import { createContext, useContext, useState, useEffect } from "react";
import { ReactChildren } from "@/types";
import { DateTime } from "luxon";
import { isSpecialText, useCanvas } from "./CanvasContext";
import { useModals } from "../modals/ModalsContext";
import { PackType } from "@/types/products";

interface DynamicPromoProviderInterface {
    children: ReactChildren
}

interface DynamicPromoContextData {
    promoVisibility: Record<SetterName, boolean>;
    enabledPromos: Record<SetterName, boolean>;
    setEnabledPromos: React.Dispatch<React.SetStateAction<Record<SetterName, boolean>>>;

    cpf_limit: number;
    cpf_limit_enabled: boolean;

    date_start: string | null;

    date_limit: string | null;
    date_limit_enabled: boolean;

    expiration_date: string | null;
    expiration_date_enabled: boolean;

    price_a_cada: string;
    price_a_cada_enabled: boolean;

    ap_x_UN: ApXUNType;
    ap_x_UN_enabled: boolean;

    leve_Por: LevePORType;
    leve_Por_enabled: boolean;

    oferta_pack: OfertaPackType;
    oferta_pack_enabled: boolean;

    xOff_xUN: XOffType;
    xOff_xUN_enabled: boolean;

    compre_e_ganhe: CompreEGanheType;
    compre_e_ganhe_enabled: boolean;

    leveX_pagueY: LeveXPagueYType;
    leveX_pagueY_enabled: boolean;

    maisX_Leve: MaisXXLeve;
    maisX_Leve_enabled: boolean;

    compreX_MaisX_Leve: CompreXMaisXLeve;
    compreX_MaisX_Leve_enabled: boolean;

    installmentPrice: InstallmentType;
    installments_enabled: boolean;

    text_club: string;
    text_club_enabled: boolean;

    updateCPFLimit: (value: number) => void;
    updateStartDate: (value: string | null) => void;
    updateDateLimit: (value: string | null) => void;
    updateExpirationDate: (value: string | null) => void;
    updatePriceACada: (value: string) => void;
    updateApXUN: (value: ApXUNType) => void;
    updateLEVEPOR: (value: LevePORType) => void;
    updateXOff: (value: XOffType) => void;
    updateCompreEGanhe: (value: CompreEGanheType) => void;
    updateLeveXPagueY: (value: LeveXPagueYType) => void;
    updateMaisX: (value: MaisXXLeve) => void;
    updateCompreXMaisXLeve: (value: CompreXMaisXLeve) => void;
    updateInstallmentPrice: (value: InstallmentType) => void;
    toggleEnabled: (setterName: SetterName, value: boolean) => void;
    updateEnabledPromos: (setterName: SetterName, value: boolean) => void;
    updateTextClub: (value: string) => void;
    updateOfertaPack: (value: OfertaPackType) => void;

    pack: PackType,
    updatePack: (value: PackType) => void;
}

export type SetterName = 'cpf_limit_enabled' | 'date_start_enabled' | 'date_limit_enabled' | 'expiration_date_enabled' | 'price_a_cada_enabled'
    | 'ap_x_UN_enabled' | 'xOff_xUN_enabled' | 'compre_e_ganhe_enabled' | 'leveX_pagueY_enabled' | 'maisX_Leve_enabled' | 'compreX_MaisX_Leve_enabled'
    | 'installments_enabled' | "text_club_enabled" | 'leve_Por_enabled' | "oferta_pack_enabled";
export type ApXUNType = { quantity: number, price: string };
export type XOffType = { xOff: number, xUN: number, price: string };
export type LevePORType = { quantity: number, price: string };
export type CompreEGanheType = { compre: number, ganhe: string };
export type LeveXPagueYType = { leve: number, pague: number };
export type MaisXXLeve = { maisX: string, leve: string };
export type CompreXMaisXLeve = { compreX: number, maisX: string, leve: string };
export type InstallmentType = { quantity: number, price: string };
export type OfertaPackType = { quantity: number, price: string };

const DynamicPromoContext = createContext<DynamicPromoContextData>(
    {} as DynamicPromoContextData
);

export const __PROMOTION_MAP__: Record<SetterName, boolean> = {
    "cpf_limit_enabled": true,
    "date_start_enabled": true,
    "date_limit_enabled": true,
    "expiration_date_enabled": true,
    "price_a_cada_enabled": true,
    "ap_x_UN_enabled": true,
    "leve_Por_enabled": true,
    "xOff_xUN_enabled": true,
    "compre_e_ganhe_enabled": true,
    "leveX_pagueY_enabled": true,
    "maisX_Leve_enabled": true,
    "compreX_MaisX_Leve_enabled": true,
    "installments_enabled": true,
    "text_club_enabled": true,
    "oferta_pack_enabled": true,
};

export const __DISABLED_PROMOTION_MAP__: Record<SetterName, boolean> = {
    "cpf_limit_enabled": false,
    "date_start_enabled": false,
    "date_limit_enabled": false,
    "expiration_date_enabled": false,
    "price_a_cada_enabled": false,
    "ap_x_UN_enabled": false,
    "leve_Por_enabled": false,
    "xOff_xUN_enabled": false,
    "compre_e_ganhe_enabled": false,
    "leveX_pagueY_enabled": false,
    "maisX_Leve_enabled": false,
    "compreX_MaisX_Leve_enabled": false,
    "installments_enabled": false,
    "text_club_enabled": false,
    "oferta_pack_enabled": false,
};

export const DynamicPromoProvider: React.FC<DynamicPromoProviderInterface> = ({ children }) => {
    const { state } = useCanvas();
    const { elements } = state;
    const { showCanvasModal } = useModals();

    const [enabledPromos, setEnabledPromos] = useState(__PROMOTION_MAP__);

    const [cpf_limit, setCPF_Limit] = useState<number>(0);
    const [cpf_limit_enabled, setCPF_Limit_Enabled] = useState<boolean>(true);

    const [date_start, setDate_start] = useState<string | null>(DateTime.now().toISODate());
    const [date_limit, setDate_Limit] = useState<string | null>(DateTime.now().toISODate());
    const [date_limit_enabled, setDate_Limit_Enabled] = useState<boolean>(true);

    const [expiration_date, setExpiration_Date] = useState<string | null>(DateTime.now().toISODate());
    const [expiration_date_enabled, setExpiration_Date_Enabled] = useState<boolean>(true);

    const [price_a_cada, setPrice_A_Cada] = useState<string>("0,00");
    const [price_a_cada_enabled, setPrice_A_Cada_Enabled] = useState<boolean>(true);

    const [ap_x_UN, setAp_x_UN] = useState<ApXUNType>({ quantity: 2, price: "0,00" });
    const [ap_x_UN_enabled, setAp_x_UN_Enabled] = useState<boolean>(true);

    const [leve_Por, setlevePor] = useState<LevePORType>({ quantity: 2, price: "0,00" });
    const [leve_Por_enabled, setlevePor_Enabled] = useState<boolean>(true);

    const [xOff_xUN, setXOff_xUN] = useState<XOffType>({ xOff: 0, xUN: 1, price: "0,00" });
    const [xOff_xUN_enabled, setXOff_xUN_Enabled] = useState<boolean>(true);

    const [compre_e_ganhe, setCompre_e_Ganhe] = useState<CompreEGanheType>({ compre: 1, ganhe: "DESCRIÇÃO DO ITEM" });
    const [compre_e_ganhe_enabled, setCompre_e_Ganhe_Enabled] = useState<boolean>(true);

    const [leveX_pagueY, setLeveX_PagueY] = useState<LeveXPagueYType>({ leve: 2, pague: 1 });
    const [leveX_pagueY_enabled, setLeveX_PagueY_Enabled] = useState<boolean>(true);

    const [maisX_Leve, setMaisX_Leve] = useState<MaisXXLeve>({ maisX: "0,00", leve: "DESCRIÇÃO DO ITEM" });
    const [maisX_Leve_enabled, setMaisX_Leve_Enabled] = useState<boolean>(true);

    const [compreX_MaisX_Leve, setCompreX_MaisX_Leve] = useState<CompreXMaisXLeve>({ compreX: 2, maisX: "0,00", leve: "DESCRIÇÃO DO ITEM" });
    const [compreX_MaisX_Leve_enabled, setCompreX_MaisX_Leve_Enabled] = useState<boolean>(true);

    const [installmentPrice, setInstallmentPrice] = useState<InstallmentType>({ quantity: 2, price: "0,00" });
    const [installments_enabled, setInstallment_Enabled] = useState<boolean>(true);

    const [text_club_enabled, settext_clube_enabled] = useState<boolean>(false);
    const [text_club, setText_clube] = useState<string>("");

    const [pack, setPack] = useState<PackType>("UN");

    const [oferta_pack, setOferta_Pack] = useState<LevePORType>({ quantity: 2, price: "0,00" });
    const [oferta_pack_enabled, setOferta_Pack_Enabled] = useState<boolean>(true);

    useEffect(() => {
        if (text_club) localStorage.setItem("text_club", text_club);
    }, [text_club]);

    useEffect(() => {
        const getTextClubFromCache = localStorage.getItem("text_club");
        if (getTextClubFromCache) setText_clube(getTextClubFromCache);
    }, []);

    const promoVisibility: Record<SetterName, boolean> = {
        "cpf_limit_enabled": cpf_limit_enabled,
        "date_start_enabled": date_limit_enabled,
        "date_limit_enabled": date_limit_enabled,
        "expiration_date_enabled": expiration_date_enabled,
        "price_a_cada_enabled": price_a_cada_enabled,
        "ap_x_UN_enabled": ap_x_UN_enabled,
        "leve_Por_enabled": leve_Por_enabled,
        "xOff_xUN_enabled": xOff_xUN_enabled,
        "compre_e_ganhe_enabled": compre_e_ganhe_enabled,
        "leveX_pagueY_enabled": leveX_pagueY_enabled,
        "maisX_Leve_enabled": maisX_Leve_enabled,
        "compreX_MaisX_Leve_enabled": compreX_MaisX_Leve_enabled,
        "installments_enabled": installments_enabled,
        "text_club_enabled": text_club_enabled,
        "oferta_pack_enabled": oferta_pack_enabled
    };

    const updatePack = (value: PackType) => setPack(value);

    const updateEnabledPromos = (setterName: SetterName, value: boolean) => {
        setEnabledPromos((prevState) => ({
            ...prevState,
            [setterName]: value
        }));
    }

    const updateCPFLimit = (limit: number) => {
        setCPF_Limit(limit);
    }

    const updateStartDate = (date: string | null) => {
        if (!date) return;
        const dt = DateTime.fromISO(date);
        const formattedDate = dt.isValid ? dt.toISODate() : null;

        setDate_start(formattedDate);
    }

    const updateDateLimit = (date: string | null) => {
        if (!date) return;
        const dt = DateTime.fromISO(date);
        const formattedDate = dt.isValid ? dt.toISODate() : null;

        setDate_Limit(formattedDate);
    }

    const updateExpirationDate = (date: string | null) => {
        if (!date) return;
        const dt = DateTime.fromISO(date);
        const formattedDate = dt.isValid ? dt.toISODate() : null;

        setExpiration_Date(formattedDate);
    }

    const updatePriceACada = (value: string) => {
        setPrice_A_Cada(value);
    }

    const updateApXUN = (value: ApXUNType) => {
        setAp_x_UN(value);
    }

    const updateLEVEPOR = (value: LevePORType) => {
        setlevePor(value);
    }
    const updateXOff = (value: XOffType) => {
        setXOff_xUN(value);
    }

    const updateCompreEGanhe = (value: CompreEGanheType) => {
        setCompre_e_Ganhe(value);
    }

    const updateLeveXPagueY = (value: LeveXPagueYType) => {
        setLeveX_PagueY(value);
    }

    const updateMaisX = (value: MaisXXLeve) => {
        setMaisX_Leve(value);
    }

    const updateCompreXMaisXLeve = (value: CompreXMaisXLeve) => {
        setCompreX_MaisX_Leve(value);
    }

    const updateInstallmentPrice = (value: InstallmentType) => {
        setInstallmentPrice(value);
    }

    const updateTextClub = (value: string) => {
        setText_clube(value);
    }

    const updateOfertaPack = (value: OfertaPackType) => {
        setOferta_Pack(value);
    }

    const toggleEnabled = (stateName: SetterName, value: boolean) => {
        const setters = {
            cpf_limit_enabled: setCPF_Limit_Enabled,
            date_start_enabled: setDate_Limit_Enabled,
            date_limit_enabled: setDate_Limit_Enabled,
            expiration_date_enabled: setExpiration_Date_Enabled,
            price_a_cada_enabled: setPrice_A_Cada_Enabled,
            ap_x_UN_enabled: setAp_x_UN_Enabled,
            leve_Por_enabled: setlevePor_Enabled,
            xOff_xUN_enabled: setXOff_xUN_Enabled,
            compre_e_ganhe_enabled: setCompre_e_Ganhe_Enabled,
            leveX_pagueY_enabled: setLeveX_PagueY_Enabled,
            maisX_Leve_enabled: setMaisX_Leve_Enabled,
            compreX_MaisX_Leve_enabled: setCompreX_MaisX_Leve_Enabled,
            installments_enabled: setInstallment_Enabled,
            text_club_enabled: settext_clube_enabled,
            oferta_pack_enabled: setOferta_Pack_Enabled,
        };

        const setter = setters[stateName];

        if (setter) setter(value);
        else console.error(`Setter not found for state: ${stateName}`);
    };

    const reset = () => {
        setCPF_Limit_Enabled(true);
        setDate_Limit_Enabled(true);
        setExpiration_Date_Enabled(true);
        setPrice_A_Cada_Enabled(true)
        setAp_x_UN_Enabled(true);
        setlevePor_Enabled(true);
        setXOff_xUN_Enabled(true);
        setCompre_e_Ganhe_Enabled(true);
        setLeveX_PagueY_Enabled(true);
        setMaisX_Leve_Enabled(true);
        setCompreX_MaisX_Leve_Enabled(true);
        setInstallment_Enabled(true);
        settext_clube_enabled(true);
        setEnabledPromos(__PROMOTION_MAP__);
        setOferta_Pack_Enabled(true);
    };

    useEffect(() => {
        if (showCanvasModal) {
            setEnabledPromos(__DISABLED_PROMOTION_MAP__);

            elements.forEach((element) => {
                const type = element.attributes.textType;
                if (isSpecialText(type)) {
                    switch (type) {
                        case "datetime": break;
                        case "market": break;
                        case "date_and_market": break;
                        case "cpf_limit":
                            updateEnabledPromos("cpf_limit_enabled", true);
                            break;
                        case "date_limit":
                            updateEnabledPromos("date_limit_enabled", true);
                            break;
                        case "expiration_date":
                            updateEnabledPromos("expiration_date_enabled", true);
                            break;
                        case "price_a_cada_full":
                        case "price_a_cada_digits":
                        case "price_a_cada_cents":
                            updateEnabledPromos("price_a_cada_enabled", true);
                            break;
                        case "ap_x_UN__price_digits":
                        case "ap_x_UN__price_cents":
                        case "ap_x_UN__quantity":
                            updateEnabledPromos("ap_x_UN_enabled", true);
                            break;
                        case "leve_Por__price_digits":
                        case "leve_Por__price_cents":
                        case "leve_Por__quantity":
                            updateEnabledPromos("leve_Por_enabled", true);
                            break;
                        case "xOff_value":
                        case "xUN_value":
                        case "xOff_price_digits":
                        case "xOff_price_cents":
                            updateEnabledPromos("xOff_xUN_enabled", true);
                            break;
                        case "compre_e_ganhe__un":
                        case "compre_e_ganhe__item":
                            updateEnabledPromos("compre_e_ganhe_enabled", true);
                            break;
                        case "leveX_pagueY__x":
                        case "leveX_pagueY__y":
                            updateEnabledPromos("leveX_pagueY_enabled", true);
                            break;
                        case "maisXX_value":
                        case "maisXX_item":
                            updateEnabledPromos("maisX_Leve_enabled", true);
                            break;
                        case "compreX_maisX_leve__x":
                        case "compreX_maisX_leve__value":
                        case "compreX_maisX_leve__item":
                            updateEnabledPromos("compreX_MaisX_Leve_enabled", true);
                            break;
                        case "installments__price_digits":
                        case "installments__price_cents":
                        case "installments__quantity":
                            updateEnabledPromos("installments_enabled", true);
                            break;
                        case "oferta_pack__price_digits":
                        case "oferta_pack__price_cents":
                        case "oferta_pack__quantity":
                            updateEnabledPromos("oferta_pack_enabled", true);
                            break;
                        default:
                            break;
                    }
                }
            })
        }
    }, [elements, showCanvasModal])

    useEffect(() => {
        if (!showCanvasModal) {
            // Reset.
            reset();
        }
    }, [showCanvasModal])

    return (
        <DynamicPromoContext.Provider
            value={{
                promoVisibility,

                enabledPromos,
                setEnabledPromos,

                cpf_limit,
                cpf_limit_enabled,

                date_start,

                date_limit,
                date_limit_enabled,

                expiration_date,
                expiration_date_enabled,

                price_a_cada,
                price_a_cada_enabled,

                ap_x_UN,
                ap_x_UN_enabled,

                leve_Por,
                leve_Por_enabled,

                xOff_xUN,
                xOff_xUN_enabled,

                compre_e_ganhe,
                compre_e_ganhe_enabled,

                leveX_pagueY,
                leveX_pagueY_enabled,

                maisX_Leve,
                maisX_Leve_enabled,

                compreX_MaisX_Leve,
                compreX_MaisX_Leve_enabled,

                installmentPrice,
                installments_enabled,

                text_club,
                text_club_enabled,

                oferta_pack,
                oferta_pack_enabled,

                updateCPFLimit,
                updateStartDate,
                updateDateLimit,
                updateExpirationDate,
                updatePriceACada,
                updateApXUN,
                updateLEVEPOR,
                updateXOff,
                updateCompreEGanhe,
                updateLeveXPagueY,
                updateMaisX,
                updateCompreXMaisXLeve,
                updateInstallmentPrice,
                toggleEnabled,
                updateEnabledPromos,
                updateTextClub,
                updateOfertaPack,

                pack,
                updatePack
            }}
        >
            {children}
        </DynamicPromoContext.Provider>
    );
};

export const useDynamicPromo = (): DynamicPromoContextData => {
    const context = useContext(DynamicPromoContext);

    if (!context) {
        throw new Error(
            "useDynamicPromo must be used within a DynamicPromoProvider"
        );
    }

    return context;
};

export function getTextTypeLabel(type: SetterName): string {
    switch (type) {
        case 'cpf_limit_enabled': return 'Limite de CPF';
        case 'date_limit_enabled': return 'Data Limite';
        case 'expiration_date_enabled': return 'Data de Expiração';
        case 'price_a_cada_enabled': return 'Preço a Cada';
        case 'ap_x_UN_enabled': return 'A Partir de X UN';
        case 'xOff_xUN_enabled': return 'X% Off';
        case 'compre_e_ganhe_enabled': return 'Compre e Ganhe';
        case 'leveX_pagueY_enabled': return 'Leve X Pague Y';
        case 'maisX_Leve_enabled': return 'Mais X Leve';
        case 'compreX_MaisX_Leve_enabled': return 'Compre X Mais X Leve';
        case 'leve_Por_enabled': return 'Leve x Por:';
        case 'oferta_pack_enabled': return 'Oferta Pack';
        default: return '';
    }
}
