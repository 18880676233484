/**
 * Este arquivo fornece um boilerplate para
 * novos contexts que vierem a ser implementados.
 */

import { createContext, useContext, useState, useEffect } from "react";
import { ReactChildren } from "@/types";
import SystemInfo from "@/helpers/SystemInfo";

interface GlobalProviderInterface {
    children: ReactChildren
}

interface GlobalContextData {
    isIntraNet: boolean
}

const GlobalContext = createContext<GlobalContextData>(
    {} as GlobalContextData
);

export const GlobalProvider: React.FC<GlobalProviderInterface> = ({ children }) => {
    const [isIntranet, setIsIntranet] = useState<boolean>(SystemInfo.isIntranet || false);

    return (
        <GlobalContext.Provider
            value={{
                isIntraNet: isIntranet
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export const useApp = (): GlobalContextData => {
    const context = useContext(GlobalContext);

    if (!context) {
        throw new Error(
            "useApp must be used within a GlobalProvider"
        );
    }

    return context;
};
