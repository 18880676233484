const packageJson = require("../../../package.json");

export const __DEVELOPER_APP_VERSION__ = packageJson['version'];

export const __BG_ANIMATED_BLUE_GRADIENT__ = "transform-gpu transition-all duration-[600ms] bg-gradient-to-r to-blue-700 via-blue-400 from-blue-700 bg-size-200 bg-pos-0 hover:bg-pos-100 group";

export type AcceptedLocales = 'en'
    | 'en-US'
    | 'pt'
    | 'pt-BR'
    | 'pt-PT'
    | 'es'
    | 'es-ES';

export type __BANNER_TYPE__ = "banner_common" | "flyer" | "leve_x__pague_y" | "ap_x_unidade" | "combo_de_produtos" | "compre_e_ganhe" | "compre_x_leve_y" | "leve_mais_pague_menos" | "leve_x_pague_y_combo" | "parcelado" | "preco_embalagem" | "x_off_unidade";

export type __BANNER_TYPE_OPTION__ = { id: __BANNER_TYPE__, label: string }

export const __BANNER_TYPES__: __BANNER_TYPE_OPTION__[] = [
    { id: 'banner_common', label: 'Cartaz Comum' },
    { id: 'flyer', label: 'Encarte' },
    { id: 'leve_x__pague_y', label: 'Leve X Pague Y' },
    { id: 'ap_x_unidade', label: 'A partir de X unidade' },
    { id: 'combo_de_produtos', label: 'Combo de Produtos' },
    { id: 'compre_e_ganhe', label: 'Compre e Ganhe' },
    { id: 'compre_x_leve_y', label: 'Compre X Leve Y' },
    { id: 'leve_mais_pague_menos', label: 'Leve +, Pague -' },
    { id: 'leve_x_pague_y_combo', label: 'Leve X, Pague Y - Combo' },
    { id: 'parcelado', label: 'Parcelado' },
    { id: 'preco_embalagem', label: 'Preço da Embalagem' },
    { id: 'x_off_unidade', label: 'X% OFF / unidade' },
];
