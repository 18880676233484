import { isEqual } from "lodash";

type OutputTimeFormat = 's' | 'm' | 'ms';

type ExecTimeOptions = {
	format: OutputTimeFormat,
	enableWarnings: boolean
}

const defaultExecTimeOptions: ExecTimeOptions = {
	format: 'm',
	enableWarnings: true
};

/**
 * Debug: Uma classe utilitária para ajudar no desenvolvimento.
 */
export default class DEBUG
{
	static isDev: boolean = process.env.NODE_ENV === "development";
	
	static ENABLED: boolean = process.env.NEXT_PUBLIC_DEBUG_MODE === "true" && DEBUG.isDev;

	/**
	 * Mede o tempo de execução de uma função em milissegundos.
	 * @param fn A função para a qual medir o tempo de execução.
	 * @param format Formato da saída de tempo, pode ser "s" para segundos ou "m" para minutos. Por padrão é "m".
	 * @returns O tempo de execução da função.
	 */
	static execTime(fn: () => void, _options: Partial<ExecTimeOptions> = defaultExecTimeOptions): number
	{
		const options = { ...defaultExecTimeOptions, ..._options };

		const start = performance.now();

		fn();

		const end = performance.now();
		const execTimeMs = end - start;

		let output: string;

		const callWarn = (message: string) => {
			if (options.enableWarnings) console.log(message);
		}

		switch(defaultExecTimeOptions.format)
		{
			case 'ms':
				output = `Tempo de execução: ${execTimeMs} milissegundos`;
				callWarn(output);
				return execTimeMs;
			case 's':
				const execTimeSec = execTimeMs / 1000;
				output = `Tempo de execução: ${execTimeSec} segundos`;
				callWarn(output);
				return execTimeSec;
			case 'm':
			default:
				const execTimeMin = execTimeMs / 1000 / 60;
				output = `Tempo de execução: ${execTimeMin} minutos`;
				callWarn(output);
				return execTimeMin;
		}
	}

	/**
	 * Retorna um objeto com as diferenças entre dois objetos.
	 * @param object1 O primeiro objeto.
	 * @param object2 O segundo objeto.
	 * @returns Um objeto contendo as diferenças entre os dois objetos de entrada.
	 */
	static getDiff(object1: Record<string, any>, object2: Record<string, any>): Record<string, any> {
		const diff: Record<string, any> = {};

		for (const key in object2)
		{
			if (Object.prototype.hasOwnProperty.call(object2, key))
			{
				const valueObject1 = object1[key];
				const valueObject2 = object2[key];

				if (valueObject1 !== undefined && valueObject2 !== undefined && !isEqual(valueObject1, valueObject2)) {
					diff[`__${key}__1`] = valueObject1;
					diff[`__${key}__2`] = valueObject2;
				} else if (valueObject1 === undefined && valueObject2 !== undefined) {
					diff[`__${key}__2`] = valueObject2;
				} else if (valueObject1 !== undefined && valueObject2 === undefined) {
					diff[`__${key}__1`] = valueObject1;
				}
			}
		}
		return diff;
	}
}
