import React from "react";
import Head from 'next/head';
import { ParentInterface } from "@/types";
import Sidebar from "@/components/Sidebar";
import { useMember } from "@/contexts/member/MemberContext";
import { useRouter } from "next/router";
import SidebarSkeleton from "@/components/sidebars/SidebarSkeleton";
import Header from "@/components/Header";
import useScreenDimensions from "@/hooks/useScreenDimensions";
import CampaignModal from "./modals/editor/campaigns/CampaignModal";
import { useModals } from "@/contexts/modals/ModalsContext";

interface LayoutInterface extends ParentInterface {
}

const Layout: React.FC<LayoutInterface> = ({ children }) => {
    const { isSmallScreen } = useScreenDimensions();
    const { member, isFetchingData } = useMember();
    const { showCampaignModal, setShowCampaignModal } = useModals();

    const router = useRouter();

    const shouldRenderSidebar = member.isLogged() && router.pathname !== '/editor';
    const shouldRenderSkeletonSidebar = isFetchingData && router.pathname !== '/editor';

    return (
        <>
            <Head>
                <title>Salestag Tablóide &trade;</title>
                <meta name="description" content="SalesTag Tablóide TM" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <div className="flex flex-col h-screen w-full">
                <Header />

                <div className="flex flex-grow overflow-hidden pt-16">
                    {shouldRenderSidebar && !isSmallScreen && <Sidebar />}
                    {shouldRenderSkeletonSidebar && !shouldRenderSidebar && !isSmallScreen && <SidebarSkeleton />}

                    <div className="flex flex-col w-full">
                        <main className={"grid grid-cols-12 w-full flex-grow"}>
                            <section className="col-span-12 h-full w-full">
                                <div className="w-full flex justify-center items-center max-h-screen overflow-auto">
                                    {children}
                                </div>
                            </section>
                        </main>
                    </div>
                </div>
                {
                    member.isLogged() && <CampaignModal isOpen={showCampaignModal} onClose={() => setShowCampaignModal(false)} />
                }
            </div>
        </>
    );
}

export default Layout;
