import { createContext, useContext, useState, useEffect, useRef, RefObject } from "react";
import { ReactChildren } from "@/types";
import { DateTime } from "luxon";
import Api from "@/api/Api";
import { useMember } from "@/contexts/member/MemberContext";
import { EntityID } from "@/types/silverstripe";
import { NotificationType } from "./@types";
import Notify from "@/helpers/Notify";

// 5 minutos
const __NOTIFICATION_TIMEOUT__: number = 300000;

interface NotificationProviderInterface {
    children: ReactChildren
}

interface NotificationContextData {
    notifications: Array<NotificationOptions>,
    refreshNotifications: () => Promise<void>,
	isFetchingNotifications: boolean,
	notificationsCount: RefObject<number>,
}

type NotificationOptions = {
	id: EntityID,
	type: NotificationType,
	userName: string,
	date: string,
	title: string,
	message: string,
	read: boolean
}

type IntervalIDType = string | number | NodeJS.Timeout | undefined;

const NotificationContext = createContext<NotificationContextData>(
    {} as NotificationContextData
);

export const NotificationProvider: React.FC<NotificationProviderInterface> = ({ children }) => {
    const [notifications, setNotifications] = useState<Array<NotificationOptions>>([]);
	const [isFetchingNotifications, setFetchingNotifications] = useState<boolean>(false);

	const notificationsCount = useRef<number>(notifications.length);

    const api = new Api('notifications', 'n');

    const { member } = useMember();
	const intervalID = useRef<IntervalIDType>(undefined);

	const getUserName = (type: NotificationType): string => {
		switch (type) {
			case 'marketing': return 'EPJ Marketing';
			case 'developer': return 'EPJ Developer';
			case 'system': return 'EPJ BOT';
			default: throw new Error(`Unknown notification type: ${type}`);
		}
	}

    const refreshNotifications = async () => {
		setFetchingNotifications(true);
		const request = api.useHash(member);
		const response = await api.post(request);

		if (response.success)
		{
			const { notificationList } = response.data;
			const list: Array<NotificationOptions> = [];
			notificationList.forEach((notification: any) => {
				list.push({
					id: notification.ID,
					type: notification.Type as NotificationType,
					title: notification.Title,
					message: notification.Message,
					userName: getUserName(notification.Type as NotificationType),
					date: getPastRelativeDate(notification.Created),
					read: Boolean(notification.Read)
				});
			});

			const previousCount = notificationsCount.current;
			notificationsCount.current = notificationList.length;

			setNotifications(list);

			if (notificationsCount.current > previousCount) 
			{
				Notify.Info("Você têm notificações não lidas.");
			}
		}
		setFetchingNotifications(false);
	}

    useEffect(() => {
		if (member.isLogged() && !intervalID.current)
		{
			refreshNotifications();
			intervalID.current = setInterval(refreshNotifications, __NOTIFICATION_TIMEOUT__);
		}
		return () => clearInterval(intervalID.current);
	}, [member]); 

    return (
        <NotificationContext.Provider
            value={{
                notifications,
				notificationsCount,
                refreshNotifications,
				isFetchingNotifications
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotifications = (): NotificationContextData => {
    const context = useContext(NotificationContext);

    if (!context) {
        throw new Error(
            "useNotifications must be used within a NotificationProvider"
        );
    }

    return context;
};

function getPastRelativeDate(dateString: string): string {
	const pastDate = DateTime.fromFormat(dateString, 'yyyy-MM-dd HH:mm:ss', { zone: 'America/Sao_Paulo' });
	const now = DateTime.local().setZone('America/Sao_Paulo');

	if (!pastDate.isValid) {
		throw new Error('Invalid date format. Please provide a valid date string in the format "YYYY-MM-DD HH:mm:ss".');
	}

	return pastDate.toRelativeCalendar({ base: now }) as string;
}
