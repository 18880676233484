interface SVGIconInterface {
	className?: string;
	onClick?: (event: any) => void;
}

export const ArrowLeft: React.FC<SVGIconInterface> = ({ className, onClick }) => {
	return (
		<svg
			aria-hidden="true"
			className={`w-5 h-5 text-black sm:w-6 sm:h-6 dark:text-gray-800 ${className}`}
			fill="none"
			stroke="currentColor"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			onClick={onClick}
		>
			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
		</svg>
	)
}

export const ArrowRight: React.FC<SVGIconInterface> = ({ className, onClick }) => {
	return (
		<svg
			aria-hidden="true"
			className={`w-5 h-5 text-black sm:w-6 sm:h-6 dark:text-gray-800 ${className}`}
			fill="none"
			stroke="currentColor"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			onClick={onClick}
		>
			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
		</svg>
	)
}

export const ArrowDown: React.FC<SVGIconInterface> = ({ className, onClick }) => {
	return (
		<svg className={`h-3 w-5 ${className}`} fill="#fff" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/>
		</svg>
	);
};

export const ArrowUp: React.FC<SVGIconInterface> = ({ className, onClick }) => {
	return (
			<svg  className={`h-3 w-5 ${className}`} fill="#fff" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
				<path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z"/>
			</svg>
	);
};
