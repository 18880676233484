import React from 'react';
import { useTable, useSortBy, CellProps } from 'react-table';
import SkeletonTable from './SkeletonTable';

interface Column {
	Header: string;
	accessor: string;
	Cell?: React.FC<CellProps<object>>;
	className?: string;
}

export interface TableProps {
	columns: Column[];
	data: any[];
	onOpenButtonClick?: (id: string | number) => void;
	onEditButtonClick?: (id: string | number) => void;
	onDeleteButtonClick?: (id: string | number) => void;
	openButtonLabel?: string;
	editButtonLabel?: string;
	deleteButtonLabel?: string;
	idKeyName?: string;
	showDeleteButton?: boolean;
	showOpenButton?: boolean;
	isLoading?: boolean;
	edit?: boolean;
	onEdit?: (newData: any, id: string | number) => void;
	rowClassName?: string;
}

const Table: React.FC<TableProps> = ({
	columns,
	data,
	onOpenButtonClick,
	onEditButtonClick,
	onDeleteButtonClick,
	openButtonLabel,
	editButtonLabel,
	deleteButtonLabel,
	idKeyName = "id",
	showOpenButton,
	isLoading = false,
	edit = false,
	onEdit,
	rowClassName = ''
}) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({ columns, data }, useSortBy);

	const handleEdit = (id: string | number, field: string, value: any) => {
		if (onEdit) {
			const rowIndex = data.findIndex(row => row[idKeyName] === id);

			if (rowIndex !== -1) {
				const newData = data.map((item, index) => {
					if (index === rowIndex) {
						return { ...item, [field]: value };
					}
					return item;
				});
				onEdit(newData, id);
			}
		}
	};

	return (
		<React.Fragment>
			{
				isLoading ? <SkeletonTable /> : (
					<div className="">
						<table {...getTableProps()} className="min-w-full border-t divide-y divide-zinc-200">
							<thead className="bg-zinc-100">
								{headerGroups.map(headerGroup => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map(column => (
											// @ts-ignore
											<th {...column.getHeaderProps(column.getSortByToggleProps())} className='text-zinc-500 font-medium'>
												{column.render('Header')}
												{/* @ts-ignore */}
												{column.isSorted ? (column.isSortedDesc ? ' ⩟' : ' ⩡') : ''}
											</th>
										))}
										<th className="px-52 py-2 text-sm text-right font-medium text-zinc-500 tracking-wider">
											Opções
										</th>
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()} className="bg-white divide-y divide-zinc-200">
								{rows.map(row => {
									prepareRow(row);
									return (
										<tr {...row.getRowProps()} className={`hover:bg-zinc-100 ${rowClassName}`}>
											{row.cells.map(cell => {
												// @ts-ignore
												const isEditable = edit && cell.column.id; // Verifica se a célula é editável

												return (
													<td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
														{isEditable ? (
															<input
																type="text"
																value={cell.value}
																// @ts-ignore
																onChange={e => handleEdit(row.original[idKeyName as string], cell.column.id as string, e.target.value)}
															/>
														) : (
															cell.render('Cell')
														)}
													</td>
												);
											})}
											<td className="px-4 py-4 whitespace-nowrap text-sm text-zinc-500">
												<div className="flex flex-row justify-end">
													{
														onOpenButtonClick && showOpenButton && openButtonLabel && (
															<button
																className="bg-green-500 hover:bg-green-600 shadow-md shadow-green-500/50 text-white px-5 py-2 rounded-md"
																// @ts-ignore
																onClick={() => onOpenButtonClick(row.original[idKeyName as string])}
															>
																{openButtonLabel}
															</button>
														)
													}
													{
														onEditButtonClick && editButtonLabel && (
															<button
																className="bg-blue-500 hover:bg-blue-600 shadow-md shadow-blue-500/50 text-white px-4 py-2 rounded-md ml-4"
																// @ts-ignore 
																onClick={() => onEditButtonClick(row.original[idKeyName as string])}
															>
																{editButtonLabel}
															</button>
														)
													}
													{
														onDeleteButtonClick && deleteButtonLabel && (
															<button
																className="bg-rose-500 hover:bg-rose-600 shadow-md shadow-rose-500/50 text-white px-4 py-2 rounded-md ml-4 transition-all duration-300 hover:shadow-lg active:scale-75"
																// @ts-ignore
																onClick={() => onDeleteButtonClick(row.original[idKeyName as string])}
															>
																{deleteButtonLabel}
															</button>
														)
													}
												</div>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)
			}
		</React.Fragment>
	);
};

export default Table;
