import React, { useEffect, useState } from "react";
import StepContainer from "@/components/partials/onboarding/steps/StepContainer";
import { useCompany } from "@/contexts/company/CompanyContext";
import { useMember } from "@/contexts/member/MemberContext";
import { View, ViewController } from "@/components/partials/onboarding/brand";
import CompanyResources from "@/components/partials/onboarding/brand/CompanyResources";
import LogosContainer from "@/components/partials/onboarding/brand/LogosContainer";
import SloganContainer from "@/components/partials/onboarding/brand/SloganContainer";
import { Transition } from "@headlessui/react";

interface CompanyStepInterface {
	styles?: string;
	setButton?: boolean;
}

const CompanyStep: React.FC<CompanyStepInterface> = ({ styles, setButton }) => {
	const { member } = useMember();
	const [showButton, setShowButton] = useState(true);
	const [view, setView] = useState<View>("company_container");

	useEffect(() => {
		if (setButton !== (null || undefined)) {
			setShowButton(setButton);
		} else {
			setShowButton(true);
		}
	}, [setButton])

	const Main: React.FC = () => {
		const options: { [key in View]: JSX.Element } = {
			company_container: <CompanyContainer setView={setView} />,
			company_resources: <CompanyResources setView={setView} />,
			logos_container: <LogosContainer />,
			slogan_container: <SloganContainer />
		}
		return options[view];
	}

	const canEditCompany = member.role === 'admin' || member.role === 'manager' || member.role === 'marketing' || member.can('manage_company');

	let props: any = {};
	if (canEditCompany && view === 'company_container') {
		if (showButton) {
			props['onConfirm'] = () => setView('company_resources');
			props['confirmLabel'] = 'Kit da Marca';
		}
	}

	return (
		<StepContainer styles={styles} {...props}>
			<Main />
		</StepContainer>
	)
}

export default CompanyStep;

const CompanyContainer: React.FC<ViewController> = ({ setView }) => {
	const [showChildren, setShowChildren] = useState(false);
	const { company, market } = useCompany();

	if (!company || !market) return <div className="flex justify-center items-center h-64">Nenhuma empresa selecionada</div>;

	useEffect(() => {
		setShowChildren(true)
	}, [setView])

	return (
		<Transition
			show={showChildren}
			enter="transition-opacity duration-1000"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="transition-opacity duration-1000"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
			className={"flex flex-col items-center"}
		>
			<h2 className="text-2xl font-semibold mb-6">Informações da Empresa</h2>
			<div className="grid grid-cols-1 gap-4 md:grid-cols-4">
				{/* Detalhes da Empresa */}
				<div className="flex flex-col items-center bg-white p-6 rounded shadow-md">
					<p className="text-gray-500">Nome da Empresa</p>
					<h3 className="text-xl font-semibold mb-2">{company.name}</h3>
				</div>
				<div className="flex flex-col items-center bg-white p-6 rounded shadow-md">
					<p className="text-gray-500">Número de Lojas</p>
					<h3 className="text-xl font-semibold mb-2">{company.totalMarkets}</h3>
				</div>
				<div className="flex flex-col items-center bg-white p-6 rounded shadow-md">
					<p className="text-gray-500">Total de Membros</p>
					<h3 className="text-xl font-semibold mb-2">{company.totalMembers}</h3>
				</div>
				<div className="flex flex-col items-center bg-white p-6 rounded shadow-md">
					<p className="text-gray-500">Impressões Este Mês</p>
					<h3 className="text-xl font-semibold mb-2">{company.monthlyPrints}</h3>
				</div>
			</div>

			<h2 className="text-2xl font-semibold mb-6 mt-6">Informações da Loja</h2>
			<div className="grid grid-cols-1 gap-4 md:grid-cols-3">
				{/* Detalhes da Loja */}
				<div className="flex flex-col items-center bg-white p-6 rounded shadow-md">
					<p className="text-gray-500">Minha Loja</p>
					<h3 className="text-xl font-semibold mb-2">{market.name}</h3>
				</div>
				<div className="flex flex-col items-center bg-white p-6 rounded shadow-md">
					<p className="text-gray-500">Membros na Loja</p>
					<h3 className="text-xl font-semibold mb-2">{market.totalMembers}</h3>
				</div>
				<div className="flex flex-col items-center bg-white p-6 rounded shadow-md">
					<p className="text-gray-500">Impressões Este Mês</p>
					<h3 className="text-xl font-semibold mb-2">{market.monthlyPrints}</h3>
				</div>
			</div>

			<div className="mt-6 text-sm text-gray-400">
				<p>Precisando adicionar Lojas ou Membros? Entre em contato com o suporte.</p>
			</div>
		</Transition>
	);
}
