import EpjLoadingIcon from "@/components/animations/EpjLoadingIcon";
import { Transition } from "@headlessui/react";

interface CanvasLoadingModalInterface {
    visible: boolean
}

const CanvasLoadingModal: React.FC<CanvasLoadingModalInterface> = ({ visible }) => {
    const doNothing = () => null;
    return (
        <Transition show={visible} >
            <div className={`fixed inset-0 z-80 `} >
                <div className="relative flex items-center justify-center h-full max-h-screen">
                    <Transition.Child
                        enter="transition-all duration-300"
                        enterFrom="scale-0"
                        enterTo="scale-100"
                        leave="transition-all duration-300"
                        leaveFrom="scale-100"
                        leaveTo="scale-0"
                    >
                        <EpjLoadingIcon />
                    </Transition.Child>
                </div>
            </div>
        </Transition>
    )
}

export default CanvasLoadingModal;
