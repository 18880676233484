import { createContext, useContext, useState, useEffect, useRef } from "react";
import { ReactChildren } from "@/types";
import Api from "@/api/Api";
import ColorHelper from "@/helpers/ColorHelper";
import { useMember } from "../member/MemberContext";
import { LineChartData, LineChartData_markets } from "@/components/charts/LineChart";
import { useQueue } from "../queue/QueueContext";
import { PieChartData } from "@/components/charts/PieChart";
import TextHelper from "@/helpers/TextHelper";
import { __DEFAULT_MOST_USED_FORMATS_DATA__ } from "./data";
import { Orientation } from "@/types/canvas";
import { TemplateHanking, FavoriteTemplate } from "@/types/dashboard";
import { EntityID } from "@/types/silverstripe";

interface DashboardProviderInterface {
	children: ReactChildren
}

interface DashboardContextData {
	refreshMonthlyView: (onFinish?: Function, markets?: number, dataIn?: string, dataFin?: string) => void,
	monthlyViewData: LineChartData[],
	marketsViewData: LineChartData_markets[],
	isFetchingMonthlyView: boolean,
	refreshMostUsedFormats: (markets: number, months: number, years: number) => void,
	refreshTemplateHanking: (markets: number, dataIn?: string, dataFin?: string) => void,
	mostUsedFormatsData: PieChartData[],
	isFetchingMostUsedFormats: boolean,

	templateHankingList: TemplateHanking[],
	isFetchingTemplateHanking: boolean,

	favoriteTemplateList: FavoriteTemplate[],
	isFetchingFavoriteTemplateList: boolean,

	isFetchingQueue: boolean,
	refreshQueue: () => void,

	refreshDashboard: (onFinish?: Function) => void,
}

const DashboardContext = createContext<DashboardContextData>(
	{} as DashboardContextData
);

export const DashboardProvider: React.FC<DashboardProviderInterface> = ({ children }) => {
	// Insights endpoint.
	const api = new Api('insights');

	const { member } = useMember();
	const { fetchQueueItems } = useQueue();

	const firstBoot = useRef<boolean>(false);
	// MonthlyView
	const [monthlyViewData, setMonthlyViewData] = useState<LineChartData[]>([]);
	const [isFetchingMonthlyView, setIsFetchingMonthlyView] = useState<boolean>(false);

	//MarketsView
	const [marketsViewData, setMarketsViewData] = useState<LineChartData_markets[]>([]);

	// Most used formats
	const [mostUsedFormatsData, setMostUsedFormatsData] = useState<PieChartData[]>(__DEFAULT_MOST_USED_FORMATS_DATA__);
	const [isFetchingMostUsedFormats, setIsFetchingMostUsedFormats] = useState<boolean>(false);

	// Template hanking
	const [templateHankingList, setTemplateHankingList] = useState<TemplateHanking[]>([]);
	const [isFetchingTemplateHanking, setIsFetchingTemplateHanking] = useState<boolean>(false);

	// Favorite templates
	const [favoriteTemplateList, setFavoriteTemplateList] = useState<FavoriteTemplate[]>([]);
	const [isFetchingFavoriteTemplateList, setIsFetchingFavoriteTemplateList] = useState<boolean>(false);

	// Queue counter
	const [isFetchingQueue, setIsFetchingQueue] = useState<boolean>(false);


	const refreshMonthlyView = async (onFinish: Function | undefined = undefined, markets: number | undefined = undefined, dataIn: string | undefined = undefined, dataFin: string | undefined = undefined) => {
		// Insights requests
		const request = api.request(member, { companyID: member.companyID, markets, dataIn, dataFin });
		setIsFetchingMonthlyView(true);
		const response = await api.post(request, 'mo');
		if (response.success) {
			const newData = [];
			const store = [];
			let index = 0;
			const { marketCounts } = response.data; //new logic global info
			// Iterar sobre todos os mercados
			for (let market in marketCounts) {
				index++;
				// Cada market é um objeto com label e count
				const { label, count, yearCount } = marketCounts[market];
				// Iterar sobre o objeto count para criar os pontos
				const points = Object.entries(count).map(([x, y]) => ({ x: parseInt(x as string), y: y as number }));
				const color = ColorHelper.randomColor();
				const impressions = yearCount;
				const acumulateY = points.reduce((acc, point) => acc + point.y, 0);
				store.push({ label, quantidade: acumulateY });
				newData.push({ label, points, color, impressions });
			}
			setMarketsViewData(store);// bar view
			setMonthlyViewData(newData);// line curve view
		}
		setIsFetchingMonthlyView(false);

		if (onFinish && typeof onFinish === 'function') onFinish();
	}
	
	const refreshQueue = async () => {
		setIsFetchingQueue(true);
		fetchQueueItems(() => setIsFetchingQueue(false));
	}
	const refreshMostUsedFormats = async (markets: number, months: number, years: number) => {
		const request = api.request(member, { markets, months, years });
		setIsFetchingMostUsedFormats(true);
		const response = await api.post(request, 'muf');
		if (response.success) {
			const { formatsCount } = response.data;
			const data = [];
			for (const key in formatsCount) {
				const value = formatsCount[key];
				data.push({ label: key as Orientation, value: value });
			}
			setMostUsedFormatsData(convertPrintsToAngles(data));
		}
		setIsFetchingMostUsedFormats(false);
	}

	const refreshTemplateHanking = async (markets: number, dataIn: string | undefined = undefined, dataFin: string | undefined = undefined) => {
		const request = api.request(member, { markets, dataIn, dataFin });
		setIsFetchingTemplateHanking(true);
		const response = await api.post(request, 'mut');
		if (response.success) {
			const { queueList } = response.data;
			const list = [];
			for (const key in queueList) {
				const item = queueList[key] as TemplateHanking;
				list.push(item);
			}
			setTemplateHankingList(list);
		}
		setIsFetchingTemplateHanking(false);
	}

	// 	const templateAPI = new Api('templates');
	// 	const request = templateAPI.request(member, { months, years });
	// 	setIsFetchingFavoriteTemplateList(true);
	// 	const response = await templateAPI.post(request, 'gf');
	// 	if (response.success) {
	// 		const { favorites } = response.data;
	// 		const { Templates: templates } = favorites;

	// 		if (templates) {
	// 			const list: FavoriteTemplate[] = [];
	// 			templates.forEach((template: any) => {
	// 				list.push({ hash: template.Hash, name: template.Name });
	// 			});
	// 			setFavoriteTemplateList(list);
	// 		}
	// 	}
	// 	setIsFetchingFavoriteTemplateList(false);
	// }
	// ----------------------------------------------------------------
	const refreshDashboard = (onFinish: Function | undefined = undefined) => {
		refreshMonthlyView(onFinish);
		refreshQueue();
	}
	useEffect(() => {
		if (member.isLogged() && !firstBoot.current) {
			firstBoot.current = true;
			refreshDashboard();
		}
	}, [member]);

	return (
		<DashboardContext.Provider
			value={{
				refreshMonthlyView,
				monthlyViewData,
				marketsViewData,
				mostUsedFormatsData,
				templateHankingList,				
				favoriteTemplateList,
				isFetchingMonthlyView,
				isFetchingMostUsedFormats,
				isFetchingTemplateHanking,
				isFetchingFavoriteTemplateList,
				isFetchingQueue,
				refreshQueue,
				refreshDashboard,
				refreshTemplateHanking,
				refreshMostUsedFormats,
			}}
		>
			{children}
		</DashboardContext.Provider>
	);
};

export const useDashboard = (): DashboardContextData => {
	const context = useContext(DashboardContext);

	if (!context) {
		throw new Error(
			"useDashboard must be used within a DashboardProvider"
		);
	}
	return context;
};
function convertPrintsToAngles(printData: { label: Orientation, value: number }[]): PieChartData[] {
	// Calcular a soma total de impressões
	const totalPrints = printData.reduce((acc, cur) => acc + cur.value, 0);
	// Mapear os dados para a nova forma
	const data = printData.map(store => {
		const proportion = store.value / totalPrints; // Proporção em relação ao total
		const angle = proportion * 360; // Conversão para ângulo
		return {
			label: TextHelper.getOrientationLabel(store.label),
			angle: angle
		};
	});
	return data;
}