import SearchDropdownPopover from '@/components/popover/SearchDropdown';
import { ExternalSearchType, ProductListItem, SilverProduct } from '@/types/products';
import React, { useEffect, useRef, useState } from 'react';
import { SearchIcon } from '@heroicons/react/solid';
import Api from '@/api/Api';
import { useMember } from '@/contexts/member/MemberContext';
import Notify from '@/helpers/Notify';

interface CategorySearchInterface {
	setList: (list: ProductListItem[]) => void,
	setOriginal: (list: SilverProduct[]) => void,
	updateLists: (value: boolean) => void,
	onSubmit: (term: string,  searchType: ExternalSearchType, setIsLoading: (value: boolean) => void) => void,
}

const CategorySearchInput: React.FC<CategorySearchInterface> = ({ setList, setOriginal, updateLists, onSubmit }) => {
	const { member } = useMember();
	const [searchType, setSearchType] = useState<ExternalSearchType>('description');
	const [search, setSearch] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	// Conta re-renderizações.
	const renders = useRef<number>(0);
	const countRenders = () => renders.current += 1;
	countRenders();

	const api = new Api('connection');

	useEffect(() => {
		if (search.length === 0 && renders?.current !== 1)
		{
			updateLists(true);
		}
	}, [search])

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		let request: any = api.useHash(member);
		request[searchType] = search;

		Notify.Info('Buscando...');
		onSubmit(search, searchType, setIsLoading);
	};

	return (
		<form onSubmit={handleSubmit} className="my-2">
			<div className="flex">
				<label htmlFor="search-dropdown" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Busca Integrada</label>
				<SearchDropdownPopover onChange={setSearchType} defaultValue={searchType} />
				<div className="relative w-full">
					<input
						type="search"
						id="search-dropdown"
						className={`${isLoading && 'cursor-not-allowed'} block p-2.5 w-full z-20 text-sm rounded-r-full text-gray-900 border-solid border border-gray-200 duration-300 hover:border-orange-400`}
						defaultValue={search}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
						disabled={isLoading}
						required
					/>
					<button
						type="submit"
						disabled={isLoading}
						className={`${isLoading && 'cursor-not-allowed'} absolute top-0 right-0 p-2.5 text-sm flex flex-row font-medium text-white ${isLoading ? 'bg-blue-800' : 'bg-blue-700'} rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
					>
						<SearchIcon className={`w-5 h-5`} />
						<span className="ml-2">Buscar</span>
					</button>
				</div>
			</div>
		</form>
	);
};

export default CategorySearchInput;
