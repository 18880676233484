import React, { useEffect, useState } from "react";
import {useAppDispatch} from "@/store/hooks";
import {darkActions} from "@/store/DarkMode.store";
import { useTranslation } from 'next-i18next';

const DarkMode: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const enableDarkMode = () => {
        dispatch(darkActions.enableDarkMode());
    };

    const disableDarkMode = () => {
        dispatch(darkActions.disableDarkMode());
    };

    const [isCurrentDarkMode, setIsCurrentDarkMode] = useState<boolean>(false);

    useEffect(() => {
		if (localStorage !== undefined)
        {
			const darkModeWasSet = localStorage.getItem("darkmode");
			setIsCurrentDarkMode(!!darkModeWasSet);
		}
    }, [])

    const toggleDarkMode = () => {
        setIsCurrentDarkMode((prevState) => !prevState);
    };

    useEffect(() => {
        const html = document.querySelector<HTMLHtmlElement>("html")!;
        if (isCurrentDarkMode) {
            html.classList.add("dark");
            localStorage.setItem("darkmode", "true");
            enableDarkMode();
            document
                .querySelector('meta[name="theme-color"]')
                ?.setAttribute("content", "#0f172a");
        } else {
            html.classList.remove("dark");
            localStorage.removeItem("darkmode");
            disableDarkMode();
            document
                .querySelector('meta[name="theme-color"]')
                ?.setAttribute("content", "#e2e8f0");
        }
    }, [isCurrentDarkMode]);

    return (
        <React.Fragment>
            <button className="text-left flex items-center justify-between bg-dark-white dark:bg-slate-800 p-2 rounded-2xl "
                onClick={toggleDarkMode}
            >
                <span className="mr-1 dark:text-slate-200">{isCurrentDarkMode ? t("darkMode.title.enabled") : t("darkMode.title.disabled")}</span>
                <div className="w-10 h-5 bg-white rounded-full px-0.5 dark:bg-slate-500/[.3] relative flex items-center dark:justify-end">
                    <div className="w-4 h-4 rounded-full bg-orange-600 dark:bg-violet-600 absolute"></div>
                </div>
            </button>
        </React.Fragment>
    );
};

export default React.memo(DarkMode);
