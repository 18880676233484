import React, { useEffect, useState } from 'react';
import initTranslator from '@root/i18n';
import '@/styles/globals.css'
import '@/styles/tw-custom.css';
import 'react-tagsinput/react-tagsinput.css';
import type { AppProps } from 'next/app'
import { ToastContainer } from 'react-toastify';
import "react-widgets/styles.css";
import 'react-tooltip/dist/react-tooltip.css'
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import 'react-lazy-load-image-component/src/effects/blur.css';
import '/node_modules/react-vis/dist/style.css';
import 'react-dropdown/style.css';
import '@/imports/roboto-fonts';
import ApplicationProvider from '@/contexts';

export default function App({ Component, pageProps }: AppProps) {
    const [isTranslationsLoaded, setIsTranslationsLoaded] = useState(false);

    useEffect(() => {
        initTranslator(setIsTranslationsLoaded);
    }, []);

    if (!isTranslationsLoaded) {
        return <React.Fragment></React.Fragment>;
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <ApplicationProvider>
                <Component {...pageProps} />
            </ApplicationProvider>
        </React.Fragment>
    )
}
