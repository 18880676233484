import React from 'react';

const CampaignIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 27 32">
		<g>
			<path fill="#808184" d="M21.06-0.024c-2.2,0-4.06,2.539-4.06,5.543V6h-0.775c-0.378,0-0.733,0.137-1.003,0.386L0.479,20.06
        C0.172,20.345-0.003,20.749,0,21.169c0.002,0.42,0.182,0.822,0.491,1.104l10.262,9.319c0.272,0.248,0.624,0.383,0.99,0.384
        c0.388,0,0.754-0.15,1.032-0.422l13.773-13.479C26.836,17.794,27,17.402,27,16.999V7.479C27,6.664,26.335,6,25.518,6h-0.416
        c0.011-0.165,0.018-0.332,0.018-0.501C25.12,2.468,23.299-0.024,21.06-0.024z M18,5.519c0-2.42,1.43-4.542,3.06-4.542
        c1.659,0,3.061,2.06,3.061,4.498c0,0.18-0.021,0.351-0.035,0.525H18V5.519z M25.518,7C25.788,7,26,7.21,26,7.479v9.519
        c0,0.136-0.055,0.268-0.15,0.361L12.076,30.838c-0.173,0.171-0.469,0.178-0.651,0.013L1.163,21.533
        C1.059,21.438,1.001,21.307,1,21.164c-0.001-0.143,0.056-0.275,0.159-0.371L15.901,7.12C16.008,7.021,16.136,7,16.225,7h7.706
        c-0.208,0.859-0.58,1.597-1.067,2.118c-0.013-0.013-0.021-0.028-0.034-0.04c-0.984-0.946-2.69-0.947-3.675,0
        c-0.497,0.478-0.771,1.115-0.771,1.793s0.273,1.316,0.771,1.794c0.492,0.473,1.145,0.734,1.837,0.734
        c0.693,0,1.346-0.261,1.838-0.734c0.497-0.479,0.771-1.116,0.771-1.794c0-0.306-0.063-0.602-0.17-0.88
        c0.733-0.718,1.283-1.77,1.535-2.992H25.518z M22.6,10.872c0,0.404-0.164,0.786-0.464,1.074c-0.608,0.586-1.678,0.587-2.288,0
        c-0.3-0.288-0.464-0.669-0.464-1.074c0-0.186,0.039-0.367,0.106-0.536C20.139,10.96,21.06,11,21.146,11c0.002,0,0.004,0,0.005,0
        c0.502,0,0.981-0.136,1.424-0.374C22.588,10.707,22.6,10.788,22.6,10.872z M20.16,9.57c0.249-0.144,0.532-0.226,0.831-0.226
        c0.391,0,0.757,0.138,1.049,0.379c-0.283,0.135-0.578,0.228-0.891,0.228C21.118,9.951,20.526,9.94,20.16,9.57z"/>
		</g>
	</svg>
);

export default CampaignIcon;
