import { Element, ElementType, PriceType } from "@/types/canvas";
import { Background, Text, Rectangle, Circle, Image, Triangle, Pricing, Star, Polygon } from "@/components/canvas";
import React from "react";
import { uuid } from '@/helpers';

class ComponentProvider {
    static getInitialProps(seed: Partial<Element> = {}): Omit<Element, "type"> {
        let priceType: PriceType | null = null;
        if (seed.textType && (seed.textType === 'digits' || seed.textType === 'cents')) {
            priceType = 'simple';
        }

        return {
            id: uuid(),
            x: 25,
            y: 25,
            width: 100,
            height: 100,
            rotation: 0,
            fill: getRandomColor(),
            image: null,
            imageType: null,
            text: null,
            defaultText: null,
            fontSize: 25,
            productID: null,
            textType: null,
            groupID: null,
            fontFamily: null,
            align: null,
            radiusX: null,
            radiusY: null,
            scaleX: 1,
            scaleY: 1,
            points: null,
            source: null,
            associated: null,
            useUpperCase: false,
            sides: null,
            cornerRadius: 0,
            priceGroupID: null,
            priceType,
            ...seed
        }
    }

    static rectangle(seed: Partial<Element> = {}): Element {
        return {
            type: "rectangle",
            ...ComponentProvider.getInitialProps(seed)
        };
    }

    static triangle(seed: Partial<Element> = {}): Element {
        return {
            type: "triangle",
            ...ComponentProvider.getInitialProps(seed)
        };
    }

    static circle(seed: Partial<Element> = {}): Element {
        return {
            type: "circle",
            ...ComponentProvider.getInitialProps({
                radiusX: 50, // garante um raio inicial
                radiusY: 50,
                x: 75,
                y: 75,
                ...seed
            })
        };
    }

    static image(seed: Partial<Element> = {}): Element {
        return {
            type: "image",
            ...ComponentProvider.getInitialProps({ x: 25, y: 25, image: '/images/no-img.png', imageType: 'static', ...seed })
        };
    }

    static text(seed: Partial<Element> = {}): Element {
        return {
            type: "text",
            ...ComponentProvider.getInitialProps({
                width: 130,
                height: 30,
                fill: '#000000',
                align: 'center',
                ...seed
            })
        };
    }

    static pricing(seed: Partial<Element> = {}): Element {
        return {
            type: "pricing",
            ...ComponentProvider.getInitialProps(seed)
        };
    }

    static star(seed: Partial<Element> = {}): Element {
        return {
            type: "star",
            ...ComponentProvider.getInitialProps({
                sides: 5,
                x: 75,
                y: 75,
                ...seed
            })
        };
    }

    static polygon(seed: Partial<Element> = {}): Element {
        return {
            type: "polygon",
            ...ComponentProvider.getInitialProps({
                sides: 5,
                x: 75,
                y: 75,
                ...seed
            })
        };
    }

    static background(color: string = '#ffffff'): Element {
        return {
            type: 'background',
            id: 'background',
            productID: null,
            groupID: null,
            x: 0,
            y: 0,
            width: 1024,
            height: 1024,
            fill: color,
            image: null,
            text: null,
            fontSize: null,
            fontFamily: null,
            rotation: 0
        } as Element
    }

    static NodeFactory(props: any) {
        const type = props.shapeProps.type as ElementType

        switch (type) {
            case 'background':
                return <Background {...props} />;
            case 'rectangle':
                return <Rectangle {...props} />
            case 'triangle':
                return <Triangle {...props} />
            case 'circle':
                return <Circle {...props} />
            case 'image':
                return <Image {...props} />
            case 'text':
                return <Text {...props} />
            case 'pricing':
                return <Pricing {...props} />
            case 'star':
                return <Star {...props} />
            case 'polygon':
                return <Polygon {...props} />
            default:
                if (type === 'element') console.log(props)
                throw new Error(`Type '${type}' is not supported`);
        }
    }
}

export default ComponentProvider;

function getRandomColor() {
    const colors = ['#9f9f9f', '#0032ff', '#00ff18', '#f4ff00', '#ff7b00', '#ff0000'];
    const index = Math.floor(Math.random() * colors.length);
    return colors[index];
}
