import React, { useState, useEffect } from "react";
import { useMember } from "@/contexts/member/MemberContext";
import { useNotifications } from "@/contexts/notification/NotificationContext";
import Api from "@/api/Api";
import Notify from "@/helpers/Notify";
import Notification from '@/components/notifications/Notification';
import Spinner from "@/components/svg/Spinner";

type RightSidebarProps = { isOpen: boolean, toggleSidebar: () => void };

const __BG_ANIMATED_GRADIENT__ = "transform-gpu transition-all duration-[600ms] bg-gradient-to-r to-dark-orange via-orange-400 from-dark-orange bg-size-200 bg-pos-0 hover:bg-pos-100 group";

const NotificationSidebar = ({ isOpen, toggleSidebar }: RightSidebarProps) => {
    const { member } = useMember();
    const { isLoginModalVisible } = useMember();
    
	const { notifications, refreshNotifications, isFetchingNotifications } = useNotifications();
    const [isLoadingReadNotification, setLoadingReadNotification] = useState(false);
    const notificationApi = new Api('notifications', 'ra');

    useEffect(() => {
        if (isOpen && isLoginModalVisible) toggleSidebar();
    }, [isOpen, isLoginModalVisible])

    const handleOverlayClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        toggleSidebar();
    };

	const onClickReadAll = async () => {
        setLoadingReadNotification(true);
        const request = notificationApi.request(member);
        const response = await notificationApi.post(request);
        if (response.success)
        {
            Notify.Success("Todas as notificações foram marcadas como lidas.");
            setLoadingReadNotification(false);
            refreshNotifications();
        }
	}

	return (
		<React.Fragment>
			<div
                className={`fixed inset-0 bg-black bg-opacity-30 transition-opacity duration-300 ease-in-out z-50 ${isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}
                onClick={handleOverlayClick}></div>
            <div
                className={`fixed top-0 right-0 h-screen z-50 bg-light-black dark:bg-slate-700 p-4 w-[30%] min-w-[250px] transition-all duration-300 ease-in-out ${isOpen ? 'transform-none opacity-100 pointer-events-auto' : 'transform translate-x-full opacity-0 pointer-events-none'}`}>
                <section className="flex flex-col h-full w-full">

					<div className="flex flex-row justify-between shadow-sm">
                        <span className="text-lg">Central de Notificações</span>
                        { isFetchingNotifications && <Spinner /> }
					</div>

                    <div className={"flex flex-col items-center h-full w-full overflow-auto px-2 py-4"}>
                        <div className="mt-2 w-full p-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                            <div className="p-4 space-y-4 max-h-[82vh] overflow-auto" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                {
                                    notifications.map((notification, index) => (
                                        <Notification key={index}
                                            id={notification.id}
                                            type={notification.type}
                                            userName={notification.userName}
                                            actionLabel='enviou uma mensagem'
                                            relativeTime={notification.date}
                                            title={notification.title}
                                            message={notification.message}
                                            read={notification.read}
                                        />
                                    ))
                                }
                                {
                                    notifications.length === 0 && (
                                        <div className='flex justify-center items-center p-4'>
                                            <p className='text-gray-400'>Nenhuma notificação</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                </section>

                <div className={`absolute bottom-4 inset-x-4 w-full flex justify-around items-center h-8 space-x-4 px-8`}
                >
                    <button className={`bg-gray-500 p-2 w-1/2 rounded-lg text-white text-center cursor-pointer`}
                        onClick={toggleSidebar}
                    >
                        Fechar
                    </button>
                    <button className={`${isLoadingReadNotification ? 'bg-gray-500' : __BG_ANIMATED_GRADIENT__} p-2 w-1/2 rounded-lg text-white text-center cursor-pointer`}
                        onClick={onClickReadAll}
                    >
                        Marcar todas como lidas
                    </button>
                </div>
            </div>
		</React.Fragment>
    );
};

export default NotificationSidebar;
