/**
 * ArrayHelper: Uma classe utilitária para manipular arrays de objetos.
 */
export default class ArrayHelper {
	/**
	 * Filtra um array de objetos pelo id e retorna o primeiro objeto correspondente.
	 */
	static filterById<T extends { id: string }>(
		array: T[],
		id: string
	): T | null {
		const result = array.filter((item) => item.id === id);
		return result.length > 0 ? result[0] : null;
	}

	/**
	 * Remove um objeto do array de acordo com o id fornecido.
	 */
	static removeById<T extends { id: string }>(array: T[], id: string): T[] {
		return array.filter((item) => item.id !== id);
	}

	/**
	 * Atualiza um objeto no array de acordo com o id fornecido, aplicando as atualizações parciais.
	 */
	static updateById<T extends { id: string }>(
		array: T[],
		id: string,
		update: Partial<T>
	): T[] {
		return array.map((item) =>
			item.id === id ? { ...item, ...update } : item
		);
	}

	/**
	 * Mapeia um objeto no array de acordo com o id fornecido, usando a função de callback fornecida.
	 */
	static mapById<T extends { id: string }>(
		array: T[],
		id: string,
		callback: (item: T) => T
	): T[] {
		return array.map((item) => (item.id === id ? callback(item) : item));
	}

	/**
	 * Filtra um array de objetos pela propriedade e valor fornecidos.
	 */
	static filterByPropertyValue<T extends Record<string, any>>(
		array: T[],
		property: string,
		value: any
	): T[] {
		return array.filter((item) => item[property] === value);
	}

	/**
	 * Remove um objeto do array de acordo com a propriedade e valor fornecidos.
	 */
	static removeByPropertyValue<T>(
		array: T[],
		property: keyof T,
		value: any
	): T[] {
		return array.filter((item) => item[property] !== value);
	}

	/**
	 * Encontra e retorna o primeiro objeto no array que possui a propriedade e valor fornecidos.
	 */
	static findByPropertyValue<T>(
		array: T[],
		property: keyof T,
		value: any
	): T | null {
		const item = array.find((item) => item[property] === value);
		return item || null;
	}

	/**
	 * Classifica um array de objetos pela propriedade fornecida, usando a função de comparação e a ordem (ascendente ou descendente).
	 */
	static sortByProperty<T>(
		array: T[],
		property: keyof T,
		compareFn: (a: T[keyof T], b: T[keyof T]) => number,
		ascending: boolean = true
	): T[] {
		return array.sort((a, b) =>
			ascending
				? compareFn(a[property], b[property])
				: compareFn(b[property], a[property])
		);
	}

	static getRandomValue<T>(array: T[]): T | undefined {
		if (array.length === 0) {
			return undefined;
		}
		const randomIndex = Math.floor(Math.random() * array.length);
		return array[randomIndex];
	}
}
