import React, { useEffect, useState } from 'react';
import Table from '@/components/tables/Table';
import EmptyBox from '@/components/animations/EmptyBox';
import { useProducts } from '@/contexts/products/ProductsContext';
import { EntityID } from "@/types/silverstripe";
import { ParentInterface } from '@/types';
import Api from '@/api/Api';
import { useMember } from '@/contexts/member/MemberContext';
import ArrayHelper from '@/helpers/ArrayHelper';
import { ExternalSearchType, ProductListItem, SilverProduct } from '@/types/products';
import ExternalProductModal from '@/components/modals/editor/ExternalProductModal';
import CategorySearchInput from '@/components/inputs/search/CategorySearchInput';
import ProductHelper from '@/helpers/ProductHelper';
import EpjLoadingIcon from '@/components/animations/EpjLoadingIcon';

const defaultFunction = () => null;
const buttonsClasses = "mt-2 mx-4 py-2 px-5 rounded-md transition-all duration-300";

interface ExternalProductTableInterface {
	isFetching: boolean;
	setIsFetching(value: boolean): void;
}

const ExternalProductTable: React.FC<ExternalProductTableInterface> = ({ isFetching, setIsFetching }) => {
	const [shouldLoadTableData, setShouldLoadTableData] = useState(true);
	const [isProductModalVisible, setIsProductModalVisible] = useState(false);

	const [productList, setProductList] = useState<ProductListItem[]>([]);
	const [originalList, setOriginalList] = useState<SilverProduct[]>([]);
	const [productListIndex, setProductListIndex] = useState(0);
	const [currentProduct, setCurrentProduct] = useState<SilverProduct | null>(null);

	const { shouldUpdateLists, setShouldUpdateLists } = useProducts();
	const { member } = useMember();

	const api = new Api('connection');

	const getList = async () => {
		if (member.isLogged()) {
			setIsFetching(true);
			const products: Array<ProductListItem> = [];
			const request = api.request(member, { index: productListIndex });
			const response = await api.post(request, 'get-products');
			if (response.success) {
				const list = response.data;
				list.forEach((current: any) => {
					const currentProduct = ProductHelper.parseProduct(current);
					products.push(currentProduct);
				})
				setProductList(products);
				setOriginalList(list);
			}
			setIsFetching(false);
		}
	}

	useEffect(() => {
		getList();
	}, [member, productListIndex]);

	useEffect(() => {
		if (shouldUpdateLists) {
			setProductList([]);
			getList();
			setShouldUpdateLists(false);
		}
	}, [shouldUpdateLists]);

	useEffect(() => {
		setTimeout(() => setShouldLoadTableData(false), 1000);
	}, []);

	const onOpenProductModal = (id: EntityID) => {
		const products = ArrayHelper.filterByPropertyValue(originalList, 'Ean', id);
		if (products.length === 0) return;

		const product = products[0];
		setCurrentProduct(product);
		setIsProductModalVisible(true);
	}

	const onCloseProductModal = () => {
		setIsProductModalVisible(false);
		setCurrentProduct(null);
	}

	const ImageIndicator = (data: any) => {
		return (
			<div className='flex w-full justify-center'>
				<div
					style={{
						width: "20px",
						height: "20px",
						borderRadius: "50%",
						backgroundColor: data.preview?.length ? "green" : "gray"
					}}
				/>
			</div>
		);
	};

	const columns = [
		{ accessor: 'ean', Header: 'EAN' },
		{ accessor: 'innerCode', Header: 'Código Interno' },
		{ accessor: 'description', Header: 'Descrição' },
		{ accessor: 'sPrice', Header: 'Preço' },
		{ accessor: 'cPrice', Header: 'Preço Clube' },
		{ accessor: 'oPrice', Header: 'Preço Oferta' },
		{ accessor: 'ePrice', Header: 'Preço Extra' },
		{ accessor: 'preview', Header: 'C/ Imagem', render: ImageIndicator },
	];

	const next = () => setProductListIndex(productListIndex + 1);
	const prev = () => setProductListIndex(prev => prev >= 1 ? prev - 1 : 0);
	const reset = () => setProductListIndex(0);

	const TableContainer = React.memo(() => {
		return (
			<div className={`flex flex-col p-6 pb-4 ${!isFetching && 'bg-slate-200 rounded-lg shadow-md'}`} >
				<Table
					columns={columns}
					data={productList}
					onEditButtonClick={onOpenProductModal}
					onDeleteButtonClick={defaultFunction}
					editButtonLabel="Detalhes"
					idKeyName='ean'
				/>
				{/* Paginação. */}
				<Pagination goTo={setProductListIndex} page={productListIndex} />
			</div>
		)
	});

	const handleSubmit = (term: string, searchType: ExternalSearchType, setIsLoading: (value: boolean) => void) => {
		let request: any = api.useHash(member);
		request[searchType] = term;

		setIsLoading(true);

		api.post(request, 'get-products').then((response) => {
			if (response.success) {
				const list = response.data;
				const products: Array<ProductListItem> = [];
				list.forEach((current: any) => {
					const currentProduct = ProductHelper.parseProduct(current);
					products.push(currentProduct);
				})
				setProductList(products);
				setOriginalList(list);
			}
		}).finally(() => setIsLoading(false));
	};

	const SearchContainer: React.FC<ParentInterface> = React.memo(({ children }) => {
		return (
			<div className='flex flex-col' >
				<CategorySearchInput onSubmit={handleSubmit} setList={setProductList}
					setOriginal={setOriginalList} updateLists={setShouldUpdateLists}
				/>
				{children}
			</div>
		);
	})

	return (
		<React.Fragment>
			<ExternalProductModal isOpen={isProductModalVisible} onClose={onCloseProductModal} product={currentProduct} />
			<div className="max-h-[80vh] min-w-fit p-2">
				<div className={`flex justify-center items-center
					${(!shouldLoadTableData && !isFetching && productList.length > 0) && 'mb-20'}`}>
					{
						(shouldLoadTableData || isFetching) ? (
							<div className='flex justify-center items-center' style={{ height: 200 }} >
								<EpjLoadingIcon />
							</div>
						) : (
							productList.length ? (
								<SearchContainer>
									<TableContainer />
								</SearchContainer>
							) : (
								<SearchContainer>
									<EmptyBox height={350} />
								</SearchContainer>
							)
						)
					}
				</div>
			</div>
		</React.Fragment>
	)
}

export default ExternalProductTable;

interface IPagination {
	goTo: (index: number) => void;
	page: number;
}
const Pagination: React.FC<IPagination> = ({ goTo, page }) => {
	const next = () => goTo(page + 2);
	const prev = () => goTo(page >= 1 ? page - 1 : 0);
	const reset = () => goTo(0);

	return (
		<div className="bg-white p-4 flex items-center justify-center flex-wrap w-full">
			<nav aria-label="Page navigation">
				<ul className="inline-flex">
					{
						page > 0 && (
							<li onClick={reset} >
								<button className="h-10 px-5 text-green-600 transition-colors duration-150 rounded-l-lg focus:shadow-outline hover:bg-green-100">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-left" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" /> <path fillRule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" /> </svg>
								</button>
							</li>
						)
					}

					<li onClick={prev} >
						<button className="h-10 px-5 text-green-600 transition-colors duration-150 rounded-l-lg focus:shadow-outline hover:bg-green-100">
							<svg className="w-4 h-4 fill-current" viewBox="0 0 20 20"><path d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" fillRule="evenodd"></path></svg></button>
					</li>

					{
						page > 0 && <li onClick={() => goTo(page)} ><button className="h-10 px-5 text-green-600 transition-colors duration-150 focus:shadow-outline hover:bg-green-100">{page}</button></li>
					}
					<li onClick={() => goTo(page)} ><button className="h-10 px-5 text-white transition-colors duration-150 bg-green-600 border border-r-0 border-green-600 focus:shadow-outline">{page + 1}</button></li>
					<li onClick={() => goTo(page + 1)} ><button className="h-10 px-5 text-green-600 transition-colors duration-150 focus:shadow-outline hover:bg-green-100">{page + 2}</button></li>
					{
						page === 0 && <li onClick={() => goTo(page + 2)} ><button className="h-10 px-5 text-green-600 transition-colors duration-150 focus:shadow-outline hover:bg-green-100">{page + 3}</button></li>
					}

					<li onClick={next} >
						<button className="h-10 px-5 text-green-600 transition-colors duration-150 bg-white rounded-r-lg focus:shadow-outline hover:bg-green-100">
							<svg className="w-4 h-4 fill-current" viewBox="0 0 20 20"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" fillRule="evenodd"></path></svg></button>
					</li>
				</ul>
			</nav>
		</div>
	);
}
