import React from "react";
import HandleModal from "@/components/modals/BlurModal";
import { ModalInterface } from "@/types";
import { useCanvas, useCanvasDispatch } from "@/contexts/editor/CanvasContext";
import { initialState } from "@/contexts/editor/reducer/state/IStateReducer";
import Notify from "@/helpers/Notify";
import ListCampaigns from "./partials/ListCampaigns";
import { useModals } from "@/contexts/modals/ModalsContext";
import { useMember } from "@/contexts/member/MemberContext";

const CampaignModal: React.FC<ModalInterface> = ({ isOpen, onClose }) => {
	const { member } = useMember();

	const { setShowCanvasModal } = useModals();

	const { state, loadStateData } = useCanvas();
	const { stateDispatch } = useCanvasDispatch();
	const { campaignName, campaignId, campaignComboType } = state;

	const handleCampaignNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		stateDispatch({ type: "setCampaignName", campaignName: e.target.value });
	};

	const handleSubmit = () => {
		if (!campaignName || !campaignId) {
			Notify.warn("Por favor, insira um nome para a campanha")
				.bottomCenter();
			return;
		}
		loadStateData({
			...initialState,
			campaignName,
			campaignId,
			campaignComboType,
			editor: {
				...initialState.editor,
				viewMode: member.can('create_banner') ? "edit" : "view"
			}
		});
		setTimeout(() => setShowCanvasModal(true), 500);
		onClose();
	}

	return (
		<HandleModal isOpen={isOpen} onClose={onClose}>
			<div className="flex justify-center items-center flex-col w-[25rem]">
				<h2 className="text-xl font-bold mb-4">Novo Encarte</h2>

				<h2 className="text-sm font-semibold mb-4">Primeiro defina alguns detalhes da campanha</h2>

				<ListCampaigns
					campaignName={campaignName || ''}
					handleCampaignNameChange={handleCampaignNameChange}
				/>

				<div className="mt-4 mb-2 w-full flex flex-row justify-between items-center space-x-2">
					<button onClick={onClose} className="bg-gray-500 w-20 px-3 py-2 rounded-md text-white">
						Fechar
					</button>
					<button onClick={handleSubmit} className="bg-app-primary w-20 px-3 py-2 rounded-md text-white">
						Salvar
					</button>
				</div>
			</div>
		</HandleModal>
	);
};

export default CampaignModal;
