import Api from "@/api/Api";
import { uuid } from "@/helpers";
import { ParentInterface } from "@/types";
import { EntityID } from "@/types/silverstripe";
import { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { useMember } from "../member/MemberContext";
import Notify from "@/helpers/Notify";

export const ItemType = {
    TASK: 'TASK',
    GROUP: 'GROUP'
};

export interface SubItem {
    id: string;
    title: string;
    done: boolean;
}

export interface Comment {
    id: string;
    content: string;
    creator: EntityID;
    creatorName: string;
    likes: Array<EntityID>;
    deleted: boolean;
}

export interface TaskInterface {
    id: string;
    title: string;
    description: string;
    done: boolean;
    subItems: SubItem[];
    comments: Comment[];
    groupId: string;
    creatorID: EntityID;
    creatorName: string;
    assigneeID: EntityID;
    assigneeName: string;
    isModel: boolean;
    isChild: boolean;
    children: Array<EntityID>;
    propagateChanges: boolean;
    autoFinish: boolean;
    date: string | null;
    repeat: 'none' | 'daily' | 'weekly' | 'monthly';
}

export interface GroupInterface {
    id: string;
    name: string;
    creatorID: EntityID;
}

interface TaskContextData {
    tasks: TaskInterface[];
    setTasks: (tasks: TaskInterface[]) => void;
    targetTaskID: string | null;
    setTargetTaskID: (taskID: string | null) => void;
    targetGroupID: string | null;
    setTargetGroupID: (taskID: string | null) => void;
    groups: GroupInterface[];
    shouldReloadTasks: boolean;
    setShouldReloadTasks: (value: boolean) => void;
    addTask: (title: string, groupId: string) => string;
    removeTask: (id: string) => void;
    toggleTask: (task: TaskInterface) => void;
    addGroup: (name: string, targetId?: string) => string;
    findGroupByTaskId: (taskId: string) => GroupInterface | undefined;
    findTasksByGroupId: (groupId: string) => TaskInterface[];
    moveTaskToGroup: (taskId: string, newGroupId: string) => void;
    moveGroup: (fromIndex: number, toIndex: number) => void;
    removeGroup: (groupId: string) => void;
    duplicateTask: (task: TaskInterface, newGroupId: string) => TaskInterface;
    duplicateGroup: (groupId: string) => string | null;
    editTask: (taskId: string, newTask: TaskInterface) => void;
    editGroup: (groupId: string, newGroup: GroupInterface) => void;
    addSubItem: (taskId: string, subItem: string) => void;
    addComment: (taskId: string, comment: string) => void;
    toggleSubItem: (taskId: string, subItemId: string) => void;
    editSubItem: (taskId: string, subItemId: string, newDescription: string) => void;
    removeSubItem: (taskId: string, subItemId: string) => void;
    removeComment: (taskId: string, commentId: string) => void;
    likeComment: (taskId: string, commentId: string) => void;
}

const TaskContext = createContext<TaskContextData>(
    {} as TaskContextData
);

export const TaskProvider: React.FC<ParentInterface> = ({ children }) => {
    const { member } = useMember();
    const [tasks, setTasks] = useState<TaskInterface[]>([]);
    const [groups, setGroups] = useState<GroupInterface[]>([]);
    const [targetTaskID, setTargetTaskID] = useState<string | null>(null);
    const [targetGroupID, setTargetGroupID] = useState<string | null>(null);
    const [shouldReloadTasks, setShouldReloadTasks] = useState<boolean>(true);

    const fetchItems = async () => {
        const api = new Api('tasks', 'gg');
        const request = api.useHash(member);
        const response = await api.post(request);
        if (response.success)
        {
            const list = response.data;
            if (list.length > 0) list.sort((a: any, b: any) => a.Index - b.Index);

            const loadedGroups: Array<GroupInterface> = [];
            const loadedTasks: Array<TaskInterface> = [];
            list.forEach((item: any) => {
                loadedGroups.push({ name: item.Name, id: item.ID, creatorID: item.CreatorID });
                item.Tasks.forEach((task: any) => {
                    loadedTasks.push({
                        id: task.ID,
                        title: task.Title,
                        description: task.Description || "",
                        done: task.Done || false,
                        subItems: task.SubItems.map((item: any): SubItem => createSubItemObject(item)) || [],
                        comments: task.Comments.map((item: any): Comment => createCommentObject(item)) || [],
                        groupId: task.GroupID,
                        creatorID: task.CreatorID,
                        creatorName: task.CreatorName,
                        assigneeID: task.AssigneeID,
                        assigneeName: task.AssigneeName,
                        isModel: Boolean(task.IsModel),
                        isChild: Boolean(task.IsChild),
                        children: task.Children,
                        propagateChanges: Boolean(task.PropagateChanges),
                        autoFinish: Boolean(task.AutoFinish),
                        date: task.Date,
                        repeat: task.Repeat
                    });
                });
            });
            setGroups(loadedGroups);
            setTasks(loadedTasks);
        }
        setShouldReloadTasks(false);
    }

    useEffect(() => {
        if (shouldReloadTasks && member.isLogged())
        {
            fetchItems();
        }
    }, [shouldReloadTasks, member]);

    const addTask = (title: string, groupId: string, data?: TaskInterface) => {
        const id = uuid();
        const newTask: TaskInterface = {
            id,
            title,
            description: '',
            done: false,
            groupId,
            creatorID: member.id,
            creatorName: member.firstName,
            assigneeID: null,
            assigneeName: '',
            subItems: [],
            comments: [],
            isModel: false,
            isChild: false,
            children: [],
            propagateChanges: false,
            autoFinish: false,
            date: null,
            repeat: 'none',
            ...data
        };
        setTasks([...tasks, newTask]);
        return id;
    };

    const removeTask = (id: string) => {
        setTasks(tasks.filter(task => task.id !== id));
    };

    const toggleTask = async (task: TaskInterface) => {
        setTasks(tasks.map(current => 
            current.id === task.id ? { ...current, done: !current.done } : current
        ));
    
        const api = new Api('tasks', 'edit-task');
        const request = api.request(member, { id: task.id, payload: { Done: !task.done } });
        const response = await api.post(request);
        if (response.success) setShouldReloadTasks(true);
        else Notify.Warn("Ocorreu um erro ao atualizar a tarefa.");
    };

    const addGroup = (name: string, targetId?: string) => {
        const id = targetId || uuid();
        const newGroup = { id, name, creatorID: member.id };
        setGroups([...groups, newGroup]);
        return id;
    };

    const findGroupByTaskId = (taskId: string) => {
        const task = tasks.find(t => t.id === taskId);
        if (task) {
            return groups.find(g => g.id === task.groupId);
        }
        return undefined;
    };

    const findTasksByGroupId = (groupId: string) => {
        return tasks.filter(task => task.groupId === groupId);
    };

    const moveTaskToGroup = async (taskId: string, newGroupId: string) => {
        // Move temporariamente para efeito visual.
        setTasks(tasks.map(task =>
            task.id === taskId ? { ...task, groupId: newGroupId } : task
        ));
        const api = new Api('tasks', 'mt');
        const request = api.request(member, { id: taskId, groupId: newGroupId });
        const response = await api.post(request);
        if (!response.success) Notify.Warn("Ocorreu um erro ao mover a tarefa.");
        setShouldReloadTasks(true);
    };

    const moveGroup = async (fromIndex: number, toIndex: number) => {
        const group1: GroupInterface | null = groups[fromIndex] || null;
        const group2: GroupInterface | null = groups[toIndex] || null;

        setGroups((prevGroups) => {
            const updatedGroups = [...prevGroups];
            [updatedGroups[fromIndex], updatedGroups[toIndex]] = [updatedGroups[toIndex], updatedGroups[fromIndex]];
            return updatedGroups;
        });
        
        if (group1 && group2)
        {
            const api = new Api('tasks', 'mg');
            const request = api.request(member, { groupId1: (group1 as GroupInterface).id, groupId2: (group2 as GroupInterface).id });
            const response = await api.post(request);
            if (response.success) setShouldReloadTasks(true);
            else Notify.Warn("Ocorreu um erro ao mover o grupo.");
        }
    };
    
    const removeGroup = (groupId: string) => {
        setGroups(prev => prev.filter(g => g.id !== groupId));
        setTasks(prev => prev.filter(t => t.groupId !== groupId));
    }

    const duplicateTask = (task: TaskInterface, newGroupId: string) => {
        const newTaskId = uuid();
        const newSubItems = task.subItems.map(item => ({ ...item, done: false }));
        const newTask = {
            ...task,
            id: newTaskId,
            groupId: newGroupId,
            done: false,
            subItems: newSubItems,
            comments: [],
        };
        return newTask;
    };

    const duplicateGroup = (groupId: string) => {
        const groupToDuplicate = groups.find(group => group.id === groupId);
        if (!groupToDuplicate) return null;
    
        const newGroupId = uuid();
        const newGroup = { ...groupToDuplicate, id: newGroupId, name: groupToDuplicate.name + ' (Cópia)' };
        
        let tasksToDuplicate: TaskInterface[] = tasks.filter(task => task.groupId === groupId);
        tasksToDuplicate = tasksToDuplicate.map(task => duplicateTask(task, newGroupId));
    
        setGroups([...groups, newGroup]);
        setTasks([...tasks, ...tasksToDuplicate]);
        return newGroupId;
    };

    const editTask = (taskId: string, newTask: TaskInterface) => {
        setTasks(prevTasks =>
            prevTasks.map(task =>
                task.id === taskId ? { ...task, ...newTask } : task
            )
        );
    }

    const editGroup = (groupId: string, newGroup: GroupInterface) => {
        setGroups(prevGroups =>
            prevGroups.map(group =>
                group.id === groupId ? { ...group, ...newGroup } : group
            )
        );
    }

    const addSubItem = async (taskId: string, content: string) => {
        const temId = uuid();
        const subItem: SubItem = {
            id: temId,
            title: content,
            done: false
        }
        setTasks(prevTasks =>
            prevTasks.map(task =>
                task.id === taskId ? { ...task, subItems: [...task.subItems, subItem] } : task
            )
        );
        const api = new Api('tasks', 'edit-subitem');
        const request = api.request(member, { payload: { TaskID: taskId, Title: content } });
        const response = await api.post(request);
        if (response.success) setShouldReloadTasks(true);
        else Notify.Warn("Não foi possível salvar o item.");
    }

    const addComment = async (taskId: string, content: string) => {
        const comment: Comment = {
            id: uuid(),
            content,
            creator: member.id,
            creatorName: member.firstName,
            likes: [],
            deleted: false
        }
        setTasks(prevTasks =>
            prevTasks.map(task =>
                task.id === taskId ? { ...task, comments: [...task.comments, comment] } : task
            )
        );
        const api = new Api('tasks', 'create-comment');
        const request = api.request(member, { payload: { TaskID: taskId, Content: content } });
        const response = await api.post(request);
        setShouldReloadTasks(true);
        if (!response.success) Notify.Warn("Não foi possível criar comentário.");
    }

    const toggleSubItem = (taskId: string, subItemId: string) => {
        const update = async (id: string, done: boolean) => {
            const api = new Api('tasks', 'edit-subitem');
            const request = api.request(member, { id, payload: { Done: done } });
            const response = await api.post(request);
            if (response.success) setShouldReloadTasks(true);
            else Notify.Warn("Não foi possível atualizar o item.");
        }
        setTasks((prevTasks) => {
            return prevTasks.map((task) => {
                if (task.id !== taskId) return task;

                return {
                    ...task,
                    subItems: task.subItems.map((subItem) => {
                        if (subItem.id !== subItemId) return subItem;
                        update(subItem.id, !subItem.done);

                        return {
                            ...subItem,
                            done: !subItem.done,
                        };
                    }),
                };
            });
        });
    };

    const editSubItem = async (taskId: string, subItemId: string, content: string) => {
        setTasks((prevTasks) => {
            return prevTasks.map((task) => {
                if (task.id !== taskId) return task;
    
                return {
                    ...task,
                    subItems: task.subItems.map((subItem) => {
                        if (subItem.id !== subItemId) return subItem;

                        return {
                            ...subItem,
                            title: content,
                        };
                    }),
                };
            });
        });
        const api = new Api('tasks', 'edit-subitem');
        const request = api.request(member, { id: subItemId, payload: { TaskID: taskId, Title: content } });
        const response = await api.post(request);
        if (response.success) setShouldReloadTasks(true);
        else Notify.Warn("Não foi possível salvar o item.");
    };

    const removeSubItem = async (taskId: string, subItemId: string) => {
        setTasks((prevTasks) => {
            return prevTasks.map((task) => {
                if (task.id !== taskId) return task;
    
                return {
                    ...task,
                    subItems: task.subItems.filter((item) => item.id !== subItemId)
                };
            });
        });
        const api = new Api('tasks', 'rsi');
        const request = api.request(member, { id: subItemId });
        const response = await api.post(request);
        if (response.success) setShouldReloadTasks(true);
        else Notify.Warn("Não foi possível remover o item.");
    };

    const removeComment = async (taskId: string, commentId: string) => {
        setTasks((prevTasks) => {
            return prevTasks.map((task) => {
                if (task.id !== taskId) return task;
    
                return {
                    ...task,
                    comments: task.comments.filter((comment) => comment.id !== commentId)
                };
            });
        });
        const api = new Api('tasks', 'rc');
        const request = api.request(member, { id: commentId });
        const response = await api.post(request);
        if (response.success) setShouldReloadTasks(true);
        else Notify.Warn("Não foi possível remover o comentário.");
    };

    const likeComment = async (taskId: string, commentId: string) => {
        const targetComment = tasks.find(t => t.id === taskId)
            ?.comments.find(c => c.id === commentId);
        if (!targetComment) return;

        const nextStatus = !targetComment.likes.includes(member.id);
        
        setTasks((prevTasks) => {
            return prevTasks.map((task) => {
                if (task.id !== taskId) return task;
                
                return {
                    ...task,
                    comments: task.comments.map((comment) => {
                        if (comment.id !== commentId) return comment;
                        
                        return {
                            ...comment,
                            likes: nextStatus ? 
                                   [...comment.likes, member.id] : 
                                   comment.likes.filter(id => id !== member.id)
                        };
                    })
                };
            });
        });
        const api = new Api('tasks', 'hcl');
        const request = api.request(member, { id: commentId, liked: nextStatus });
        const response = await api.post(request);
        if (!response.success) Notify.Warn("Não foi possível atualizar o comentário.");
        setShouldReloadTasks(true);
    };

    return (
        <TaskContext.Provider
            value={{
                tasks,
                setTasks,
                targetTaskID,
                setTargetTaskID,
                targetGroupID,
                setTargetGroupID,
                groups,
                shouldReloadTasks,
                setShouldReloadTasks,
                addTask,
                removeTask,
                toggleTask,
                addGroup,
                findGroupByTaskId,
                findTasksByGroupId,
                moveTaskToGroup,
                moveGroup,
                removeGroup,
                duplicateTask,
                duplicateGroup,
                editTask,
                editGroup,
                addComment,
                addSubItem,
                toggleSubItem,
                editSubItem,
                removeSubItem,
                removeComment,
                likeComment
            }}
        >
            {children}
        </TaskContext.Provider>
    );
};

export const useTasks = (): TaskContextData => {
    const context = useContext(TaskContext);

    if (!context) {
        throw new Error(
            "useTasks must be used within a TaskProvider"
        );
    }

    return context;
};

const createSubItemObject = (item: any): SubItem => ({ id: item.ID, title: item.Title, done: item.Done });

const createCommentObject = (item: any): Comment => (
    {
        id: item.ID,
        content: item.Content,
        creator: item.CreatorID,
        creatorName: item.CreatorName,
        likes: item.Likes,
        deleted: Boolean(item.Deleted)
    });
