import React, { createContext, useContext, useState, useRef, useEffect, useReducer } from "react";
import ComponentProvider from "@/components/editor/ComponentProvider";
import Konva from "konva";
import {
    CanvasState,
    ShapeInfo,
    ElementData,
    GetElementDataByIdPayload,
    TextType,
    StageRef,
    GroupData,
    ReducedCanvasState,
    CanvasContextInterface,
    OnSuccessFn,
    PriceType,
    ElementType,
    ImageLoadingState,
    IUseCustomText,
    ComboType,
} from "@/types/canvas";
import { DateTime } from "luxon";
import Notify from "@/helpers/Notify";
import TextHelper from "@/helpers/TextHelper";
import { PackType, ProductListItem } from "@/types/products";
import { ParentInterface } from "@/types";
import { __BANNER_TYPES__, __BANNER_TYPE__ } from "@/types/constants";
import DEBUG from '@/helpers/DEBUG';
import StateReducer, { history } from "./reducer/state/StateReducer";
import { ICanvasDispatch, __BACKGROUND__, initialEditorState, initialMemories, initialSelection, initialState } from "./reducer/state/IStateReducer";
import Api from "@/api/Api";
import { EntityID } from "@/types/silverstripe";
import { convertResponseToCanvasState } from "@/components/cards/models/BannerCard";
import { useMember } from "../member/MemberContext";
import { usePricing } from "../pricing/PricingContext";

// Contexto
const CanvasContext = createContext<CanvasContextInterface>({} as CanvasContextInterface);
const CanvasDispatchContext = createContext<ICanvasDispatch>({} as ICanvasDispatch);

// Hook personalizado
export const useCanvas = () => {
    const context = useContext(CanvasContext);

    if (!context) {
        throw new Error("useCanvas must be used within an CanvasContext");
    }
    return context;
}

export const useCanvasDispatch = () => {
    const context = useContext(CanvasDispatchContext);

    if (!context) {
        throw new Error("useCanvasDispatch must be used within an CanvasDispatchContext");
    }
    return context;
}

export const CanvasProvider: React.FC<ParentInterface> = ({ children }) => {
    const { member } = useMember();

    const [isLoadingTemplateData, setIsLoadingTemplateData] = useState<boolean>(false);
    const [loadRefs, setLoadRefs] = useState(false);
    const [allImagesLoaded, setAllImagesLoaded] = useState(false);
    const [imageLoadingState, setImageLoadingState] = useState<ImageLoadingState>({});
    const [shouldReloadTemplateData, setShouldReloadTemplateData] = useState<boolean>(true);
    const [isGeneratingImage, setIsGeneratingImage] = useState<boolean>(false);
    const [currentFontSize, setFontSize] = useState<number>(0);
    const [isFuturePrice, setFuturePrice] = useState<boolean>(false);
    const [lineID, setLineID] = useState<string>('');
    const [useCustomText, setUseCustomText] = useState<IUseCustomText>({ textClub: false });

    const [state, stateDispatch] = useReducer(StateReducer, initialState);

    const newGroupId = useRef<string | null>(null);
    const isEditing = state.editor.viewMode === 'edit';

    useEffect(() => {
        if (isEditing) {
            history.clear();
        }
    }, [isEditing]);

    const priceTypeHierarchy = (types: PriceType[]) => {
        let priceHierarchy: PriceType[] = ["simple", "offer", "club", "extra"];
        let currentPriceType: PriceType = "simple";

        types.forEach(e => {
            const positionNewPriceType = priceHierarchy.indexOf(e);
            const positionCurrentPrice = priceHierarchy.indexOf(currentPriceType);
            if (positionNewPriceType > positionCurrentPrice) {
                currentPriceType = priceHierarchy[positionNewPriceType];
            }
        })

        stateDispatch({ type: "setPriceType", priceType: currentPriceType })
    }

    const loadExistingTemplate = async (id: EntityID) => {
        const api = new Api('templates', 'g');
        const request = api.request(member, { templateID: id });
        const response = await api.post(request);
        if (response.success) {
            const canvasState = convertResponseToCanvasState(response);
            return loadStateData(canvasState as CanvasState);
        }
        else Notify.Error("Houve um erro com este template!");
        return false;
    }

    const reloadTemplate = async (templateId: string): Promise<boolean> => {
        if (templateId && shouldReloadTemplateData) {
            console.log('reloading template')
            stateDispatch({ type: "resetCanvas" });
            return await loadExistingTemplate(templateId);
        }
        return false;
    }

    // FIXME: executar apenas ao salvar o cartaz.
    const setTemplateType = () => {
        const price: PriceType[] = [];
        state.products.ids.forEach(e => {
            const product = getElementsByProductId(e);
            product.forEach(e => {
                if (e.attributes.priceType) {
                    if (!price.includes(e.attributes.priceType)) {
                        price.push(e.attributes.priceType);
                    }
                }
                setTemplateComboType(e.attributes.textType);
            })
        })
        priceTypeHierarchy(price);

    }

    const autoDefineComboType = (type: TextType): ComboType | null => {
        if (!type) return null;
        const textType = type.toLowerCase();

        if (textType.includes("ap_x")) return "ap_x_un";
        if (textType.includes("levex")) return "leve_x_pague_y";
        if (textType.includes("xoff")) return "xOff";
        if (textType.includes("de_por")) return "de_por";
        if (textType.includes("leve_por")) return "leve_x_por";
        if (textType.includes("oferta_pack")) return "oferta_pack";
        return null;
    }

    const setTemplateComboType = (type: TextType) => {
        const comboType = autoDefineComboType(type)
        if (comboType != null) {
            return stateDispatch({ type: "setComboType", comboType: comboType })
        }
    }

    const getNewGroupId = () => newGroupId.current;

    const setNewGroupId = (groupId: string | null = null) => {
        newGroupId.current = groupId;
    }

    /**
     * Retorna um array ordenado dos elementos por zIndex
     * @returns array
     */
    const getSortedRenderData = (): (ElementData | GroupData)[] => {
        const renderData = [...state.elements, ...state.groups];
        return renderData.sort((a, b) => a.zIndex - b.zIndex);
    };

    const getElementDataById = (id: GetElementDataByIdPayload): ElementData | null => {
        const data = state.elements.filter(data => (data.attributes.id === id));
        return data.length > 0 ? data[0] : null;
    }

    const getGroupDataById = (id: string | null): GroupData | null => {
        if (id) {
            const groupId = id.replace('group_selection_', '');
            const data = state.groups.filter(group => (group.id === groupId));
            return data.length > 0 ? data[0] : null;
        }
        return null;
    }

    const selectProduct = (id: string | null) => {
        stateDispatch({ type: "selectProduct", id: id });
    };

    const updateProductTextElements = async (productID: string | null, targetProduct: ProductListItem | null = null) => {
        if (!productID) return;

        const productIndex = TextHelper.getProductIndex();
        const product = TextHelper.productSamples[productIndex];

        const target: ProductListItem = targetProduct || product;
        const productElements = getElementsByProductId(productID);

        const writeAndResize = (ref: any, value: string, desc: string = "", ignore?: boolean) => {
            if (!value) return;

            // Substitui o placeholder [[x]] pelo valor do produtogo
            let defaultText = ref.getDefaultText();
            // para ignorar o texto que vem antes do [[x]]
            if (ignore) defaultText = defaultText.replace(/.*(\[\[x\]\]).*/, "$1");

            const text = defaultText.includes('[[x]]') ?
                (value ? defaultText.replace('[[x]]', value) : "") : value;

            ref.writeText(desc + text);
        };

        productElements.map(async data => {
            if (data.attributes && data.ref && data.ref.current) {
                const { attributes: element, ref: { current: ref } } = data;
                const { textType, priceType, source, text } = element;

                if (element.type === "text") {
                    const priceMap = {
                        simple: isFuturePrice ? target.oPrice : target.sPrice,
                        club: target.cPrice,
                        offer: target.oPrice,
                        extra: target.ePrice
                    }

                    if (target.wantUseProductPerGram && textType === "price_per_gram") {
                        const priceGramType = ref.attrs.priceType as PriceType;
                        let price = priceMap[priceGramType];
                        if (!price) price = priceMap.simple
                        writeAndResize(ref, price as string);
                    } else {
                        if (textType === "price_per_gram")
                            writeAndResize(ref, " ", "", true);
                    }

                    if (textType === 'description' || textType === 'price') {
                        const text = target.wantShortDescription && target.shortDescription ? target.shortDescription : target.description;
                        writeAndResize(ref, text);
                    }

                    if (target.wantUseProductPerGram && source === "packType") {
                        target.packType = "100g" as PackType;
                    }

                    if (textType === 'digits' || textType === 'cents') {
                        const price = priceType && priceMap[priceType] ? priceMap[priceType] : target.sPrice;
                        if (price) {
                            const [digits, cents] = TextHelper.formatPrice(price);
                            const isDigits = textType === 'digits';

                            if (target.wantUseProductPerGram) {
                                // nao usar toFixed, pois numeros com muitas casas decimais como '3,499000' vao ser arredondados para 3,50
                                let priceInGram = Math.floor((parseFloat(price) * 0.1) * 100) / 100;
                                let realAndDecimal = priceInGram.toFixed(2).toString().split(".");

                                return writeAndResize(ref, isDigits ? realAndDecimal[0] : "," + realAndDecimal[1]);
                            }

                            writeAndResize(ref, isDigits ? digits : cents);
                        }
                    }

                    if (
                        textType === "ap_x_UN__price_digits" || textType === "ap_x_UN__price_cents" ||
                        textType === 'xOff_price_digits' || textType === 'xOff_price_cents' ||
                        textType === "leveX_pagueY_digits" || textType === "leveX_pagueY_cents" ||
                        textType === "leve_Por__price_digits" || textType === "leve_Por__price_cents" ||
                        textType === "oferta_pack__price_digits" || textType === "oferta_pack__price_cents"
                    ) {
                        const isDigits =
                            textType === "ap_x_UN__price_digits"
                            || textType === 'xOff_price_digits'
                            || textType === 'leve_Por__price_digits'
                            || textType === "leveX_pagueY_digits"
                            || textType === "oferta_pack__price_digits";
                        if (target.dynamicPricingDigits || target.dynamicPricingCents) {
                            writeAndResize(ref, isDigits ? target.dynamicPricingDigits as string : target.dynamicPricingCents as string);
                        }
                    }

                    if (textType === 'product_source' && source) {
                        const newText = target[source] || text || '';
                        writeAndResize(ref, String(newText));
                    }

                    if (textType === 'date_limit') {
                        if (targetProduct?.initDate && targetProduct?.endDate && isFuturePrice)
                            writeAndResize(ref, `${targetProduct.initDate} ATÉ ${targetProduct.endDate}`, "OFERTA VÁLIDA DE ", true);
                        // @ts-ignore
                        else ref.writeText("");
                    }

                    if (textType === "cpf_limit") {
                        if (targetProduct?.cpfLimit)
                            writeAndResize(ref, String(targetProduct.cpfLimit));
                    }
                }

                if (element.type === "rectangle") {
                    if (!target.wantUseProductPerGram && textType === "price_per_gram") {
                        // deixar o retangulo invisivel
                        element.fill = "#FF000000 ";
                    }
                }
            }
        })
    };

    const updateProductImageElements = async (productID: string | null, targetProduct: ProductListItem | null = null) => {
        if (!productID) return;
        const productIndex = TextHelper.getProductIndex();
        const product = TextHelper.productSamples[productIndex];

        const target = targetProduct || product;

        const productElements = getElementsByProductId(productID).filter(el => el.attributes.type === "image");

        if (productElements.length) {
            for (const data of productElements) {
                if (data.attributes && data.ref) {
                    if (data.ref.current && data.attributes.imageType === "barCode") {
                        // @ts-ignore
                        data.ref.current.generateBarCode(target.ean, target.wantShortDescription);
                    }
                    if (data.attributes.imageType === "product") {
                        const element = data.attributes;
                        // @ts-ignore
                        await data.ref.current.updateImageUrl(target.preview, element);
                    }
                }
            }
        }
    };

    const getElementsByGroupId = (groupID: string): ElementData[] => {
        if (!groupID) return [];
        return state.elements.filter((elementData) => elementData.attributes.groupID === groupID);
    };

    const getElementsByProductId = (productID: string | null): ElementData[] => {
        if (!productID) return [];
        return state.elements.filter((elementData) => elementData.attributes.productID === productID);
    };

    const updateTextType = async (type: TextType, id?: string | null, onSuccess?: OnSuccessFn) => {
        const currentID = id || state.current.id;
        const data = getElementDataById(currentID);
        if (currentID && data && data.attributes && data.ref && shapeInfo.isText) {
            const attributes = data.attributes;
            const ref = data.ref.current as Konva.Text;
            let newText = '';
            let priceType: PriceType | null = null;
            switch (type) {
                case 'description':
                    newText = 'Descrição do Produto';
                    break;
                case 'price':
                    newText = '00,00';
                    break;
                case 'digits':
                    newText = '00';
                    priceType = 'simple';
                    break;
                case 'cents':
                    newText = ',00';
                    priceType = 'simple';
                    break;
                default:
                    newText = 'Novo Texto';
                    break;
            }
            stateDispatch({
                type: "updateElement", payload: {
                    ...data,
                    id: currentID,
                    attributes: {
                        ...attributes,
                        text: newText,
                        textType: type,
                        priceType
                    }
                }
            });
            // @ts-ignore
            ref.autosize();
            if (onSuccess) onSuccess(type);
        }
    }

    const updatePriceType = (priceType: PriceType | null, id?: string | null) => {
        const currentID = id || state.current.id;
        const data = getElementDataById(currentID);

        if (currentID && data && data.attributes && data.ref && shapeInfo.isText) {
            const { attributes } = data;
            stateDispatch({
                type: "updateElement", payload: {
                    id: currentID, attributes: { ...attributes, priceType }
                }
            });
        }
    }

    const updateFontSize = (size: number, onSuccess?: OnSuccessFn) => {
        const currentID = state.current.id;
        const data = getElementDataById(currentID);

        if (currentID && data && data.ref && shapeInfo.isText) {
            setFontSize(size);
            const attributes = data.attributes;
            stateDispatch({
                type: "updateElement",
                payload: {
                    id: currentID, attributes: { ...attributes, fontSize: size }
                }
            });
            if (onSuccess) onSuccess(size);
        }
    }

    const updateFontFamily = (value: string, onSuccess?: OnSuccessFn) => {
        const currentID = state.current.id;
        const data = getElementDataById(currentID);

        if (data && data.ref && shapeInfo.isText) {
            const attributes = data.attributes;
            const fontFamily = value.replaceAll(/\s/g, '');

            if (attributes.productID && attributes.priceGroupID) {
                for (let i = 0; i < state.elements.length; i++) {
                    const element = state.elements[i];
                    if (element.attributes.priceGroupID === attributes.priceGroupID) {
                        stateDispatch({
                            type: "updateElement",
                            payload: {
                                id: element.attributes.id,
                                attributes: { ...element.attributes, fontFamily: fontFamily }
                            }
                        });
                    }
                }
            }
            else {
                stateDispatch({
                    type: "updateElement",
                    payload: {
                        id: attributes.id,
                        attributes: { ...attributes, fontFamily: fontFamily }
                    }
                });
            }

            if (onSuccess) onSuccess(fontFamily);
        }
    };

    const updateTextAlign = (alignment: 'left' | 'right' | 'center', onSuccess?: OnSuccessFn) => {
        const currentID = state.current.id;
        const data = getElementDataById(currentID);
        if (data && currentID && data.ref && state.current.info.isText) {
            const attributes = data.attributes;
            stateDispatch({
                type: "updateElement",
                payload: {
                    id: attributes.id, attributes: { ...attributes, align: alignment }
                }
            });

            if (onSuccess) onSuccess(alignment);
        }
        else console.log('no shape selected for ', currentID)
    }

    const writeOnSelected = async (value: string, multiline: boolean = true) => {
        const currentID = state.current.id;
        const data = getElementDataById(currentID);
        if (data && currentID && data.ref && state.current.info.isText) {
            const ref = data.ref.current as Konva.Text;
            // @ts-ignore
            await ref.writeText(value);
            // @ts-ignore
            if (!multiline) ref.autosize(multiline);
        }
        else console.log('no shape selected for ', currentID)
    }

    const resetCanvasState = (newValues?: Partial<CanvasState>) => {
        stateDispatch({
            type: "resetCanvas",
            newValues: newValues
        });
        if (DEBUG.ENABLED) console.log('canvas was reseted');
    }

    // refatorar usando outros reducers.
    const loadStateData = (data: CanvasState) => {
        if (data) {
            // Assegura que o elemento terá valores padrão definidos.
            const elements = data.elements.map((element: ElementData) => ({
                ...element,
                attributes: {
                    ...ComponentProvider.getInitialProps(element.attributes),
                    type: element.attributes.type
                }
            }));
            const groups = data.groups.map((group: GroupData) => ({
                ...group,
            }));

            const imageElements = state.elements.filter(el => el.attributes.type === 'image');
            if (imageElements.length === 0 && loadRefs) {
                setAllImagesLoaded(true);
            }

            stateDispatch({ type: "loadStateData", data, elements, groups });
            return true;
        }
        return false;
    }

    // Aplica algumas tratativas antes de salvar o estado.
    const stateToObject = (): ReducedCanvasState => {
        const parsedElements: ElementData[] = [];
        // Faz uma redução dos elementos.
        state.elements.forEach(data => parsedElements.push(elementMinify(data)));

        return {
            templateId: state.templateId,
            templateName: state.templateName,
            templateType: state.templateType,
            orientation: state.editor.orientation,
            elements: parsedElements,
            products: state.products.ids,
            groups: state.groups,
            priceType: state.priceType,
            comboType: state.comboType
        }
    }

    const saveOnLocalStorage = () => {
        if (localStorage !== undefined) {
            const parsedElements: ElementData[] = [];
            state.elements.forEach(data => {
                // Usa minify para pegar apenas dados relevantes.
                parsedElements.push(elementMinify(data));
            });

            const editorData = {
                ...state,
                current: initialSelection,
                elements: parsedElements,
                history: [initialMemories],
                editor: {
                    ...state.editor,
                    section: initialEditorState.section
                },
                stageRef: null
            };

            try {
                localStorage.setItem('generated-cartaz', JSON.stringify(editorData));
                console.log('saved on localStorage', editorData);
                Notify.Success('Layout salvo no local storage');
            } catch (e) {
                if (e instanceof DOMException && e.name === 'QuotaExceededError') {
                    Notify.Error("Quota limite do LocalStorage atingida!");
                } else {
                    // Tratamento de erro genérico, caso seja outro erro que não QuotaExceededError.
                    // @ts-ignore
                    Notify.Error(`Erro ao salvar no LocalStorage: ${e.message}`);
                }
            }
        }
        else if (localStorage === undefined) Notify.Error('No local storage available');
    }

    const saveJSON = () => {
        if (localStorage !== undefined && state.stageRef?.current) {
            const json = state.stageRef.current.toJSON();
            localStorage.setItem('json-layout', json);
            console.log('saved json on localStorage', json);
            Notify.Success('Layout exportado em JSON com sucesso!');
        }
        else if (localStorage === undefined) Notify.Error('No local storage available');
        else if (!state.stageRef?.current) Notify.Error('Invalid stage reference');
    }

    const loadJSON = () => {
        if (localStorage !== undefined && state.stageRef?.current) {
            const json = localStorage.getItem('json-layout');
            if (json && typeof json === 'string') {
                const layerData = JSON.parse(json);

                if (!layerData.children || !state.stageRef?.current.children) {
                    Notify.Error('No stage children found.');
                    return;
                }

                if (state.stageRef.current.children) {
                    // Clear the current layer
                    state.stageRef.current.children[0].destroyChildren();
                }

                // Reconstruct the stage from the saved JSON data
                const layer = layerData.children
                    .filter((child: any) => child.className === 'Layer');

                if (layer && layer.length > 0) {
                    const newLayer = Konva.Node.create(layer[0]);
                    state.stageRef.current.add(newLayer);
                }
                state.stageRef.current.draw();
            }
            else Notify.Warn("Inconsistent json data found");
        }
    }

    const canvasToImage = (ref: StageRef) => {
        if (ref && ref.current) {
            const stage = ref.current;
            const date = DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
            const fileName = `encarte-[${date}].png`;

            const uri = stage.toDataURL({ pixelRatio: 10 });
            const link = document.createElement('a');

            link.download = fileName;
            link.href = uri;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const getStageImageUri = (quality: number = 10): string | null => {
        if (state.stageRef && state.stageRef.current) {
            const stage = state.stageRef.current;
            return stage.toDataURL({ pixelRatio: quality });
        }
        return null;
    }

    const getStageImageBlob = (quality: number = 10): Promise<Blob | null> => {
        return new Promise((resolve, reject) => {
            if (state.stageRef && state.stageRef.current) {
                const stage = state.stageRef.current;
                const dataURL = stage.toDataURL({ pixelRatio: quality });
                fetch(dataURL)
                    .then(res => res.blob())
                    .then(blob => {
                        resolve(blob);
                    })
                    .catch(err => {
                        console.error(err);
                        reject(null);
                    });
            } else {
                resolve(null);
            }
        });
    };

    const removeStageZoom = () => {
        if (state.stageRef && state.stageRef.current) {
            state.stageRef.current.scale({ x: 1, y: 1 });
            state.stageRef.current.position({ x: 0, y: 0 });
            state.stageRef.current.batchDraw();
            stateDispatch({ type: "setIsZoomEnabled", isOn: false });
        }
    };

    // REF
    const previousElement = (elements: ElementData[]) => {
        const last = history.peekUndo();
        const element = elements.find(el => el.attributes.id === state.current.id);
        if (element) {
            history.undoPush(element);
        } else if (!last) {
            if (element) {
                history.undoPush(element);
            }
        }
    }

    const shortcutUndo = () => {
        const lastValue = history.peekUndo();
        if (lastValue) {
            const current = state.elements.find(el => el.attributes.id === lastValue.attributes.id);
            if (current) {
                const element = history.undo(current);
                if (element) stateDispatch({
                    type: "updateElement",
                    payload: {
                        id: element.attributes.id, attributes: element.attributes, isUndoFunction: true
                    }
                });
            } else {
                stateDispatch({ type: "addElement", attributes: lastValue.attributes });
                history.undo(lastValue);
            }
        }
    }

    const shortcutRedo = () => {
        const lastValue = history.peekRedo();
        if (lastValue) {
            const current = state.elements.find(el => el.attributes.id === lastValue.attributes.id);
            if (current) {
                const element = history.redo(current);
                if (element) {
                    stateDispatch({
                        type: "updateElement",
                        payload: {
                            id: element.attributes.id,
                            attributes: element.attributes,
                            isUndoFunction: true
                        }
                    });
                }
            }
        }
    }

    useEffect(() => {
        // Checar se todos os elementos têm uma ref
        const allReady = state.elements.every(element => {
            // Se for o elemento de background, ignore a verificação de ref
            return element.attributes.type === __BACKGROUND__ || element.ref != null;
        });

        if (allReady) {
            // Se todos os elementos tiverem uma ref, atualize o estado
            setLoadRefs(true);
        } else {
            // Se não, garanta que o estado está em false
            setLoadRefs(false);
        }
    }, [state.elements]);

    useEffect(() => {
        if (state.elements.length <= 1) return;

        const imageElements = state.elements.filter(el => el.attributes.type === 'image');
        if (imageElements.length === 0 && loadRefs) {
            setAllImagesLoaded(true);
            if (DEBUG.ENABLED) console.log('no image elements found');
        }
    }, [state.elements, imageLoadingState, loadRefs]);

    const shapeInfo: ShapeInfo = buildShapeInfo(state.current.id, state.current.type);

    return (
        <CanvasContext.Provider
            value={{
                state,
                shapeInfo,
                isEditing,
                reloadTemplate,
                shouldReloadTemplateData,
                setShouldReloadTemplateData,
                getSortedRenderData,
                getElementDataById,
                getGroupDataById,
                updateFontFamily,
                updateFontSize,
                updateTextAlign,
                isSpecialText,
                loadStateData,
                stateToObject,
                saveOnLocalStorage,
                canvasToImage,
                getStageImageUri,
                getStageImageBlob,
                updateTextType,
                updatePriceType,
                writeOnSelected,
                saveJSON,
                loadJSON,
                removeStageZoom,
                getElementsByGroupId,
                getNewGroupId,
                setNewGroupId,
                selectProduct,
                getElementsByProductId,
                updateProductTextElements,
                updateProductImageElements,
                isGeneratingImage,
                setIsGeneratingImage,
                isLoadingTemplateData,
                setIsLoadingTemplateData,
                loadRefs,
                setLoadRefs,
                allImagesLoaded,
                setAllImagesLoaded,
                imageLoadingState,
                setImageLoadingState,
                currentFontSize,
                setFontSize,
                setTemplateType,
                lineID,
                setLineID,
                isFuturePrice,
                setFuturePrice,
                shortcutUndo,
                shortcutRedo,
                previousElement,
                useCustomText,
                setUseCustomText
            }}
        >
            <CanvasDispatchContext.Provider value={{ stateDispatch }}>
                {children}
            </CanvasDispatchContext.Provider>
        </CanvasContext.Provider>
    );
};

function buildShapeInfo(selectedID: string | null, selectedType: ElementType | null): ShapeInfo {
    return {
        id: selectedID,
        exists: selectedID !== null,
        isBackground: selectedType === 'background',
        isCircle: selectedType === 'circle',
        isImage: selectedType === 'image',
        isRectangle: selectedType === 'rectangle',
        isText: selectedType === 'text',
        isGroup: !!((selectedID && !selectedType) && (selectedID.includes('group_selection')))
    }
}

function elementMinify(data: ElementData): ElementData {
    // Anula as referências.
    let parsedData = { ...data, ref: null };

    // Extrai o src em caso da imagem não ser string.
    if (data.attributes.type === 'image') {
        const source = data.attributes.image;
        const src = (typeof source === 'string' ? source
            : (source && typeof source === 'object' && 'src' in source) ? (source as HTMLImageElement).src
                : '/images/no-img.png') as string;
        parsedData = { ...parsedData, attributes: { ...parsedData.attributes, image: src } }
    }

    // Mantém o texto padrão caso o tipo de texto seja especial.
    if (isSpecialText(data.attributes.textType) && data.attributes.defaultText) {
        parsedData = { ...parsedData, attributes: { ...parsedData.attributes, text: data.attributes.defaultText } };
    }
    return parsedData;
}

export function isSpecialText(textType: TextType): boolean {
    return ([
        'datetime', 'market', 'date_and_market',
        'cpf_limit', 'expiration_date',
        'price_a_cada_full', 'price_a_cada_digits', 'price_a_cada_cents',
        'ap_x_UN__quantity', 'xOff_value', 'xUN_value', 'xOff_price_digits', 'xOff_price_cents',
        'leve_Por__quantity', 'leve_Por__price_digits', "leve_Por__price_cents",
        'oferta_pack__quantity', 'oferta_pack__price_digits', "oferta_pack__price_cents",
        'compre_e_ganhe__un', 'compre_e_ganhe__item',
        'leveX_pagueY__x', 'leveX_pagueY__y',
        'maisXX_value', 'maisXX_item',
        'compreX_maisX_leve__x', 'compreX_maisX_leve__value', 'compreX_maisX_leve__item',
        'installments__quantity', 'installments__price_digits', 'installments__price_cents', "text_club"
    ] as Array<TextType>).includes(textType);
}