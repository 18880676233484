import { useUserManager } from '@/contexts/admin/UserManagerContext';
import { useMember } from '@/contexts/member/MemberContext';
import { PlusIcon } from '@heroicons/react/solid';
import React from 'react';

const button_gradient_style = "flex justify-center items-center gap-2 shadow-md shadow-blue-500/50 bg-blue-500 text-white pl-6 pr-4 py-2 rounded-lg hover:bg-blue-600";

interface IAdminHeader {
	isOpenModal?: boolean
}

const AdminHeader: React.FC<IAdminHeader> = ({ isOpenModal = false }) => {
	const {
		currentTab,
		setMemberModalVisibility,
		setCompanyModalVisibility,
		setPermissionModalVisibility,
		setNotificationModalVisibility,
		setFontModalVisibility,
		setImageModalVisibility,
	} = useUserManager();

	const { member } = useMember();

	return (
		<div className="flex justify-end space-x-2 items-center rounded-xl">
			<div className='flex flex-row'>
				{
					(member.admin() || member.role === "manager") && !isOpenModal && currentTab === 'members' && (
						<button
							className={button_gradient_style}
							onClick={() => setMemberModalVisibility(true)}
						>
							Novo Usuário
							<PlusIcon className='w-4' />
						</button>
					)
				}
				{
					(member.admin() || member.role === "manager") && !isOpenModal && currentTab === 'companies' && (
						<button
							className={button_gradient_style}
							onClick={() => setCompanyModalVisibility(true)}
						>
							Nova Empresa
							<PlusIcon className='w-4' />
						</button>
					)
				}
				{
					(member.admin() || member.role === "manager") && !isOpenModal && currentTab === 'permissions' && (
						<button
							className={button_gradient_style}
							onClick={() => setPermissionModalVisibility(true)}
						>
							Criar Permissão
							<PlusIcon className='w-4' />
						</button>
					)
				}
				{
					(member.admin() || member.role === "manager") && !isOpenModal && currentTab === 'notifications' && (
						<button
							className={button_gradient_style}
							onClick={() => setNotificationModalVisibility(true)}
						>
							Nova Notificação
							<PlusIcon className='w-4' />
						</button>
					)
				}
				{
					(member.admin() || member.role === "manager") && !isOpenModal && member.can('upload_fonts') && currentTab === 'fonts' && (
						<button
							className={button_gradient_style}
							onClick={() => setFontModalVisibility(true)}
						>
							Enviar Fonte
							<PlusIcon className='w-4' />
						</button>
					)
				}
				{
					(member.admin() || member.role === "manager") && !isOpenModal && currentTab === 'marketing-images' && (
						<button
							className={button_gradient_style}
							onClick={() => setImageModalVisibility(true)}
						>
							Enviar Imagem
							<PlusIcon className='w-4' />
						</button>
					)
				}
			</div>
		</div>
	)
}

export default AdminHeader;
