import React, { useEffect, useState } from "react";
import StepContainer from "@/components/partials/onboarding/steps/StepContainer";
import { useMember } from "@/contexts/member/MemberContext";
import { Transition } from "@headlessui/react";

interface ViewProps {
	view?: () => void;	
	style?: string;
}

const SecurityStep: React.FC<ViewProps> = ({ view, style }) => {
	const { member } = useMember();
	const [showChildren, setShowChildren] = useState(false);

	useEffect(() => {
		setShowChildren(true)
	}, [view])

	const PermissionsContainer: React.FC = React.memo(() => {
		return (
			<Transition
				show={showChildren}
				enter="transition-opacity duration-1000"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity duration-1000"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
				className={"overflow-auto px-2 grid gap-4 grid-cols-1 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2"}
			>
					<PermissionItem
						label="Criar Cartazes"
						description="Você tem autorização para criar modelos de cartaz para sua empresa."
						defaultValue={member.can('create_banner')} />
					<PermissionItem
						label="Enviar Cartaz à Fila"
						description="Você pode enviar um cartaz para a Fila de Impressão."
						defaultValue={member.can('send_to_queue')} />
					<PermissionItem
						label="Imprimir Fila de Impressão"
						description="Você pode gerar imagens e PDFs a partir da sua Fila de Impressão."
						defaultValue={member.can('print_queue')} />
					<PermissionItem
						label="Enviar à Outras Lojas"
						description="Você pode enviar um cartaz para a Fila de Impressão de qualquer loja pertencente a sua empresa."
						defaultValue={member.can('send_to_any_queue')} />
					<PermissionItem
						label="Editar Texto nos Cartazes"
						description="Você pode editar informações como Descrição e Preço antes de enviar o cartaz para a Fila de Impressão."
						defaultValue={member.can('edit_banner_texts')} />
					<PermissionItem
						label="Fazer Upload de Fontes"
						description="Você pode fazer o upload de fontes customizadas no sistema."
						defaultValue={member.can('upload_fonts')} />
					<PermissionItem
						label="Fazer Upload de Imagens"
						description="Você pode fazer o upload de imagens para usar em seus cartazes."
						defaultValue={member.can('upload_images')} />
					<PermissionItem
						label="Gerenciar Produtos"
						description="Você pode gerenciar os produtos locais da sua empresa."
						defaultValue={member.can('manage_products')} />
					<PermissionItem
						label="Gerenciar Empresa"
						description="Você pode gerenciar membros e informações relacionadas a sua empresa no sistema."
						defaultValue={member.can('manage_company')} />
					<PermissionItem
						label="Gerenciar Ilha de Impressão"
						description="Você pode gerenciar a Ilha de Impressão e imprimir cartazes de todas as lojas da sua empresa."
						defaultValue={member.can('manage_island')} />
					{
						member.admin() && (
							<PermissionItem
								label="Gerenciar Conteúdo Público"
								description="Você pode gerenciar recursos que ficarão expostos ao público, como imagens, layouts entre outros."
								defaultValue={member.can('manage_public_assets')} />
						)
					}
					<PermissionItem
						label="Gerar Vídeos A Partir da Fila"
						description="Você pode criar vídeos a partir dos cartazes adicionados na fila de impressão."
						defaultValue={member.can('generate_video')} />
			</Transition>
		)
	});
	
	return (
		<Transition
		show={showChildren}
		enter="transition-opacity duration-1000"
		enterFrom="opacity-0"
		enterTo="opacity-100"
		leave="transition-opacity duration-1000"
		leaveFrom="opacity-100"
		leaveTo="opacity-0">
			<StepContainer styles={style}>
				<p className="my-2 font-bold">Suas permissões de acesso estão listados em verde</p>
				<PermissionsContainer />
			</StepContainer>
		</Transition>
	)
}

export default SecurityStep;

const PermissionItem: React.FC<{label: string, description: string, defaultValue: boolean}> = ({ label, description, defaultValue }) => {

    return (
		<div className={`w-full p-5 border-2 rounded-md cursor-pointer transition-all duration-300 ease-in-out 
			${defaultValue ? 'border-green-500 bg-green-50' : 'border-gray-300 bg-gray-50'} 
			hover:shadow-lg hover:border-transparent`}
		>	
			<h3 className={`text-lg font-semibold mb-2 transition-colors duration-300 ease-in-out ${!defaultValue ? 'text-gray-400' : 'text-green-700'} hover:text-gray-900`}>{label}</h3>
			<p className={`text-sm transition-colors duration-300 ease-in-out ${defaultValue ? 'text-gray-500' : 'text-gray-400'} hover:text-gray-600`}>{description}</p>
		</div>
	)	
}
