import { useEffect, useRef } from 'react';
import { useAppSelector } from '@/store/hooks';

export const usePopoverAutoClose = () => {
	const popoverRef = useRef<HTMLButtonElement | null>(null);
	const hiddenInputRef = useRef(null);
	const isSidebarOpen = useAppSelector((state) => state.modals.openPreferencesModal);

	useEffect(() => { // Fecha o popover caso a sidebar seja aberta.
		if (hiddenInputRef.current && popoverRef.current && isSidebarOpen)
		{
			const input = hiddenInputRef.current as HTMLInputElement;
			const popover = popoverRef.current as HTMLButtonElement;
			if (input.value === 'true')
			{
				popover.click();
			}
		}
	}, [isSidebarOpen]);

	const toggle = () => {
		if (!popoverRef.current) return;
		const popover = popoverRef.current as HTMLButtonElement;
		popover.click();
	}

    return {
        popoverRef,
        hiddenInputRef,
        isSidebarOpen,
		toggle
    }
};
