import React from 'react';


interface LoadingInterface {
    width?: string
    height?: string
}

const Loading: React.FC<LoadingInterface> = ({ width, height }) => {
    return (
        <div className={`
        ${width ? width : "w-10"} 
        ${height ? height : "h-10"} rounded-full border-4 border-zinc-102 border-t-4 border-t-blue-500 animate-spin`} />
    )
}

export default Loading;
