import { createSlice } from "@reduxjs/toolkit";

type ModalsState = {
	openPreferencesModal: boolean;
};

const initialState: ModalsState = {
	openPreferencesModal: false
};

type AddElementPayload = { open: boolean };

const modalsSlice = createSlice({
	name: "modals",
	initialState: initialState,
	reducers: {
		togglePreferences(state, action: { payload: AddElementPayload }) {
			state.openPreferencesModal = action.payload.open;
		}
	},
});

export const modalsActions = modalsSlice.actions;
export default modalsSlice.reducer;
