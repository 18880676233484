import { createContext, useContext, useState, useRef, RefObject } from "react";
import { ParentInterface } from '@/types';
import { BannerProducts, ProductBulkListItem, ProductListItem, SilverProduct } from "@/types/products";
import ProductHelper from "@/helpers/ProductHelper";
import ArrayHelper from "@/helpers/ArrayHelper";
import { isEqual } from 'lodash';
import _ from "lodash";
import { EntityID } from "@/types/silverstripe";

interface CanvasProductsContextInterface {
	products: ProductListItem[],
	setProducts: (products: ProductListItem[]) => void,

	bulkProducts: ProductBulkListItem[],
	setBulkProducts: (products: ProductBulkListItem[]) => void,

	bannerProducts: RefObject<BannerProducts[]>,
	setBannerProducts: (products: BannerProducts[]) => void,

	showProductListModal: boolean,
	setShowProductListModal: (value: boolean) => void,

	addProduct: (product: ProductListItem) => void,
	addSilverProduct: (product: SilverProduct) => void,

	getBy: (key: keyof ProductListItem, value: any) => ProductListItem | null,

	removeBy: (key: keyof ProductListItem, value: any) => void,
	deleteProduct: (productToDelete: ProductListItem) => void,

	indexedProducts: Record<string, EntityID>,
	setIndexedProducts: (value: Record<string, EntityID>) => void,
	removeProductFromIndex: (productId: EntityID) => void,

	resetContext: () => void,
}

const CanvasProductsContext = createContext<CanvasProductsContextInterface>(
	{} as CanvasProductsContextInterface
);

export const CanvasProductsProvider: React.FC<ParentInterface> = ({ children }) => {
	const [products, setProducts] = useState<ProductListItem[]>([]);
	const [bulkProducts, setBulkProducts] = useState<ProductBulkListItem[]>([]);
	const [showProductListModal, setShowProductListModal] = useState<boolean>(false);
	const [indexedProducts, setIndexedProducts] = useState<Record<string, EntityID>>({});

	const bannerProducts = useRef<BannerProducts[]>([]);
	const setBannerProducts = (products: Array<BannerProducts>) => {
		if (bannerProducts.current) bannerProducts.current = products;
	}

	const removeProductFromIndex = (productId: EntityID) => {
		const newIndexedProducts = { ...indexedProducts };
		for (const key in newIndexedProducts) {
			if (newIndexedProducts[key] === productId) {
				delete newIndexedProducts[key];
			}
		}
		setIndexedProducts(newIndexedProducts);
	};

	function productAlreadyIncluded(product: ProductListItem) {
		return _.some(products, product);
	}

	const addProduct = (product: ProductListItem) => {
		if (!product || productAlreadyIncluded(product)) return;

		setProducts((prevState) => ([
			...prevState,
			product
		]));
	}

	const addSilverProduct = (product: SilverProduct) => {
		if (!product) return;
		const parsedProduct = ProductHelper.parseProduct(product);

		if (productAlreadyIncluded(parsedProduct)) return;

		addProduct(parsedProduct);
	}

	const getBy = (key: keyof ProductListItem, value: any): ProductListItem | null => {
		return ArrayHelper.findByPropertyValue(products, key, value);
	}

	const removeBy = (key: keyof ProductListItem, value: any) => {
		const list = ArrayHelper.removeByPropertyValue(products, key, value);
		setProducts(list);
	}

	const deleteProduct = (productToDelete: ProductListItem) => {
		setProducts((prevState) =>
			prevState.filter((product) => !isEqual(product, productToDelete))
		);
	};

	const resetContext = () => {
		setProducts([]);
		setBannerProducts([]);
	}

	return (
		<CanvasProductsContext.Provider
			value={{
				products,
				setProducts,

				bulkProducts,
				setBulkProducts,
				
				bannerProducts,
				setBannerProducts,

				showProductListModal,
				setShowProductListModal,

				addProduct,
				addSilverProduct,

				getBy,

				removeBy,
				deleteProduct,

				resetContext,

				indexedProducts,
				setIndexedProducts,
				removeProductFromIndex
			}}
		>
			{children}
		</CanvasProductsContext.Provider>
	);
};

export const useCanvasProducts = (): CanvasProductsContextInterface => {
	const context = useContext(CanvasProductsContext);

	if (!context) {
		throw new Error(
			"useCanvasProducts must be used within an CanvasProductsContext"
		);
	}

	return context;
};
