import React, { useEffect, useRef, useState } from 'react';
import { BellIcon } from "@heroicons/react/solid";
import { useNotifications } from '@/contexts/notification/NotificationContext';
import Notification from '@/components/notifications/Notification';
import Icon from '@/components/notifications/Icon';

const NotificationCenter: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);
	const isOpenRef = useRef(isOpen);

	const { notifications, refreshNotifications } = useNotifications();

	const handleClick = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		isOpenRef.current = isOpen;
	}, [isOpen]);

	useEffect(() => {
		refreshNotifications();
		const intervalID = setInterval(refreshNotifications, 30000);
		return () => clearInterval(intervalID);
	}, []);

	return (
		<div className="relative inline-block text-left">
			<div>
				<button type="button" onClick={handleClick} className="focus:outline-none">
					<div className="relative">
						<span className={`absolute -top-2 -right-2 w-5 h-5 ${notifications.length > 0 ? 'bg-red-500' : 'bg-blue-500'} text-white rounded-full text-xs text-center flex items-center justify-center`}>
							{notifications.length}
						</span>
						<Icon icon={BellIcon} className="w-6 mt-1 text-white" />
					</div>
				</button>
			</div>

			{isOpen && (
				<div className="origin-top-right absolute right-0 mt-2 w-[400px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
					<div className="p-4 space-y-4 max-h-[85vh] overflow-auto" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
						{
							notifications.map((notification, index) => (
								<Notification key={index}
									id={notification.id}
									type={notification.type}
									userName={notification.userName}
									actionLabel='enviou uma mensagem'
									relativeTime={notification.date}
									title={notification.title}
									message={notification.message}
									read={notification.read}
								/>
							))
						}
						{
							notifications.length === 0 && (
								<div className='flex justify-center items-center p-4'>
									<p className='text-gray-400'>Nenhuma notificação</p>
								</div>
							)
						}
					</div>
				</div>
			)}
		</div>
	);
};

export default NotificationCenter;
