import React from "react";
import Image from 'next/image';
import Link from "next/link";
import { useMember } from "@/contexts/member/MemberContext";
import UserPopover from "./popover/UserPopover";
import NotificationCenter from "./buttons/NotificationCenter";
import AnimatedUnderscore from "./partials/common/AnimatedUnderscore";
import { useCanvasProducts } from "@/contexts/editor/CanvasProductsContext";
import E_AISidebar from "./sidebars/e_ai/E_AISidebar";
import { __BG_ANIMATED_BLUE_GRADIENT__ } from '@/types/constants';
import useScreenDimensions from "@/hooks/useScreenDimensions";
import MobileMenu from "./MobileMenu";
import DEBUG from '@/helpers/DEBUG';


const Header = () => {
    const { isSmallScreen } = useScreenDimensions();
    const { member, setIsLoginModalVisible } = useMember();
    const { showProductListModal, setShowProductListModal } = useCanvasProducts();

    return (
        <header className={`fixed top-0 left-0 right-0 shadow-md py-4 px-8 flex justify-between z-10 ${__BG_ANIMATED_BLUE_GRADIENT__}`}>
            <E_AISidebar isOpen={showProductListModal} toggleSidebar={() => setShowProductListModal(!showProductListModal)} />
            {
                isSmallScreen ? <MobileMenu /> : (
                    <Link href="/" className="h-full flex justify-center items-center mt-3" >
                        <Image
                            src="/images/brand/SalestagWhiteSvg.svg"
                            alt="Salestag Logo"
                            width={90}
                            height={24}
                            priority
                            className="hover:scale-125 duration-500"
                        />
                    </Link>
                )
            }
            <nav className="flex items-center space-x-6 text-gray-400 h-18 max-h-18">
                {
                    member.isLogged() ? (
                        <div className="flex flex-row justify-center items-center space-x-4" >
                            <OptionIA />
                            <NotificationCenter />
                            <UserPopover />
                        </div>
                    ) : (
                        <Link href={''}>
                            <span
                                onClick={() => setIsLoginModalVisible(true)}
                                className="relative p-2 font-semibold cursor-pointer transition-all duration-300 group"
                            >
                                <AnimatedUnderscore>
                                    Fazer Login
                                </AnimatedUnderscore>
                            </span>
                        </Link>
                    )
                }
            </nav>
        </header>
    );
}

const OptionIA = () => {
    if (DEBUG.ENABLED) {
        return (
            <div className="cursor-pointer">
                <svg fill="white" xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512"><path d="M320 0c17.7 0 32 14.3 32 32V96H472c39.8 0 72 32.2 72 72V440c0 39.8-32.2 72-72 72H168c-39.8 0-72-32.2-72-72V168c0-39.8 32.2-72 72-72H288V32c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H208zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H304zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H400zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224H64V416H48c-26.5 0-48-21.5-48-48V272c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H576V224h16z" /></svg>
            </div>
        )
    }

    return <></>;
}

export default Header;
