import React from 'react';
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import emptyBox from "@public/animations/emptyBox.json";
import emptyYellowBox from "@public/animations/empty-yellow-box.json";
import magGlass from "@public/animations/sad-mag-glass.json";

type AnimationType = "default" | "yellow-box" | "mag-glass";

interface EmptyBoxInterface {
	height?: number;
	animation?: AnimationType;
	loop?: boolean;
}

const EmptyBox: React.FC<EmptyBoxInterface> = ({ height, animation = 'default', loop = true }) => {
	const animations: Record<AnimationType, any> = {
		'default': emptyBox,
		'yellow-box': emptyYellowBox,
		'mag-glass': magGlass
	};
	return (
		<Lottie
			loop={loop}
			play
			style={{ height }}
			animationData={animations[animation]}
		/>
	)
}

EmptyBox.defaultProps = {
	height: 200
}

export default EmptyBox;
