import { ViewController } from "@/components/partials/onboarding/brand";

const CompanyResources: React.FC<ViewController> = ({ setView }) => {
	return (
		<>
			<h2 className="text-2xl font-semibold mb-8">Kit da Marca</h2>
			<div className="grid grid-cols-1 gap-4 md:grid-cols-3">
				{/* Detalhes da Empresa */}
				<div onClick={() => setView("logos_container")} className="flex flex-col items-center bg-white p-6 rounded-xl shadow-md border-2 border-orange-500 hover:scale-102 duration-200 cursor-pointer">
					<p className="text-gray-500">Opção da Gerência</p>
					<h3 className="text-xl font-semibold mb-2">Logos</h3>
				</div>
				<div onClick={() => setView("slogan_container")} className="flex flex-col items-center bg-white p-6 rounded-xl shadow-md border-2 border-orange-500 hover:scale-102 duration-200 cursor-pointer">
					<p className="text-gray-500">Opção da Gerência</p>
					<h3 className="text-xl font-semibold mb-2">Slogan</h3>
				</div>
				<div onClick={() => setView("company_container")} className="cursor-pointer flex flex-col items-center justify-center bg-white p-6 rounded-xl hover:scale-102 duration-200 shadow">
					<p className="text-gray-500">Página Anterior</p>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						className="h-8 w-8"
						>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M15 19l-7-7 7-7"
						/>
					</svg>			
				</div>
			</div>
		</>
	)
}

export default CompanyResources;
