import React from 'react';

interface TextRowInterface {
	label: string;
	value: string;
	labelClassName?: string;
	valueClassName?: string;
	containerClassName?: string;
}

const TextRow: React.FC<TextRowInterface> = ({
	label,
	value,
	labelClassName,
	valueClassName,
	containerClassName
}) => {
	return (
		<div className={`w-full flex justify-between ${containerClassName}`}>
			<span className={`text-left ${labelClassName}`}>{label}</span>
			<span className={`text-right ${valueClassName}`}>{value}</span>
		</div>
	)
}

export default TextRow;
