import { ReactChildren } from "@/types";


interface RowInterface {
	children: ReactChildren,
	className?: string
}

const Row: React.FC<RowInterface> = ({ children, className }) => {
	return (
		<div className={`flex flex-row w-full justify-center items-center ${className}`}>
			{ children }
		</div>
	)
}

Row.defaultProps = {
	className: 'mt-4'
}

export default Row;
