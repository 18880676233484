import React from 'react';
import { ParentInterface } from '@/types';

interface AnimatedUnderscoreProps extends ParentInterface {
	className?: string,
	textColor?: string,
    lineColor?: string,
}

const AnimatedUnderscore: React.FC<AnimatedUnderscoreProps> = ({ children, className = '', textColor = 'text-white', lineColor = 'bg-white' }) => {
    const baseClass = "relative inline-block py-2 px-4 font-semibold transition-all duration-300 group";
    const addedClass = className;

    return (
        <span className={`${baseClass} ${textColor} ${addedClass}`}>
            <span className="relative">{children}</span>
            <span className={`${lineColor} absolute inset-x-0 bottom-0 h-1 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 rounded`}></span>
        </span>
    );
};

export default AnimatedUnderscore;
