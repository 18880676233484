import React, { useState } from 'react';
import BoxIcon from '@/components/svg/BoxIcon';
import LightningIcon from '@/components/svg/LightningIcon';
import TiersIcon from '@/components/svg/TiersIcon';
import LayoutIcon from '@/components/svg/LayoutIcon';
import HomeIcon from '@/components/svg/HomeIcon';
import CampaignIcon from '@/components/svg/CampaignIcon';
import { useMember } from '@/contexts/member/MemberContext';
import { useModels } from '@/contexts/models/ModelsContext';
import { useQueue } from '@/contexts/queue/QueueContext';
import TextHelper from '@/helpers/TextHelper';
import Link from 'next/link';

interface MenuLinkInterface {
	href: string;
	icon: React.ComponentType;
	title: string;
	count?: number;
	onClick?: () => void;
}

const MobileMenu: React.FC = () => {
	const { member } = useMember();
	const { queueCount } = useQueue();
	const { countTotalTemplates } = useModels();

	const [isOpen, setIsOpen] = useState(false);

	const closeMenu = () => setIsOpen(false);

	return (
		<div>
			<button
				className="text-3xl text-white"
				onClick={() => setIsOpen(!isOpen)}
			>
				&#9776;
			</button>
			{isOpen && (
				<div className="fixed top-0 left-0 w-screen h-screen bg-white flex flex-col items-center justify-center z-50">
					<button
						className="absolute top-4 left-8 text-4xl"
						onClick={() => setIsOpen(false)}
					>
						&times; {/* Código HTML para o ícone de fechar */}
					</button>
					
					<MenuLink href='/' icon={HomeIcon} title={'Home'} onClick={closeMenu} />
					<MenuLink href='/campaigns' icon={CampaignIcon} title={'Campanhas'} onClick={closeMenu} />
					<MenuLink href='/models' icon={LayoutIcon} title={'Modelos'} count={countTotalTemplates} onClick={closeMenu} />
					<MenuLink href='/fila' icon={TiersIcon} title={'Fila'} count={queueCount} onClick={closeMenu} />
					<MenuLink href='/products' icon={BoxIcon} title={'Produtos'} onClick={closeMenu} />
					{ member.admin() && <MenuLink href='/admin' icon={LightningIcon} title={'Administração'} onClick={closeMenu} /> }
				</div>
			)}
		</div>
	);
};

export default MobileMenu;

const MenuLink: React.FC<MenuLinkInterface> = ({
	href,
	icon: Icon,
	title,
	count,
	onClick = () => null
}) => {
	return (
		<Link href={href} onClick={onClick} className="flex flex-row space-x-4 justify-center items-center text-gray-700 text-xl py-2 px-4 hover:underline">
			<span className='text-gray-400'><Icon /></span> &nbsp; {title}
			{
				count ? (
					<span className="flex items-center justify-center text-sm text-gray-500 font-semibold bg-gray-200 h-6 px-2 rounded-full">
						{ TextHelper.padNumber(count) }
					</span>
				) : <></>
			}
		</Link>
	)
}
