const crypto = require ? require('crypto') : null;

class CryptoHelper {
    // Inicializa as configurações de criptografia
    constructor(__SECURITY_ENCRYPTION_KEY__) {
        // Verifica se uma chave de segurança foi fornecida
        if (!__SECURITY_ENCRYPTION_KEY__) throw new Error("Nenhuma chave de segurança encontrada.");
        // Verifica se a biblioteca 'crypto' está disponível
        if (!crypto) throw new Error("Nenhuma biblioteca de criptografia disponível.");
        
        // Define o algoritmo de criptografia como 'aes-256-cbc'
        this.algorithm = 'aes-256-cbc';
        // Cria uma chave de criptografia de 256 bits usando SHA-256
        this.key = crypto.createHash('sha256').update(__SECURITY_ENCRYPTION_KEY__).digest();
    }

	encrypt(text) {
        // Gera um Vetor de Inicialização (IV) de 128 bits
		const iv = crypto.randomBytes(16);
        // Cria um objeto de cifra para criptografia
		const cipher = crypto.createCipheriv(this.algorithm, this.key, iv);
		let encrypted = cipher.update(text);
		encrypted = Buffer.concat([encrypted, cipher.final()]);
		return iv.toString('hex') + ':' + encrypted.toString('hex');
	}

	decrypt(encrypted) {
		const parts = encrypted.split(':');
		if (parts.length !== 2)
		{
			throw new Error("Mensagem criptografada inválida");
		}

		// IV recebido
		const iv = Buffer.from(parts[0], 'hex');
		// Conteúdo criptografado
		const cipherText = Buffer.from(parts[1], 'hex');
        // Descriptografa, usando a mesma chave e IV
		const decipher = crypto.createDecipheriv(this.algorithm, this.key, iv);
		let decrypted = decipher.update(cipherText);
		decrypted = Buffer.concat([decrypted, decipher.final()]);
		return decrypted.toString();
	}
}

// Suporte a CommonJS e ES Modules
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
	module.exports = CryptoHelper;
}
else if (typeof window !== "undefined") {
	window.CryptoHelper = CryptoHelper;
}
else {
	throw new Error("Unsupported platform type for CryptoHelper module detected.");
}
