import { Orientation, TextType } from "@/types/canvas";
import { __BANNER_TYPES__, __BANNER_TYPE__ } from "@/types/constants";
import { ProductListItem } from "@/types/products";

function getOrientationLabel(orientation: Orientation): string {
	switch (orientation) {
		case 'p30': return 'p30';
		case 'p21': return 'P21';
		case 'horizontal': return 'Horizontal';
		case 'vertical': return 'Vertical';
		case "story": return 'Story';
		case "post": return 'Post';
		default: throw new Error("Invalid orientation received: " + orientation);
	}
}

function capitalizeFirstLetter(value: string): string {
	return value.charAt(0).toUpperCase() + value.slice(1);
}

function firstWord(value: string): string {
	if (!value) return '';
	return value.split(' ')[0];
}

function charLimit(text: string, limit: number): string {
	// Retorna como está caso seja menor que o máximo.
	if (text.length <= limit) return text;

	const limited = text.slice(0, limit) + '...';
	return limited;
}

function getProductIndex() {
	return Math.floor(Math.random() * productSamples.length);
}

function formatPrice(sPriceString: string) {
	// Remove separadores de agrupamento (ponto ou vírgula) e encontra o último separador decimal
	const cleanedPrice = sPriceString.replace(/[,.](?=\d{3})/g, '');
	const decimalSeparatorIndex = cleanedPrice.search(/[,.]/);

	// Extrai os dígitos e centavos com base no último separador decimal encontrado
	const digits = decimalSeparatorIndex !== -1 ? cleanedPrice.slice(0, decimalSeparatorIndex) : cleanedPrice;
	let cents = decimalSeparatorIndex !== -1 ? "," + cleanedPrice.slice(decimalSeparatorIndex + 1) : "";

	// Adiciona um '0' se os centavos forem apenas um dígito
	if (cents.length === 2) {
		cents += '0';
	}

	return [digits, cents];
}

function padNumber(input: number | string): string {
	const num = typeof input === "string" ? parseInt(input) : input;

	if (isNaN(num)) {
		throw new Error("A entrada não pode ser convertida para um número.");
	}

	return num < 10 ? '0' + num : '' + num;
}

function getTextByType(type: TextType): string {
	switch (type) {
		case 'description':
			return 'Descrição do Produto';
		case 'price':
			return '00,00';
		case 'digits':
			return '00';
		case 'cents':
			return ',00';
		case 'cpf_limit':
			return 'Limitado a [[x]] por CPF'
		case 'date_limit':
			return 'Oferta Válida de [[X]]'
		default:
			return 'Novo Texto';
	}
}

const productSamples: ProductListItem[] = [
	// Preço com um dígito.
	{ description: "Creme Dental 90g", marketID: 1, sPrice: "2,99", cPrice: "2,69", oPrice: "2,39", ePrice: "2,24", id: "1", ean: null, innerCode: null, preview: '/images/samples/products/Creme-Dental-Colgate-90g-Tripla-Acao-Drogaria-SP-27537-2-removebg-preview.png', packType: 'UN', packQuantity: 1, extraParameter_1: null, extraParameter_2: null, extraParameter_3: null, extraParameter_4: null, extraParameter_5: null, extraParameter_6: null, extraParameter_7: null, extraParameter_8: null, extraParameter_9: null, extraParameter_10: null, initDate: new Date(), endDate: new Date() },
	{ description: "Molho de Tomate Tradicional 340g", marketID: 1, sPrice: "2,49", cPrice: "", oPrice: "", ePrice: "", id: "4", ean: null, innerCode: null, preview: '/images/samples/products/molho-de-tomate-tradicional-fugini-sache-340g-b09-removebg-preview.png', packType: 'UN', packQuantity: 1, extraParameter_1: null, extraParameter_2: null, extraParameter_3: null, extraParameter_4: null, extraParameter_5: null, extraParameter_6: null, extraParameter_7: null, extraParameter_8: null, extraParameter_9: null, extraParameter_10: null, initDate: new Date(), endDate: new Date() },
	{ description: "Bolacha Recheada 130g", marketID: 1, sPrice: "2,89", cPrice: "2,60", oPrice: "2,31", ePrice: "2,17", id: "8", ean: null, innerCode: null, preview: '/images/samples/products/passatempo.png', packType: 'UN', packQuantity: 1, extraParameter_1: null, extraParameter_2: null, extraParameter_3: null, extraParameter_4: null, extraParameter_5: null, extraParameter_6: null, extraParameter_7: null, extraParameter_8: null, extraParameter_9: null, extraParameter_10: null, initDate: new Date(), endDate: new Date() },
	{ description: "Macarrão Espaguete 500g", marketID: 1, sPrice: "3,99", cPrice: "", oPrice: "", ePrice: "", id: "10", ean: null, innerCode: null, preview: '/images/samples/products/macarrao.png', packType: 'UN', packQuantity: 1, extraParameter_1: null, extraParameter_2: null, extraParameter_3: null, extraParameter_4: null, extraParameter_5: null, extraParameter_6: null, extraParameter_7: null, extraParameter_8: null, extraParameter_9: null, extraParameter_10: null, initDate: new Date(), endDate: new Date() },
	// Preço com dois dígitos.
	{ description: "Arroz Branco Tipo 1 5kg", marketID: 1, sPrice: "21,50", cPrice: "19,35", oPrice: "17,20", ePrice: "16,13", id: "2", ean: null, innerCode: null, preview: '/images/samples/products/arroz-tiojao.png', packType: 'KG', packQuantity: 5, extraParameter_1: null, extraParameter_2: null, extraParameter_3: null, extraParameter_4: null, extraParameter_5: null, extraParameter_6: null, extraParameter_7: null, extraParameter_8: null, extraParameter_9: null, extraParameter_10: null, initDate: new Date(), endDate: new Date() },
	{ description: "Café em Pó Tradicional 500g", marketID: 1, sPrice: "12,99", cPrice: "11,69", oPrice: "10,39", ePrice: "9,74", id: "3", ean: null, innerCode: null, preview: '/images/samples/products/cafe.png', packType: 'KG', packQuantity: 1, extraParameter_1: null, extraParameter_2: null, extraParameter_3: null, extraParameter_4: null, extraParameter_5: null, extraParameter_6: null, extraParameter_7: null, extraParameter_8: null, extraParameter_9: null, extraParameter_10: null, initDate: new Date(), endDate: new Date() },
	{ description: "Papel Higiênico Folha Dupla 12 rolos", marketID: 1, sPrice: "13,90", cPrice: "12,51", oPrice: "", ePrice: "10,43", id: "4", ean: null, innerCode: null, preview: '/images/samples/products/papel.png', packType: 'CX', packQuantity: 1, extraParameter_1: null, extraParameter_2: null, extraParameter_3: null, extraParameter_4: null, extraParameter_5: null, extraParameter_6: null, extraParameter_7: null, extraParameter_8: null, extraParameter_9: null, extraParameter_10: null, initDate: new Date(), endDate: new Date() },
	{ description: "Shampoo Hidratante 400ml", sPrice: "14,99", marketID: 1, cPrice: "13,49", oPrice: "11,99", ePrice: "11,24", id: "5", ean: null, innerCode: null, preview: '/images/samples/products/Shampoo Hidratante 400ml.png', packType: 'UN', packQuantity: 1, extraParameter_1: null, extraParameter_2: null, extraParameter_3: null, extraParameter_4: null, extraParameter_5: null, extraParameter_6: null, extraParameter_7: null, extraParameter_8: null, extraParameter_9: null, extraParameter_10: null, initDate: new Date(), endDate: new Date() },
	// Preço com três dígitos.
	{ description: "Panela de Pressão Elétrica 6L", sPrice: "299,90", marketID: 1, cPrice: "269,91", oPrice: "239,92", ePrice: "224,93", id: "6", ean: null, innerCode: null, preview: '/images/samples/products/Panela de Pressão Elétrica 6L.png', packType: 'UN', packQuantity: 1, extraParameter_1: null, extraParameter_2: null, extraParameter_3: null, extraParameter_4: null, extraParameter_5: null, extraParameter_6: null, extraParameter_7: null, extraParameter_8: null, extraParameter_9: null, extraParameter_10: null, initDate: new Date(), endDate: new Date() },
	{ description: "Conjunto de Panelas Inox 5 peças", sPrice: "299,99", marketID: 1, cPrice: "269,99", oPrice: "239,99", ePrice: "224,99", id: "7", ean: null, innerCode: null, preview: '/images/samples/products/Conjunto de Panelas Inox 5 peças.png', packType: 'FD', packQuantity: 5, extraParameter_1: null, extraParameter_2: null, extraParameter_3: null, extraParameter_4: null, extraParameter_5: null, extraParameter_6: null, extraParameter_7: null, extraParameter_8: null, extraParameter_9: null, extraParameter_10: null, initDate: new Date(), endDate: new Date() },
	{ description: "Liquidificador Alta Potência 1000W", sPrice: "259,90", marketID: 1, cPrice: "233,91", oPrice: "207,92", ePrice: "194,93", id: "8", ean: null, innerCode: null, preview: '/images/samples/products/Liquidificador Alta Potência 1000W.png', packType: 'UN', packQuantity: 1, extraParameter_1: null, extraParameter_2: null, extraParameter_3: null, extraParameter_4: null, extraParameter_5: null, extraParameter_6: null, extraParameter_7: null, extraParameter_8: null, extraParameter_9: null, extraParameter_10: null, initDate: new Date(), endDate: new Date() },
	{ description: "Air Fryer 4L", sPrice: "379,99", marketID: 1, cPrice: "341,99", oPrice: "303,99", ePrice: "", id: "9", ean: null, innerCode: null, preview: '/images/samples/products/Air Fryer 4L.png', packType: 'LT', packQuantity: 4, extraParameter_1: null, extraParameter_2: null, extraParameter_3: null, extraParameter_4: null, extraParameter_5: null, extraParameter_6: null, extraParameter_7: null, extraParameter_8: null, extraParameter_9: null, extraParameter_10: null, initDate: new Date(), endDate: new Date() },
	// Preço com quatro dígitos.
	{ description: "Notebook Gamer 16GB RAM, SSD 1TB", marketID: 1, sPrice: "8.999,00", cPrice: "8.099,10", oPrice: "7.199,20", ePrice: "6.749,25", id: "10", ean: null, innerCode: null, preview: '/images/samples/products/Notebook Gamer 16GB RAM, SSD 1TB.png', packType: 'UN', packQuantity: 1, extraParameter_1: null, extraParameter_2: null, extraParameter_3: null, extraParameter_4: null, extraParameter_5: null, extraParameter_6: null, extraParameter_7: null, extraParameter_8: null, extraParameter_9: null, extraParameter_10: null, initDate: new Date(), endDate: new Date() },
	{ description: "TV LED 65' 4K Ultra HD Smart", marketID: 1, sPrice: "4.799,00", cPrice: "4.319,10", oPrice: "3.839,20", ePrice: "", id: "11", ean: null, innerCode: null, preview: "/images/samples/products/TV LED 65' 4K Ultra HD Smart.png", packType: 'UN', packQuantity: 1, extraParameter_1: null, extraParameter_2: null, extraParameter_3: null, extraParameter_4: null, extraParameter_5: null, extraParameter_6: null, extraParameter_7: null, extraParameter_8: null, extraParameter_9: null, extraParameter_10: null, initDate: new Date(), endDate: new Date() },
	{ description: "Ar-condicionado Split Inverter 12.000 BTUs", marketID: 1, sPrice: "2.899,00", cPrice: "2.609,10", oPrice: "", ePrice: "", id: "12", ean: null, innerCode: null, preview: '/images/samples/products/Ar-condicionado Split Inverter 12.000 BTUs.png', packType: 'UN', packQuantity: 1, extraParameter_1: null, extraParameter_2: null, extraParameter_3: null, extraParameter_4: null, extraParameter_5: null, extraParameter_6: null, extraParameter_7: null, extraParameter_8: null, extraParameter_9: null, extraParameter_10: null, initDate: new Date(), endDate: new Date() },
	{ description: "Fogão 5 Bocas Inox", marketID: 1, sPrice: "1.599,00", cPrice: "", oPrice: "", ePrice: "1.199,25", id: "13", ean: null, innerCode: null, preview: '/images/samples/products/Fogão 5 Bocas Inox.png', packType: 'UN', packQuantity: 1, extraParameter_1: null, extraParameter_2: null, extraParameter_3: null, extraParameter_4: null, extraParameter_5: null, extraParameter_6: null, extraParameter_7: null, extraParameter_8: null, extraParameter_9: null, extraParameter_10: null, initDate: new Date(), endDate: new Date() },
];

export default {
	getOrientationLabel,
	productSamples,
	getProductIndex,
	formatPrice,
	padNumber,
	getTextByType,
	capitalizeFirstLetter,
	firstWord,
	charLimit
}
