import { IColor } from "@/types/canvas";

export const StarIcon = ({ color }: IColor) => {
    return (
        <svg className={color} xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 24 24">
            <path d="M12 0l3.668 8.155 8.332 1.151-6.064 5.828 1.48 8.866-7.416-4.554-7.417 4.554 1.481-8.866-6.064-5.828 8.332-1.151z" />
        </svg>
    );
}

export const UnselectedStarIcon = ({ color }: IColor) => {
    return (
        <svg className={color} xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 24 24">
            <path d="M18.397 17.899l1.019 6.101-7.416-4.554-7.416 4.554 1.48-8.866-6.064-5.828 8.332-1.15 3.668-8.156 3.047 6.773c-1.258 1.186-2.047 2.863-2.047 4.727 0 3.213 2.334 5.875 5.397 6.399zm5.603-6.399c0 2.485-2.017 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 2.017-4.5 4.5-4.5 4.5 2.015 4.5 4.5zm-3.086-2.122l-1.414 1.414-1.414-1.414-.707.708 1.414 1.414-1.414 1.414.707.707 1.414-1.414 1.414 1.414.708-.707-1.414-1.414 1.414-1.414-.708-.708z" />
        </svg>
    );
}