import React, { useState } from "react";
import HandleModal from "@/components/modals/BlurModal";
import { ModalInterface } from "@/types";
import ExternalProductTable from "@/components/partials/products/ExternalProductTable";
import { useCanvasProducts } from "@/contexts/editor/CanvasProductsContext";
import { useCanvas } from "@/contexts/editor/CanvasContext";
import BooleanInputRow from "@/components/inputs/rows/BooleanInputRow";
import InternalProductTable from "@/components/partials/products/InternalProductTable";

interface ProductsSearchModalInterface extends ModalInterface { }

const ProductsSearchModal: React.FC<ProductsSearchModalInterface> = ({ isOpen, onClose }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [integraBusca, setIntegraBusca] = useState(false);
	const { state } = useCanvas();
	const { products } = useCanvasProducts();

	const closeModal = () => {
		onClose();
		setIsLoading(false);
	}

	return (
		<HandleModal isOpen={isOpen} onClose={closeModal} >
			<div className="flex flex-col justify-center items-center text-center max-h-[85vh] min-w-[25vw]">
				<div className="flex flex-row justify-around w-full">
					{
						!isLoading && <span></span>
					}
					<span className="cursor-default no-text-highlight text-xl mb-2" >
						Buscar Produtos
					</span>
					{
						!isLoading && (
							<span className="cursor-default no-text-highlight text-xl mb-2" >
								{(products.length)}/{state.products.ids.length}
							</span>
						)
					}
				</div>

				{
					!isLoading && (
						<div className="w-full flex justify-center">
							<div className="w-1/5 min-w-[250px]">
								<BooleanInputRow label={integraBusca ? 'Busca Integrada' : 'Busca Local'}
									defaultValue={integraBusca} onChange={setIntegraBusca}
								/>
							</div>
						</div>
					)
				}

				{/* Tabelas de Produtos */}
				<div className="flex flex-col overflow-auto max-w-[85vw]">
					{
						integraBusca ? <ExternalProductTable isFetching={isLoading} setIsFetching={setIsLoading} />
							: <InternalProductTable isFetching={isLoading} setIsFetching={setIsLoading} />
					}
				</div>
			</div>
		</HandleModal>
	)
}

export default ProductsSearchModal;
