import { useState, useCallback } from 'react';

const useBoolean = (initValue = false) => {
	const [value, setValue] = useState(initValue);

	const toggle = useCallback(() => {
		setValue(v => !v);
	}, []);

	const on = useCallback(() => {
		setValue(true);
	}, []);

	const off = useCallback(() => {
		setValue(false);
	}, []);

	return { value, toggle, on, off };
}

export default useBoolean;
