import React from 'react';
import Row from "@/components/modals/admin/components/Row";
import PriceInput from "@/components/inputs/PriceInput";

interface PriceInputRowInterface {
	label: string,
	defaultValue: any,
	onChange: (value: any) => void,
	disabled?: boolean,
}

const PriceInputRow: React.FC<PriceInputRowInterface> = ({ label, defaultValue, onChange, disabled }) => {
	return (
		<Row>
			<span className="mr-4 w-2/5" >
				{label}
			</span>
			<div className="w-3/5" >
				<PriceInput
					disabled={disabled}
					defaultValue={defaultValue}
					onChange={(option: any) => onChange(option)}
				/>
			</div>
		</Row>
	)
}

PriceInputRow.defaultProps = {
	disabled: false,
}

export default PriceInputRow;
