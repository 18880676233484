import { configureStore } from "@reduxjs/toolkit";
import canvasReducer from "@/store/Canvas.store";
import modalsReducer from "@/store/Modals.store";
import darkReducer from "@/store/DarkMode.store";
import localeReducer from "@/store/Locale.store";

const ignoredActions = ['canvas/updateElement'];
const ignoredActionPaths = [];
const ignoredPaths = ['payload.element.image'];

const store = configureStore({
    reducer: {
        canvas: canvasReducer,
        modals: modalsReducer,
        darkMode: darkReducer,
        locale: localeReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export type RootState = ReturnType<typeof store.getState>;
export type AddDispatch = typeof store.dispatch;
export default store;
