import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import MouseOverPopover from '../buttons/MouseOverPopover';
import { useModals } from '@/contexts/modals/ModalsContext';
import { useMember } from '@/contexts/member/MemberContext';
import { useRouter } from 'next/router';
import { PlusIcon } from '@heroicons/react/solid';
import AdminHeader from '../admin/partials/AdminHeader';

export interface Tab {
	id: string;
	label: string;
	content: React.ReactNode;
	popoverText?: string;
}

interface TabContainerProps {
	tabs: Tab[];
	onTabChange?: (tab: Tab) => void;
	isUsedInModal?: boolean;
}

export interface TabContainerRef {
	changeTab: (id: string) => void;
	getActiveTab: () => string;
}

const TabContainer = forwardRef<TabContainerRef, TabContainerProps>(({ tabs, onTabChange, isUsedInModal }, ref) => {
	const [activeTab, setActiveTab] = useState(tabs[0].id);

	const { setShowMarketModal } = useModals();
	const { member } = useMember();
	const router = useRouter();

	const goBack = () => router.back();

	useEffect(() => {
		if (onTabChange) onTabChange(tabs.find((tab) => tab.id === activeTab) as Tab);
	}, [activeTab])

	useImperativeHandle(ref, () => ({
		changeTab(id: string) {
			setActiveTab(id);
		},
		getActiveTab() {
			return activeTab;
		}
	}));

	const find = tabs[0].id === 'markets' && tabs[0].id === activeTab;

	return (
		<div className="container h-full flex flex-col w-full min-w-full">
			<div className="flex w-full overflow-auto">
				<div className='w-full flex items-top justify-start'>
					{tabs.map((tab) => (
						<button
							key={tab.id}
							onClick={() => setActiveTab(tab.id)}
							className={`py-4 px-6 text-zinc-800 dark:text-white font-semibold flex flex-row ${activeTab === tab.id
								? 'border-t-2 border-orange-500'
								: 'hover:border-t-2 hover:border-orange-500 duration-75'}`}
						>
							{tab.label}
							{
								tab.popoverText && (
									<MouseOverPopover popoverText={tab.popoverText}>
										<span className='ml-2 px-3 py-1 text-sm rounded-full bg-zinc-100'>?</span>
									</MouseOverPopover>
								)
							}
						</button>
					))}
				</div>
				{
					(member.admin() || member.role === 'manager') && (
						<div className='pr-4 w-1/3 flex items-center justify-end gap-2'>
							{
								find ? (
									<>
										{
											member.admin() && (
												<button
													className="flex items-center justify-center gap-2 bg-blue-500 hover:bg-blue-600 shadow-md shadow-blue-500/50 text-white px-6 py-2 rounded-lg"
													onClick={goBack}
												>
													{/* <ArrowLeft className='stroke-white' /> */}
													Voltar
												</button>
											)
										}

										<button
											className="flex items-center justify-between gap-2 bg-green-500 shadow-md shadow-green-500/50 text-white pl-6 pr-4 py-2 rounded-lg"
											onClick={() => setShowMarketModal(true)}
										>
											Nova Loja
											<PlusIcon className='w-4' />
										</button>
									</>
								) : (
									<AdminHeader isOpenModal={isUsedInModal} />
								)
							}
						</div>
					)
				}
			</div>
			<div className="h-full overflow-visible w-full min-w-full">
				{tabs.map((tab) => (
					<div className='h-full w-full' key={tab.id} hidden={activeTab !== tab.id}>
						{tab.content}
					</div>
				))}
			</div>
		</div>
	);
});

export default TabContainer;
