import React, { useState } from 'react';
import { ClockIcon, ChatAlt2Icon } from "@heroicons/react/solid";
import marketingIcon from '@public/animations/marketing-icon.json';
import developerIcon from '@public/animations/developer-icon.json';
import robotIcon from '@public/animations/robot.json';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import Api from '@/api/Api';
import { useMember } from '@/contexts/member/MemberContext';
import { EntityID } from '@/types/silverstripe';
import { NotificationType } from '@/contexts/notification/@types';
import Notify from '@/helpers/Notify';
import Icon from '@/components/notifications/Icon';
import { useNotifications } from '@/contexts/notification/NotificationContext';

interface INotification {
	id: EntityID,
	type: NotificationType,
	userName: string,
	actionLabel: string,
	relativeTime: string,
	title: string,
	message: string,
	read: boolean
}

export const Notification: React.FC<INotification> = ({ id, type, userName, actionLabel, relativeTime, title, message, read }) => {
	const { member } = useMember();
	const [play, setPlay] = useState(false);
	const [openMessage, setOpenMessage] = useState(false);
    const [isMarking, marking] = useState(false);

    const { refreshNotifications, isFetchingNotifications } = useNotifications();

	const onMouseEnter = () => setPlay(true);
	const onMouseLeave = () => setPlay(false);

	const onClick = () => {
		setOpenMessage(!openMessage);
	}

	const markAsRead = () => {
		const api = new Api('notifications', 'r');
		const request = api.request(member, { notificationID: id });
        marking(true);
		api.post(request).then((response) => {
			if (response.success) {
                refreshNotifications();
			}
            else Notify.Warn("Não foi possível completar.");
		}).finally(resetContainer);
	}

    const resetContainer = () => {
        setOpenMessage(false);
        setTimeout(() => marking(false), 1000);
    }

	const animations: Record<NotificationType, any> = {
		marketing: marketingIcon,
		developer: developerIcon,
		system: robotIcon,
	}

	const animationSpeed: Record<NotificationType, any> = {
		marketing: 0.6,
		developer: 1,
		system: 1.3,
	}

	return (
		<div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className="flex items-start gap-4 py-4 px-6 bg-white hover:bg-gray-100 rounded-lg shadow-md transition-colors duration-300 whitespace-pre-line">
			<div className="rounded-full w-[6rem] min-w-[90px] bg-gray-200 shadow-lg">
				<Lottie
					loop={true}
					speed={animationSpeed[type]}
					play={play}
					animationData={animations[type]}
				/>
			</div>
			<div className="flex flex-col gap-2">
				<p className="text-sm text-gray-500">
					<span className="font-semibold text-gray-900">{userName}</span>
					&nbsp;{actionLabel}:
				</p>
				<p className="flex items-center gap-2 text-xs text-gray-400">
					<Icon icon={ClockIcon} className="w-4" />
					{relativeTime}
					{
						!read && !isMarking &&
						<span onClick={markAsRead} className='ml-1 text-gray-500 hover:text-blue-600 hover:underline cursor-pointer'>
							Marcar como lido
						</span>
					}
				</p>
                <div className="hover:text-blue-600 cursor-pointer" onClick={onClick}>
                    <p className="flex items-center gap-2 text-base font-semibold">
                        <Icon icon={ChatAlt2Icon} className="w-5" />
                        {title}
                    </p>
                    {openMessage ? (
                        <p onClick={onClick} className="flex items-center gap-2 text-sm text-gray-600 cursor-pointer">
                            {message}
                        </p>
                    ) : (
                        <span className="flex items-center w-full hover:underline gap-2 text-xs cursor-pointer">
                            Mostrar mais
                        </span>
                    )}
                </div>
            </div>
		</div>
	)
}

export default Notification;