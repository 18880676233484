import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isDark: false,
};

const darkModeSlice = createSlice({
    name: "darkMode",
    initialState: initialState,
    reducers: {
        enableDarkMode(state) {
            state.isDark = true;
        },
        disableDarkMode(state) {
            state.isDark = false;
        },
    },
});

export const darkActions = darkModeSlice.actions;
export default darkModeSlice.reducer;
