import React, { useRef, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { ModalInterface } from '@/types';

export interface BlurModalProps extends ModalInterface {
	children: React.ReactNode;
	closable?: boolean;
	bgClass?: string;
	className?: string;
	extraClass?: string;
	ignoreOverlay?: string;
	dontWantPadding?: boolean;
	backdropClass?: string;
}

const HandleModal: React.FC<BlurModalProps> = ({
	isOpen,
	onClose,
	children,
	closable,
	bgClass,
	className,
	extraClass,
	ignoreOverlay,
	dontWantPadding,
	backdropClass
}) => {
	const contentRef = useRef<HTMLDivElement>(null);

	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const target = event.target as HTMLElement;
		const mustIgnoreTarget = ignoreOverlay && (target.id === ignoreOverlay || target.classList.contains(ignoreOverlay));

		if (closable && contentRef.current && !contentRef.current.contains(event.target as Node) && !mustIgnoreTarget) {
			onClose();
		}
	};

	useEffect(() => {
		const handleEscapeKey = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				onClose();
			}
		};

		if (isOpen) {
			document.addEventListener('keydown', handleEscapeKey);
		}

		return () => {
			document.removeEventListener('keydown', handleEscapeKey);
		};
	}, [isOpen, onClose]);

	HandleModal.defaultProps = {
		closable: true,
		bgClass: "bg-white",
		className: `rounded-3xl`,
		extraClass: "",
		backdropClass: "bg-black bg-opacity-30"
	};

	return (
		<>
			<Transition show={isOpen} as={React.Fragment}>
				<div className={`fixed inset-0 z-80 ${backdropClass}`} onClick={handleOverlayClick}>
					<div className="relative flex items-center justify-center h-full max-h-screen">
						<Transition.Child
							enter="transition-all duration-300"
							enterFrom="scale-0"
							enterTo="scale-100"
							leave="transition-all duration-300"
							leaveFrom="scale-100"
							leaveTo="scale-0"
						>
							<div ref={contentRef} className={`${bgClass} ${className} ${dontWantPadding ? "" : "p-6"}${extraClass}`}>
								{children}
							</div>
						</Transition.Child>
					</div>
				</div>
			</Transition>
		</>
	);
};

export default HandleModal;
