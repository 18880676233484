import { toast } from "react-toastify";

type ToastAction = () => void;

type ActionPositions = {
	center: ToastAction;
	left: ToastAction;
	right: ToastAction;
	bottomCenter: ToastAction;
	bottomLeft: ToastAction;
	bottomRight: ToastAction;
};

const __DEFAULT_TOAST_POSITION__ = toast.POSITION.BOTTOM_RIGHT;

class Notify {
	static Success(message: string): void { toast.success(message, { position: __DEFAULT_TOAST_POSITION__ }) }

	static Warn(message: string): void { toast.warn(message, { position: __DEFAULT_TOAST_POSITION__ }) }

	static Error(message: string): void { toast.error(message, { position: __DEFAULT_TOAST_POSITION__ }) }

	static Info(message: string): void { toast.info(message, { position: __DEFAULT_TOAST_POSITION__ }) }

	static success(message: string): ActionPositions {
		return {
			center: () => {
				toast.success(message, { position: toast.POSITION.TOP_CENTER });
			},
			left: () => {
				toast.success(message, { position: toast.POSITION.TOP_LEFT });
			},
			right: () => {
				toast.success(message, { position: toast.POSITION.TOP_RIGHT });
			},
			bottomCenter: () => {
				toast.success(message, { position: toast.POSITION.BOTTOM_CENTER });
			},
			bottomLeft: () => {
				toast.success(message, { position: toast.POSITION.BOTTOM_LEFT });
			},
			bottomRight: () => {
				toast.success(message, { position: toast.POSITION.BOTTOM_RIGHT });
			},
		};
	}

	static warn(message: string): ActionPositions {
		return {
			center: () => {
				toast.warn(message, { position: toast.POSITION.TOP_CENTER });
			},
			left: () => {
				toast.warn(message, { position: toast.POSITION.TOP_LEFT });
			},
			right: () => {
				toast.warn(message, { position: toast.POSITION.TOP_RIGHT });
			},
			bottomCenter: () => {
				toast.warn(message, { position: toast.POSITION.BOTTOM_CENTER });
			},
			bottomLeft: () => {
				toast.warn(message, { position: toast.POSITION.BOTTOM_LEFT });
			},
			bottomRight: () => {
				toast.warn(message, { position: toast.POSITION.BOTTOM_RIGHT });
			},
		};
	}

	static error(message: string): ActionPositions {
		return {
			center: () => {
				toast.error(message, { position: toast.POSITION.TOP_CENTER });
			},
			left: () => {
				toast.error(message, { position: toast.POSITION.TOP_LEFT });
			},
			right: () => {
				toast.error(message, { position: toast.POSITION.TOP_RIGHT });
			},
			bottomCenter: () => {
				toast.error(message, { position: toast.POSITION.BOTTOM_CENTER });
			},
			bottomLeft: () => {
				toast.error(message, { position: toast.POSITION.BOTTOM_LEFT });
			},
			bottomRight: () => {
				toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
			},
		};
	}

	static info(message: string): ActionPositions {
		return {
			center: () => {
				toast.info(message, { position: toast.POSITION.TOP_CENTER });
			},
			left: () => {
				toast.info(message, { position: toast.POSITION.TOP_LEFT });
			},
			right: () => {
				toast.info(message, { position: toast.POSITION.TOP_RIGHT });
			},
			bottomCenter: () => {
				toast.info(message, { position: toast.POSITION.BOTTOM_CENTER });
			},
			bottomLeft: () => {
				toast.info(message, { position: toast.POSITION.BOTTOM_LEFT });
			},
			bottomRight: () => {
				toast.info(message, { position: toast.POSITION.BOTTOM_RIGHT });
			},
		};
	}
}

export default Notify;
