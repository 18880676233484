import { useMediaQuery } from 'react-responsive';

type ScreenQueryResult = {
	isLargeScreen: boolean,
	isMediumScreen: boolean,
	isSmallScreen: boolean
}

const useScreenDimensions = (): ScreenQueryResult => {
    const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
    const isMediumScreen = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1279px)' });
    const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });

    if (isLargeScreen) return { isLargeScreen: true, isMediumScreen: false, isSmallScreen: false };
    if (isMediumScreen) return { isLargeScreen: false, isMediumScreen: true, isSmallScreen: false };
    if (isSmallScreen) return { isLargeScreen: false, isMediumScreen: false, isSmallScreen: true };
    return { isLargeScreen: false, isMediumScreen: false, isSmallScreen: false };
}

export default useScreenDimensions;
