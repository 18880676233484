import { useStepper } from '@/contexts/onboarding/steps/StepContext';
import React from 'react';

interface StepperInterface {
    onChange: (step: number) => void,
}

const StepConfig: React.FC<StepperInterface> = ({ onChange }) => {
    const { activeStep, setActiveStep } = useStepper();
    const steps = ['Permissões de Acesso', 'Informações'];

    const handleIcons = (index: number) => {
        const icons = [
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 9.761v-4.761c0-2.761-2.238-5-5-5-2.763 0-5 2.239-5 5v4.761c-1.827 1.466-3 3.714-3 6.239 0 4.418 3.582 8 8 8s8-3.582 8-8c0-2.525-1.173-4.773-3-6.239zm-8-4.761c0-1.654 1.346-3 3-3s3 1.346 3 3v3.587c-.927-.376-1.938-.587-3-.587s-2.073.211-3 .587v-3.587zm4 11.723v2.277h-2v-2.277c-.596-.347-1-.984-1-1.723 0-1.104.896-2 2-2s2 .896 2 2c0 .738-.404 1.376-1 1.723z" /></svg>,
            <svg xmlns="http://www.w3.org/2000/svg" height="1.3em" viewBox="0 0 192 512"><path d="M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V448h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H64V256H32c-17.7 0-32-14.3-32-32z" /></svg>,
        ]
        return icons[index];
    }

    const handleChangeActiveStep = (step: number) => {
        setActiveStep(step);
        onChange(step);
    }

    return (
        <div className="w-full px-8 mb-5">
            <div className="flex items-center justify-around space-x-4">
                {steps.map((step, index) => (
                    <div key={`step-${index}`} className="flex items-center cursor-pointer" onClick={() => handleChangeActiveStep(index)}>
                        <div className="flex flex-col items-center">
                            <div
                                className={`rounded-full h-10 w-10 flex items-center justify-center mb-2 cursor-pointer 
                                    ${index === activeStep ? 'bg-blue-500 text-white fill-white' : 'bg-white border-2 border-gray-200 text-gray-500 hover:text-white hover:bg-blue-500 fill-blue-500 hover:fill-white'}`}
                            >
                                {handleIcons(index)}
                            </div>
                            <div className="text-center">
                                <div className={`font-semibold ${index === activeStep ? 'text-blue-500' : 'text-gray-500 hover:text-blue-500'}`}>{step}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default StepConfig;
