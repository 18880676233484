import { IColor } from "@/types/canvas";

const RowIcon = ({ color }: IColor) => {
    return (
        <svg className={color} width="64px" height="64px" viewBox="0 0 24 24" strokeWidth="1.8" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <g clipPath="url(#clip0_3190_17433)">
                <path d="M9.8525 14.6334L3.65151 10.6873C2.41651 9.90141 2.41651 8.09858 3.65151 7.31268L9.8525 3.36659C11.1628 2.53279 12.8372 2.53279 14.1475 3.36659L20.3485 7.31268C21.5835 8.09859 21.5835 9.90142 20.3485 10.6873L14.1475 14.6334C12.8372 15.4672 11.1628 15.4672 9.8525 14.6334Z" strokeWidth="1.8"> </path>
                <path d="M18.2857 12L20.3485 13.3127C21.5835 14.0986 21.5835 15.9014 20.3485 16.6873L14.1475 20.6334C12.8372 21.4672 11.1628 21.4672 9.8525 20.6334L3.65151 16.6873C2.41651 15.9014 2.41651 14.0986 3.65151 13.3127L5.71429 12" strokeWidth="1.8"></path>
            </g>
            <defs>
                <clipPath id="clip0_3190_17433">
                    <rect width="24" height="24" fill="white">
                    </rect>
                </clipPath>
            </defs>
        </svg>
    )
}

export default RowIcon;