import React, { useEffect, useState } from "react";
import HandleModal from "@/components/modals/BlurModal";
import { useMember } from "@/contexts/member/MemberContext";
import TabContainer from "@/components/wrappers/TabContainer";
import { ModalInterface } from "@/types";
import Row from "@/components/modals/admin/components/Row";
import TextInputRow from "@/components/inputs/rows/TextInputRow";
import DropdownRow from "@/components/inputs/rows/DropdownRow";
import { useProducts } from "@/contexts/products/ProductsContext";
import PriceInputRow from "@/components/inputs/rows/PriceInputRow";
import Dropzone from "@/components/cards/common/Dropzone";
import { AllowedPackType, SilverProduct } from "@/types/products";
import PriceHelper from "@/helpers/PriceHelper";
import { useCanvasProducts } from "@/contexts/editor/CanvasProductsContext";
import Notify from "@/helpers/Notify";
import TextHelper from "@/helpers/TextHelper";
import EpjLoadingIcon from "@/components/animations/EpjLoadingIcon";

interface ExternalProductModalInterface extends ModalInterface {
	product?: SilverProduct | null
}

const ExternalProductModal: React.FC<ExternalProductModalInterface> = ({ isOpen, onClose, product }) => {
	const title = "Detalhes do Produto"

	const { member } = useMember();

	const {
		resetContext,
		setDescription, setShortDescription, setEanCode, setInnerCode, setPackType,
		setSPrice, setCPrice, setOPrice, setEPrice, setProductImage
	} = useProducts();

	const { addSilverProduct, getBy } = useCanvasProducts();

	const [isLoading, setIsLoading] = useState(false);

	const closeModal = () => {
		resetContext();
		setIsLoading(false);
		onClose();
	}

	const buttonsClasses = "mt-2 mx-4 py-2 px-5 rounded-md transition-all duration-300";

	useEffect(() => {
		if (product && member.isLogged() && isOpen) {
			setDescription(product.Description || '');
			setShortDescription(product.ShortDescription || '');
			setEanCode(product.Ean || '');
			setInnerCode(product.Code || '');
			setPackType((product.PackType?.toLocaleLowerCase() as AllowedPackType) || 'un');

			setSPrice(PriceHelper.isPriceValid(product.SPrice) ? String(product.SPrice) : '');
			setCPrice(PriceHelper.isPriceValid(product.CPrice) ? String(product.CPrice) : '');
			setOPrice(PriceHelper.isPriceValid(product.OPrice) ? String(product.OPrice) : '');
			setEPrice(PriceHelper.isPriceValid(product.EPrice) ? String(product.EPrice) : '');

			setProductImage(product.ImageURL || null);
		}
	}, [product, isOpen]);

	const onConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		if (!product) return;

		const existingProduct = getBy('id', product.ID);
		if (existingProduct) {
			Notify.Warn("Este produto já está disponível em sua lista!");
		}
		else {
			addSilverProduct(product);
			Notify.Success(`"${TextHelper.firstWord(product.Description)}" está disponível no cartaz.`);
		}
		closeModal();
	}

	const onCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		closeModal();
	}

	const cancelButtonClasses = isLoading ? 'bg-slate-500 cursor-not-allowed' : 'bg-slate-200 hover:bg-slate-300';
	const confirmButtonClasses = isLoading ? 'bg-orange-800 cursor-not-allowed' : 'bg-orange-500 hover:bg-orange-600';

	const tabs = [
		{
			id: 'tab1',
			label: 'Detalhes',
			content: <ProductDetailsContainer />,
		},
		{
			id: 'tab2',
			label: 'Preços',
			content: <PriceContainer />,
		},
		{
			id: 'tab3',
			label: 'Imagem do Produto',
			content: <ProductImageContainer />,
		},
	];

	return (
		<HandleModal isOpen={isOpen} onClose={closeModal}>
			{
				isLoading ?
					<EpjLoadingIcon />
					: (
						<div className="flex flex-col justify-center items-center" >
							<span className="cursor-default no-text-highlight text-2xl mt-3 mb-4" >
								{title}
							</span>
							<TabContainer tabs={tabs} />
							<Row>
								<button className={`${buttonsClasses} ${cancelButtonClasses}`} disabled={isLoading} onClick={onCancel}>Fechar</button>
								{
									product ? (
										<button className={`${buttonsClasses} ${confirmButtonClasses} text-white`}
											disabled={isLoading}
											onClick={onConfirm}>
											Adicionar Produto
										</button>
									) : <></>
								}
							</Row>
						</div>
					)
			}
		</HandleModal>
	)
}

ExternalProductModal.defaultProps = {
	product: undefined
}

export default ExternalProductModal;

const ProductDetailsContainer: React.FC = () => {
	const {
		description, shortDescription, eanCode, innerCode, packType,
		setDescription, setShortDescription, setEanCode, setInnerCode, setPackType
	} = useProducts();

	return (
		<div className="flex flex-col justify-center items-center text-center w-[500px] mb-10">
			<TextInputRow disabled={true} label="Descrição:" defaultValue={description} onChange={setDescription} />
			<TextInputRow disabled={true} label="EAN:" defaultValue={eanCode} onChange={setEanCode} />
			<TextInputRow disabled={true} label="Código Interno:" defaultValue={innerCode} onChange={setInnerCode} />
			<TextInputRow disabled={true} label="Descrição Curta:" defaultValue={shortDescription} onChange={setShortDescription} />
			<div className="w-full mt-4">
				<DropdownRow
					label="Embalagem do Produto:"
					value={packType}
					data={get_allowed_packages()}
					onChange={(option: any) => setPackType(option.id)}
					dataKey="id"
					textField="label"
					disabled={true}
				/>
			</div>
		</div>
	)
}

const PriceContainer: React.FC = () => {
	const {
		sPrice, cPrice, oPrice, ePrice,
		setSPrice, setCPrice, setOPrice, setEPrice
	} = useProducts();

	return (
		<div className="flex flex-col justify-center items-center text-center w-[500px] mb-10">
			<PriceInputRow disabled={true} label="Preço Simples" defaultValue={sPrice} onChange={setSPrice} />
			<PriceInputRow disabled={true} label="Preço Clube" defaultValue={cPrice} onChange={setCPrice} />
			<PriceInputRow disabled={true} label="Preço Oferta" defaultValue={oPrice} onChange={setOPrice} />
			<PriceInputRow disabled={true} label="Preço Extra" defaultValue={ePrice} onChange={setEPrice} />
		</div>
	)
}

const ProductImageContainer: React.FC = () => {
	const { productImage, setProductImage } = useProducts();
	return (
		<div className="flex flex-col justify-center items-center text-center max-h-[500px] w-[500px] mb-10">
			<Dropzone disableUpload={true} onChange={(uri: string | null) => setProductImage(uri)} defaultUri={productImage} />
		</div>
	)
}

function get_allowed_packages() {
	return [
		{ id: 'un', label: 'Unidade' },
		{ id: 'kg', label: 'Quilo' },
	];
}
