import { createContext, useContext, useState, useEffect } from "react";
import { ReactChildren } from "@/types";

interface StepperProviderInterface {
    children: ReactChildren
}

interface StepperContextData {
	activeStep: number,
    setActiveStep: (value: number) => void,
}

const StepperContext = createContext<StepperContextData>(
    {} as StepperContextData
);

export const StepProvider: React.FC<StepperProviderInterface> = ({ children }) => {
    const [activeStep, setActiveStep] = useState<number>(0);

    return (
        <StepperContext.Provider
            value={{
				activeStep,
				setActiveStep
            }}
        >
            {children}
        </StepperContext.Provider>
    );
};

export const useStepper = (): StepperContextData => {
	const context = useContext(StepperContext);

	if (!context)
	{
		throw new Error("Stepper must be used within an ModalsContext");
	}
	return context;
};

