import React, { Fragment, useRef, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { usePopoverAutoClose } from '@/hooks/usePopoverAutoClose';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { ExternalSearchType } from '@/types/products';

interface PopoverProps {
	onChange: (value: ExternalSearchType) => void
	defaultValue: ExternalSearchType
}

interface ContentInterface {
	isOpen: boolean,
	onClick: (value: ExternalSearchType) => void
}

interface ItemProps {
	label: string,
	value: ExternalSearchType,
	onClick: (value: ExternalSearchType) => void
}

const SearchDropdownPopover: React.FC<PopoverProps> = ({ onChange, defaultValue }) => {
	const { popoverRef, hiddenInputRef, toggle } = usePopoverAutoClose();
	const [selectedItem, setSelectedItem] = useState<ExternalSearchType>(defaultValue);

	const onSelect = (value: ExternalSearchType) => {
		setSelectedItem(value);
		onChange(value);
		toggle();
	}

	return (
		<Popover className="relative">
			{({ open }: { open: boolean }) => (
				<React.Fragment>
					<Popover.Button ref={popoverRef}
						className="h-full flex items-center cursor-pointer"
						onClick={() => null}
					>
						<span
							id="dropdown-button"
							className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
							// onClick={handleToggleDropdown}
						>
							{getLabel(selectedItem)}
							<ChevronRightIcon
								className={`${open && 'transform rotate-180'} w-5 h-5 text-slate-500`}
							/>
						</span>
					</Popover.Button>

					{/* Armazena o estado do 'open' uma vez que não temos acesso a ele no componente. */}
					<input ref={hiddenInputRef} type="hidden" value={open ? 'true' : 'false'} />

					<Transition
						as={Fragment}
						enter="transition ease-out duration-200"
						enterFrom="opacity-0 translate-y-1"
						enterTo="opacity-100 translate-y-0"
						leave="transition ease-in duration-150"
						leaveFrom="opacity-100 translate-y-0"
						leaveTo="opacity-0 translate-y-1"
					>
						<Popover.Panel className={`absolute rounded-lg bg-white dark:bg-slate-500 dark:text-white left-[17em] z-10 min-w-fit -translate-x-56 transform px-0 lg:max-w-3xl`}>
							<div className={`overflow-hidden rounded-lg shadow-sm ring-1 ring-black ring-opacity-5 w-fit`}>
								<div className="h-fit w-[200px] flex flex-col justify-center items-center" >
									<Content isOpen={open} onClick={onSelect} />
								</div>
							</div>
						</Popover.Panel>
					</Transition>
				</React.Fragment>
			)}
		</Popover>
	)
}

SearchDropdownPopover.defaultProps = {
	defaultValue: 'general' as ExternalSearchType
}

export default SearchDropdownPopover;

const Content: React.FC<ContentInterface> = ({ isOpen, onClick }) => {
	
	return (
		<div
			id="dropdown"
			className={`absolute z-10 left-10 ${!isOpen && 'hidden'} bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 origin-top-right`}
		>
			<ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button">
				<Item label="Geral" value="general" onClick={onClick} />
				<Item label="Descrição" value="description" onClick={onClick} />
				<Item label="Ean" value="ean" onClick={onClick} />
				<Item label="Código Interno" value="code" onClick={onClick} />
			</ul>
		</div>
	)
}

const Item: React.FC<ItemProps> = ({ label, value, onClick }) => {
	const buttonClasses = "inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white";
	return (
		<li>
			<button type="button" className={buttonClasses} onClick={() => onClick(value)}>
				{label}
			</button>
		</li>
	)
}

function getLabel(value: ExternalSearchType): string
{
	const labels = {
		general: "Geral",
		description: "Descrição",
		ean: "Ean",
		code: "Código Interno"
	}
	return labels[value];
}
