import React, { createContext, useContext, useEffect, useState } from 'react';
import { ModelsContextInterface } from '@/contexts/models/interface';
import { Orientation, PrivacyOptions } from '@/types/canvas';
import { ParentInterface } from '@/types';
import { useQuery } from 'react-query';
import { useMember } from '@/contexts/member/MemberContext';
import Api from '@/api/Api';
import { EntityID } from '@/types/silverstripe';
import useBoolean from '@/hooks/useBoolean';
import { __BANNER_TYPE__ } from '@/types/constants';
import { useCanvas } from '@/contexts/editor/CanvasContext';

export type TemplateListType = {
    id: string,
    name: string,
    campaignID: EntityID,
    campaignName: string,
    orientation: Orientation,
    privacy: PrivacyOptions,
    preview: string | null
}

export type ItemListType = TemplateListType & {
    companyID: EntityID,
    companyName: string,
}

export type TemplateLists = Record<Orientation, TemplateListType[]>;

const ModelsContext = createContext<ModelsContextInterface | undefined>(undefined);

const ModelsProvider: React.FC<ParentInterface> = ({ children }) => {
    const { state } = useCanvas();

    const { templateType } = state;

    const [isLoadingModels, setIsLoadingModels] = useState(true);
    const [term, setTerm] = useState('');
    const [targetProductCount, setTargetProductCount] = useState<number | null>(null);
    const [targetOrientation, setTargetOrientation] = useState<Orientation | null>(null);

    const [targetModel, setTargetModel] = useState<EntityID>(null);

    const { value: isOfferSelectorModalVisible, on: showOfferSelector, off: hideOfferSelector } = useBoolean(false);

    const [refreshList, setRefreshList] = useState(true);

    const [templateLists, setTemplateLists] = useState<TemplateLists>({
        vertical: [],
        horizontal: [],
        p21: [],
        p30: [],
        story: [],
        post: [],
    });

    const countTotalTemplates = Object.values(templateLists).reduce(
        (total, templateList) => total + templateList.length,
        0
    );

    const api = new Api('templates', 'gl');

    const { member, isFetchingData } = useMember();

    const { data: response, isLoading, refetch } = useQuery(['templateList', member, term, targetProductCount, targetOrientation, templateType], async () => {
        const requestData = {
            ...(term ? { term } : {}),
            ...(Number.isInteger(targetProductCount) ? { targetProductCount } : {}),
            ...(targetOrientation ? { targetOrientation } : {}),
            ...(templateType ? { templateType } : {})
        };

        const request = api.request(member, requestData);
        setIsLoadingModels(true);
        const res = await api.post(request);
        setIsLoadingModels(false);
        return res;
    }, { enabled: !isFetchingData, refetchOnWindowFocus: false });

    useEffect(() => {
        if (!isLoading && response?.success) {
            const { templateList: list } = response.data;
            setTemplateLists(categorizeTemplates(list));
        }
    }, [isLoading, response]);

    useEffect(() => {
        if (refreshList) {
            refetch();
            setRefreshList(false);
        }
    }, [refreshList, refetch]);

    return <ModelsContext.Provider value={{
        isLoadingModels,
        setIsLoadingModels,

        refreshList,
        setRefreshList,

        term,
        setTerm,

        targetProductCount,
        setTargetProductCount,

        targetOrientation,
        setTargetOrientation,

        templateLists,
        countTotalTemplates,

        targetModel,
        setTargetModel,

        isOfferSelectorModalVisible,
        showOfferSelector,
        hideOfferSelector,
    }}>
        {children}
    </ModelsContext.Provider>;
};

const useModels = (): ModelsContextInterface => {
    const context = useContext(ModelsContext);
    if (!context) {
        throw new Error('useModels must be used within a ModelsProvider');
    }
    return context;
};

export { ModelsProvider, useModels };

export function categorizeTemplates(list: any[]): TemplateLists {
    const categories: TemplateLists = {
        vertical: [],
        horizontal: [],
        p21: [],
        p30: [],
        story: [],
        post: [],
    };

    list.forEach((item: any) => {
        const formattedItem: ItemListType = {
            id: item.Hash,
            name: item.Name,
            companyID: item.OwnerID,
            companyName: item.OwnerName,
            campaignID: item.CampaignID,
            campaignName: item.CampaignName,
            orientation: item.Orientation,
            privacy: item.Privacy,
            preview: item.Preview,
        };

        if (categories[item.Orientation as keyof TemplateLists]) {
            categories[item.Orientation as keyof TemplateLists].push(formattedItem);
        }
    });

    return categories;
}
