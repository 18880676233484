import React from 'react';

const BoxIcon = () => (
	<svg
		fill="none"
		strokeLinecap="round"
		strokeLinejoin="round"
		strokeWidth="2"
		viewBox="0 0 24 24"
		stroke="currentColor"
		className="h-6 w-6"
	>
		<path d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"></path>
	</svg>
);

export default BoxIcon;
