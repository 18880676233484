import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react';
import { Switch } from '@headlessui/react';

interface BooleanInputInterface {
	onChange: (value: boolean) => void;
	defaultValue?: boolean;
}

interface BooleanInputRef {
	toggleEnable: () => void;
	getValue: () => boolean;
}

const BooleanInput = forwardRef<BooleanInputRef, BooleanInputInterface>(
	({ defaultValue, onChange }, ref) => {
		const [enabled, setEnabled] = useState<boolean>(defaultValue ?? false);

		useEffect(() => {
			// Sincroniza o estado interno com a prop defaultValue quando ela mudar
			setEnabled(defaultValue ?? false);
		}, [defaultValue]);

		const toggleEnable = () => {
			const newEnabledState = !enabled;
			setEnabled(newEnabledState);
			onChange(newEnabledState);
		};

		useImperativeHandle(ref, () => ({
			toggleEnable,
			getValue: () => enabled,
		}));

		return (
			<div className="flex flex-row w-full justify-center items-center">
				<Switch
					checked={enabled}
					onChange={toggleEnable}
					className={`${enabled ? 'bg-orange-500' : 'bg-gray-700'}
          relative inline-flex h-8 w-16 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
				>
					<span className="sr-only">Use setting</span>
					<span
						aria-hidden="true"
						className={`${enabled ? 'translate-x-8' : 'translate-x-0'}
            pointer-events-none inline-block h-full w-[28px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
					/>
				</Switch>
			</div>
		);
	}
);

const BooleanInputSmall = forwardRef<BooleanInputRef, BooleanInputInterface>(
	({ defaultValue, onChange }, ref) => {
		const [enabled, setEnabled] = useState<boolean>(defaultValue ?? false);

		useEffect(() => {
			// Sincroniza o estado interno com a prop defaultValue quando ela mudar
			setEnabled(defaultValue ?? false);
		}, [defaultValue]);

		const toggleEnable = () => {
			const newEnabledState = !enabled;
			setEnabled(newEnabledState);
			onChange(newEnabledState);
		};

		useImperativeHandle(ref, () => ({
			toggleEnable,
			getValue: () => enabled,
		}));

		return (
			<Switch
				checked={enabled}
				onChange={toggleEnable}
				className={`${enabled ? 'bg-orange-500' : 'bg-gray-700'}
          relative inline-flex h-6 w-10 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
			>
				<span className="sr-only">Use setting</span>
				<span
					aria-hidden="true"
					className={`${enabled ? 'translate-x-4' : 'translate-x-0'}
            pointer-events-none inline-block h-full w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
				/>
			</Switch>
		);
	}
);

BooleanInput.defaultProps = {
	defaultValue: false,
};

export { BooleanInputSmall };
export default BooleanInput;

export function useBooleanInputRef() {
	return useRef<BooleanInputRef>(null);
}
