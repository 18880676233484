import mergeObjects from "@/hooks/mergeObjects";
import { IMember } from "@/types/member";
import { EncryptedRequest, PostMethod, ResponseObject } from "./types";
import CryptoHelper from "@/helpers/CryptoHelper";
import DEBUG from "@/helpers/DEBUG";

export default class Api {
	private readonly target: string;
	private readonly endpoint: string;

	constructor(target: string = 'login', endpoint: string = '', host: string | null = null) {
		const url = host || process.env.NEXT_PUBLIC_JORNAL_BACKEND as string;
		this.target = host?.length ? `${host}/${target}` : `${url}/api/${target}`;
		this.endpoint = endpoint.length > 0 ? `${endpoint}/` : endpoint;
	}

	link(link?: string) {
		return `${this.target}/${this.endpoint}${link ?? ''}`;
	}

	prepareResponse(data: any = null) {
		if (data) {
			return create_response_object(data.success, data.data, data.code, data.message);
		}
		else {
			return create_response_object(false, null, 'unknown_error', 'An unknown error occurred.');
		}
	}

	decryptResponse(data: any = null) {
		const encryptor = Api.getEncryptor();
		const decryptedData = encryptor.decrypt(data);
		return typeof decryptedData === 'string' ? JSON.parse(decryptedData) : decryptedData;
	}

	async fetch(link?: string) {
		try {
			const response = await fetch(this.link(link));
			const data = await response.json();
			return this.prepareResponse(data);
		} catch {
			return this.prepareResponse();
		}
	}

	async post(data?: any, link?: string) {
		try {
			const response = await fetch(this.link(link), Api.createOptions(data));
			const responseData = await response.json();
			const decryptedData = this.decryptResponse(responseData._r);
			
			return this.prepareResponse(decryptedData);
		} catch (error: any) {
			if (DEBUG.ENABLED) console.log('error:', error.message);
			return this.prepareResponse();
		}
	}

	async fetchAPI(data?: any, link?: string) {
		try {
			const response = await fetch(this.link(link), Api.createOptions(data));
			const responseData = await response.json();

			if (responseData.error) {
				const errorResponse = create_response_object(false, null, 'api_fetch_fail', responseData.message);
				return this.prepareResponse(errorResponse);
			}

			const decryptedData = this.decryptResponse(responseData._r);
			return this.prepareResponse(decryptedData);
		} catch (error: any) {
			if (DEBUG.ENABLED) console.log('error:', error.message);
			return this.prepareResponse();
		}
	}

	async postBlob(data?: any, link?: string) {
		try {
			const response = await fetch(this.link(link), { method: 'POST', body: data });
			const responseData = await response.json();

			const decryptedData = this.decryptResponse(responseData._r);
			return this.prepareResponse(decryptedData);
		} catch (error: any) {
			if (DEBUG.ENABLED) console.log('error:', error.message);
			return this.prepareResponse();
		}
	}

	request<T>(member: IMember, data: T = {} as T): { hash: string } & T {
		return mergeObjects({ hash: member.hash }, data);
	}

	useHash(member: IMember): object {
		return this.request(member, {});
	}

	static createOptions(data: object): PostMethod {
		return {
			method: 'POST',
			headers: {
				'Content-Type': 'text/plain'
			},
			body: JSON.stringify(Api.encryptRequest(data))
		};
	}

	static getEncryptor(): CryptoHelper {
		const __SECURITY_ENCRYPTION_KEY__ = process.env.NEXT_PUBLIC_SECURITY_ENCRYPTION_KEY;
		return new CryptoHelper(__SECURITY_ENCRYPTION_KEY__);
	}

	static encryptRequest(data: object): EncryptedRequest {
		const encryptor = Api.getEncryptor();
		const encryptedData = encryptor.encrypt(JSON.stringify(data));
		return { _r: encryptedData };
	}
}

function create_response_object(success: boolean, data: any = null, code: string = '', message: string = ''): ResponseObject {
	return { success, data, code, message };
}
