import React, { Fragment, useRef, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { usePopoverAutoClose } from '@/hooks/usePopoverAutoClose';
import { useMember } from '@/contexts/member/MemberContext';
import { useTranslation } from 'next-i18next';
import DarkMode from "@/components/buttons/DarkMode";
import LanguageSelector from "@/components/buttons/LanguageSelector";
import { useModals } from '@/contexts/modals/ModalsContext';
import { __DEVELOPER_APP_VERSION__ } from "@/types/constants";
import DEBUG from '@/helpers/DEBUG';

interface PopoverProps {
}

interface ContentInterface {
	onMouseLeave: () => void;
}

const UserPopover: React.FC<PopoverProps> = ({ }) => {
	const { popoverRef, hiddenInputRef, isSidebarOpen } = usePopoverAutoClose();
	const { member } = useMember();
	const { t: u } = useTranslation('user.translation');

	const isOpen = () => {
		if (!hiddenInputRef.current) return false;
		return (hiddenInputRef.current as HTMLInputElement).value === 'true';

	}

	const handleClick = () => {
		if (!popoverRef.current) return;
		const ref = popoverRef.current;
		if (!isOpen()) ref.click();
	}

	const onLeave = () => {
		if (!popoverRef.current) return;
		const ref = popoverRef.current;
		if (isOpen()) ref.click();
	}

	return (
		<div onClick={handleClick} >
			<Popover className="relative">
				{({ open }: { open: boolean }) => (
					<React.Fragment>
						<Popover.Button ref={popoverRef}
							className="h-full flex items-center cursor-pointer bg-white bg-opacity-70 hover:bg-opacity-100 transition duration-700 ease-in-out rounded-lg p-2 mx-2"
							onClick={() => null}
						>
							<span className='mx-2 text-black flex flex-row'>
								<span className="font-semibold">
									{u("intro.hello")}, {member.isLogged() ? member.firstName : u("intro.guest")}
								</span>
								<img src={member.isLogged() && member.avatar ? member.avatar : 'https://picsum.photos/200'}
									alt="Avatar Image"
									width={25}
									height={25}
									className="rounded-full ml-4" />
							</span>
						</Popover.Button>

						{/* Armazena o estado do 'open' uma vez que não temos acesso a ele no componente. */}
						<input ref={hiddenInputRef} type="hidden" value={open ? 'true' : 'false'} />

						<Transition
							as={Fragment}
							enter="transition ease-out duration-200"
							enterFrom="opacity-0 translate-y-1"
							enterTo="opacity-100 translate-y-0"
							leave="transition ease-in duration-150"
							leaveFrom="opacity-100 translate-y-0"
							leaveTo="opacity-0 translate-y-1"
						>
							<Popover.Panel className={`absolute rounded-lg bg-white dark:bg-slate-500 dark:text-white left-1/2 z-10 mt-5 min-w-fit -translate-x-56 transform px-0 lg:max-w-3xl`}>
								<div className={`overflow-hidden rounded-lg shadow-sm ring-1 ring-black ring-opacity-5 w-fit`}>
									<div className="h-fit w-[300px] flex flex-col justify-center items-center" >
										<Content onMouseLeave={onLeave} />
									</div>
								</div>
							</Popover.Panel>
						</Transition>
					</React.Fragment>
				)}
			</Popover>
		</div>
	)
}

export default UserPopover;

const Content: React.FC<ContentInterface> = ({ onMouseLeave }) => {
	const { member, setIsLoginModalVisible } = useMember();
	const { setShowConfigModal, setShowProfileModal, setShowDeveloperModal } = useModals();

	const { t: u } = useTranslation('user.translation');

	const handleVersionClick = () => {
		if (DEBUG.ENABLED) setShowDeveloperModal(true);
	}

	return (
		<div className={"flex flex-col items-center h-full w-full py-4"} onMouseLeave={onMouseLeave} >
			<section className="flex flex-col h-full">
				<span className="flex items-center mx-auto">
					<span className="font-medium text-black dark:text-white no-text-highlight">
						{u("intro.hello")}, {member.isLogged() ? member.firstName : u("intro.guest")}!
					</span>
					<img src={member.isLogged() && member.avatar ? member.avatar : 'https://picsum.photos/200'}
						alt="Avatar Image"
						width={125}
						height={125}
						className="w-10 rounded-full ml-4" />
				</span>

			</section>
			<div className='mt-4 w-full flex justify-center'>
				<div
					onClick={() => setShowProfileModal(true)}
					className={`cursor-pointer w-3/5 p-2 rounded-md text-center transition dark:text-slate-200 bg-teal-100 border border-1 border-teal-500 text-teal-600 hover:bg-teal-200 dark:bg-teal-100/[.3]`}
				>
					Meu Perfil
				</div>
			</div>

			<div className='mt-4 w-full flex justify-center'>
				<div
					onClick={() => setShowConfigModal(true)}
					className={`cursor-pointer w-3/5 p-2 rounded-md text-center transition dark:text-blue-500 bg-blue-100 border border-1 border-blue-500 text-blue-600 hover:bg-blue-200 dark:bg-teal-100/[.3]`}
				>
					Configurações
				</div>
			</div>


			<div className='mt-4 w-full flex justify-center'>
				<LoginButton onClick={() => setIsLoginModalVisible(true)} />
			</div>
			<SystemController enableDarkMode={false} enableInternalization={false} />
			<div className={`w-full flex justify-center ${DEBUG.ENABLED && 'hover:underline hover:cursor-pointer'}`} onClick={handleVersionClick}>
				<small className='text-gray-400' >v{__DEVELOPER_APP_VERSION__}</small>
			</div>
		</div>
	)
}

const SystemController: React.FC<{ enableDarkMode: boolean, enableInternalization: boolean }> = ({ enableDarkMode = false, enableInternalization = false }) => {
	const isBothEnabled = enableDarkMode && enableInternalization;

	return (
		<div className="flex justify-between items-center mt-5">
			{enableInternalization && <LanguageSelector />}
			{isBothEnabled && <div className="w-2" />}
			{
				enableDarkMode && (
					<div className="bg-light-black dark:bg-slate-700 w-full p-2 rounded">
						<DarkMode />
					</div>
				)
			}
		</div>
	)
}

function LoginButton({ onClick }: { onClick: () => void }) {
	const { member } = useMember();
	const defaultClasses = "cursor-pointer w-3/5 p-2 rounded-md text-center transition dark:text-slate-200";
	const loggedMemberClasses = "bg-teal-100 border border-1 border-teal-500 text-teal-600 hover:bg-teal-200 dark:bg-teal-100/[.3]"
	const loggedOutMemberClasses = "bg-rose-100 border border-1 border-rose-500 text-rose-600 hover:bg-rose-200 dark:bg-rose-100/[.3]";

	return (
		<div
			onClick={member.isLogged() ? member.logout : onClick}
			className={`${defaultClasses} ${member.isLogged() ? loggedOutMemberClasses : loggedMemberClasses}`}
		>
			{
				member.isLogged() ? 'Sair' : 'Fazer Login'
			}
		</div>
	);
}
