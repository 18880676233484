import React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

type MouseOverPopoverProps = {
	mainText?: string;
	popoverText?: string;
	mainClass?: string;
	popoverClass?: string;
	children?: React.ReactNode;
};

const MouseOverPopover: React.FC<MouseOverPopoverProps> = ({
	popoverText = 'I use Popover.',
	mainClass = '',
	popoverClass = '',
	children
}) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<div>
			<div
				className={mainClass}
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
			>
				{children}
			</div>
			<Popover
				id="mouse-over-popover"
				sx={{
					pointerEvents: 'none',
					'& .MuiPaper-root': {
						boxShadow: 'none', // Remove a sombra
						border: '1px solid #D3D3D3', // Adiciona uma borda
						borderRadius: "6px"
					},
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 35,
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Typography className={popoverClass} sx={{ px: 1.5, py: 0.2, fontSize: "14px" }}>
					{popoverText}
				</Typography>
			</Popover>
		</div>
	);
}

export default MouseOverPopover;
