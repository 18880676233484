import { ParentInterface } from '@/types';

interface StepContainerProps extends ParentInterface {
	onConfirm?: () => void;
	confirmLabel?: string;	
	styles?: string;
}

const StepContainer: React.FC<StepContainerProps> = ({ children, onConfirm, confirmLabel = 'Salvar', styles }) => {

	return (
		<div className={`relative flex flex-col justify-center items-center sm:h-[45vh] md:h-[48vh] lg:h-[50vh] xl:h-[58vh] ${styles} w-full rounded-lg`}>
			{onConfirm &&
				<button
					className="absolute top-2 right-2 px-4 py-2 rounded-lg bg-orange-500 text-white hover:bg-orange-600 cursor-pointer"
					onClick={onConfirm}
				>
					{confirmLabel}
				</button>
			}
			{children}
		</div>
	)
}

export default StepContainer;
