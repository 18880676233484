import React from 'react';

const SidebarSkeleton = () => {
	return (
		<div className="min-h-screen flex justify-center">
			<div className="flex w-full max-w-xs min-w-[18rem] p-4 bg-gradient-to-br from-stone-100 via-stone-200 to-stone-300">
				<div className="h-screen min-w-full overflow-y-auto pb-10">
					<ul className="flex flex-col w-full pr-2">
						<li className="my-px">
							<div className="flex flex-row justify-start items-center">
								<div className="h-12 w-12 bg-gray-200 rounded-full mr-4"></div>
								<div className="flex flex-col">
									<div className="h-4 bg-gray-200 rounded w-20 mb-1"></div>
									<div className="h-3 bg-gray-200 rounded w-28"></div>
								</div>
								<div className="flex mb-auto cursor-pointer">
									<div className="h-12 w-12 bg-gray-200 rounded-full"></div>
								</div>
							</div>
						</li>

						<li className="my-px">
							<div className="flex items-center mt-4">
								<div className="h-6 w-6 bg-gray-200 rounded-full mr-3"></div>
								<div className="h-4 bg-gray-200 rounded w-20"></div>
							</div>
						</li>

						<li className="my-px">
							<div className="flex items-center mt-4 cursor-pointer">
								<div className="h-6 w-6 bg-gray-200 rounded-full mr-3"></div>
								<div className="h-4 bg-gray-200 rounded w-24"></div>
							</div>
						</li>

						<li className="my-px">
							<div className="flex items-center mt-4">
								<div className="h-6 w-6 bg-gray-200 rounded-full mr-3"></div>
								<div className="h-4 bg-gray-200 rounded w-20"></div>
							</div>
						</li>

						<li className="my-px">
							<div className="flex items-center mt-4">
								<div className="h-6 w-6 bg-gray-200 rounded-full mr-3"></div>
								<div className="h-4 bg-gray-200 rounded w-24"></div>
							</div>
						</li>

						<li className="my-px">
							<div className="flex items-center mt-4">
								<div className="h-6 w-6 bg-gray-200 rounded-full mr-3"></div>
								<div className="h-4 bg-gray-200 rounded w-20"></div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default SidebarSkeleton;
