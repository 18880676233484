import axios from 'axios';

type ImageCallback = (outputImage: string) => void;

export default class ImageHelper {

	static sampleLogos = [
		'/images/brand/sample/logo_1.png',
		'/images/brand/sample/logo_2.png',
		'/images/brand/sample/logo_3.png',
	];

	static isBase64(image: string | null): boolean {
		return /^data:image\/[a-z]+;base64,/.test(image || "");
	}

	static toBase64(file: File): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				resolve(reader.result as string);
			};
			reader.onerror = () => {
				reject(reader.error);
			};
			reader.readAsDataURL(file);
		});
	};

	static async removeBackground(image: string | null, callback: ImageCallback) {
		if (!image) return;
		try {
			const response = await fetch("/api/remove-bg", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ image }),
			});

			if (!response.ok) {
				// throw new Error("Erro ao processar a imagem.");
				callback(image); // devolve string original.
			}

			const data = await response.json();
			const outputBase64 = data.imageBase64;
			callback(outputBase64);
		}
		catch (error) {
			console.error("Erro ao remover o fundo da imagem", error);
		}
	}

	static createClientURL(path: string | null = null, useReplace: boolean = true): string {
		if (!process.env.NEXT_PUBLIC_BASE_URL) throw new Error("Environment variable NEXT_PUBLIC_BASE_URL must be set.")
		const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:3002';

		if (!path) return baseUrl;

		if (useReplace) path = path.replace(/^\/+/g, '');
		return `${baseUrl}${path}`;
	}

	static createServerURL(path: string | null = ''): string {
		if (!process.env.NEXT_PUBLIC_HOST_URL) {
			throw new Error("NEXT_PUBLIC_HOST_URL must be set.");
		}

		// Se estiver em formato base64 devolve como está.
		if (ImageHelper.isBase64(path)) {
			return path || "";
		}
		return `${process.env.NEXT_PUBLIC_HOST_URL}${path ?? ''}`;
	}

	static blobToBase64(blob: Blob): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				resolve(reader.result as string);
			};
			reader.onerror = () => {
				reject(reader.error);
			};
			reader.readAsDataURL(blob);
		});
	}

	static async getImageAsBase64(url: string, useKnowHost: boolean = true, options: object = {}): Promise<string | null> {
		// Verifica se já está em base64.
		if (ImageHelper.isBase64(url)) {
			return new Promise((resolve) => {
				resolve(url); // devolve a string original.
			});
		}
		try {
			if (useKnowHost && !url.includes(ImageHelper.createServerURL())) {
				url = ImageHelper.createServerURL(url);
			}

			const response = await axios.get(url, {
				responseType: 'blob',
				...options
			});

			if (response.status !== 200) {
				return null;
			}
			const blob = new Blob([response.data], { type: response.headers['content-type'] });
			const base64 = await this.blobToBase64(blob);
			return base64;
		} catch (error: any) {
			console.error("Erro ao obter a imagem como base64::::", error.message);
			return null;
		}
	}
}
