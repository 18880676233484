import React, { useEffect, useState } from "react";
import HandleModal from "@/components/modals/BlurModal";
import { ModalInterface } from "@/types";
import SecurityStep from "@/components/partials/onboarding/steps/SecurityStep";
import CompanyStep from "@/components/partials/onboarding/steps/CompanyStep";
import { StepProvider, useStepper } from "@/contexts/onboarding/steps/StepContext";
import StepConfig from "@/components/partials/onboarding/StepConfig";

interface ConfigModalInterface extends ModalInterface { }

const ConfigModal: React.FC<ConfigModalInterface> = ({ isOpen, onClose }) => {
    const { activeStep } = useStepper();
    const [step, setStep] = useState<number>(0);

    useEffect(() => {
        if (activeStep != null) {
            setStep(activeStep);
        }
    }, [activeStep])

    const stepContent = [
        <SecurityStep style="sm:h-[53vh] md:h-[58vh] lg:h-[63vh]" />,
        <div className="relative">
            <CompanyStep setButton={false} styles="sm:h-[53vh] md:h-[58vh] lg:h-[63vh]" />
        </div>,
    ];

    return (
        <HandleModal isOpen={isOpen} onClose={() => { onClose(), setStep(0) }} closable={true}>
            <div className="flex justify-end">
                <button onClick={() => { onClose(), setStep(0) }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="fill-gray-300 hover:fill-gray-500" width="15" height="15" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" /></svg>
                </button>
            </div>
            <div className="px-10 pt-10 h-[85vh] w-[90vw] overflow-y-hidden flex flex-col justify-center">
                <StepProvider>
                    <StepConfig onChange={setStep} />
                    <div className="m-auto">
                        {stepContent[step]}
                    </div>
                </StepProvider>
            </div>
        </HandleModal>
    );
};

export default ConfigModal;
