import dynamic from "next/dynamic";

const Background = dynamic(() => import("./shapes/Background"), {
    ssr: false,
});

const Rectangle = dynamic(() => import("./shapes/Rectangle"), {
    ssr: false,
});

const Triangle = dynamic(() => import("./shapes/Triangle"), {
    ssr: false,
});

const Circle = dynamic(() => import("./shapes/Circle"), {
    ssr: false,
});

const Image = dynamic(() => import("./shapes/Image"), {
    ssr: false,
});

const Text = dynamic(() => import("./shapes/Text"), {
    ssr: false,
});

const Pricing = dynamic(() => import("./shapes/Pricing"), {
    ssr: false,
});

const Star = dynamic(() => import("./shapes/Star"), {
    ssr: false,
});

const Polygon = dynamic(() => import("./shapes/Polygon"), {
    ssr: false,
});

export {Background};
export {Rectangle};
export {Triangle};
export {Circle};
export {Image};
export {Text};
export {Pricing};
export {Star};
export {Polygon};
