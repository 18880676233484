import { useCallback } from 'react';
import Cookies from 'js-cookie';

interface UseCookies {
	setCookie: (key: string, value: string, options?: Cookies.CookieAttributes) => void;
	getCookie: (key: string) => string | undefined;
	removeCookie: (key: string, options?: Cookies.CookieAttributes) => void;
}

const useCookies = (): UseCookies => {
	const setCookie = useCallback((key: string, value: string, options?: Cookies.CookieAttributes) => {
		Cookies.set(key, value, options);
	}, []);

	const getCookie = useCallback((key: string): string | undefined => {
		return Cookies.get(key);
	}, []);

	const removeCookie = useCallback((key: string, options?: Cookies.CookieAttributes) => {
		Cookies.remove(key, options);
	}, []);

	return {
		setCookie,
		getCookie,
		removeCookie,
	};
};

export default useCookies;
