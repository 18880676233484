import { IColor } from "@/types/canvas";

const TagPriceIcon = ({ color }: IColor) => {
    return (
        <svg className={color} width="64px" height="64px" viewBox="0 0 24 24" strokeWidth="1.8" fill="none" xmlns="http://www.w3.org/2000/svg" color="#71717a">
            <path d="M2.90602 17.505L5.33709 3.71766C5.5289 2.62987 6.56621 1.90354 7.654 2.09534L19.4717 4.17912C20.5595 4.37093 21.2858 5.40824 21.094 6.49603L18.6629 20.2833C18.4711 21.3711 17.4338 22.0975 16.346 21.9057L4.52834 19.8219C3.44055 19.6301 2.71421 18.5928 2.90602 17.505Z" stroke="#71717a" strokeWidth="1.8"></path><path d="M8.92902 6.38184L16.8075 7.77102" stroke="#71717a" strokeWidth="1.8" strokeLinecap="round"></path>
            <path d="M8.23444 10.3213L16.1129 11.7105" stroke="#71717a" strokeWidth="1.8" strokeLinecap="round"></path><path d="M7.53986 14.2607L12.4639 15.129" stroke="#71717a" strokeWidth="1.8" strokeLinecap="round"></path>
        </svg>
    );
}

export default TagPriceIcon;