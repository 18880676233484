import React, { useState, useEffect } from "react";
import { useMember } from "@/contexts/member/MemberContext";
import Api from "@/api/Api";
import Notify from "@/helpers/Notify";
import { useModals } from "@/contexts/modals/ModalsContext";
import { useCompany } from "@/contexts/company/CompanyContext";
import EditIcon from "@/components/svg/EditIcon";
import Loading from "@/components/animations/Loading";

type RightSidebarProps = { isOpen: boolean, toggleSidebar: () => void };

const __BG_ANIMATED_GRADIENT__ = "transform-gpu transition-all duration-[600ms] bg-gradient-to-r to-dark-orange via-orange-400 from-dark-orange bg-size-200 bg-pos-0 hover:bg-pos-100 group";

const ProfileSidebar = ({ isOpen, toggleSidebar }: RightSidebarProps) => {
	const { member, isLoginModalVisible, createMemberFromAPIResponse } = useMember();
	const { company } = useCompany();
	const { setProfilePictureSelectorVisibility } = useModals();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [changes, setChanges] = useState<boolean>(false);

	useEffect(() => {
		if (isOpen && isLoginModalVisible) toggleSidebar();
		if (!isOpen && changes) onClick();
		setChanges(false);
	}, [isOpen, isLoginModalVisible])

	const handleOverlayClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		toggleSidebar();
	};

	// State para o nome e sobrenome
	const [firstName, setFirstName] = useState<string>("");
	const [lastName, setLastName] = useState<string>("");

	useEffect(() => {
		if (member.isLogged()) {
			setFirstName(member.firstName);
			setLastName(member.lastName || '');
		}
	}, [member]);

	useEffect(() => {
		const handleEscapeKey = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				toggleSidebar();
			}
		};

		if (isOpen) {
			document.addEventListener('keydown', handleEscapeKey);
		}

		return () => {
			document.removeEventListener('keydown', handleEscapeKey);
		};
	}, [isOpen]);


	const onClick = async () => {
		if (!firstName || !lastName) {
			Notify.Warn("Por favor, preencha todos os campos");
			return;
		}

		const api = new Api('members');
		const request = api.request(member, {
			payload: {
				FirstName: firstName,
				Surname: lastName
			}
		});

		setIsLoading(true);
		const response = await api.post(request, 'u');
		if (response.success) {
			const { result } = response.data;
			if (result) {
				const authRequest = api.useHash(member);
				const authResponse = await api.post(authRequest, 'a');
				if (authResponse.success) {
					if (createMemberFromAPIResponse(authResponse)) {
						Notify.Success("Salvo!");
					}
					else Notify.Error("Houve um erro ao atualizar");
				}
			}
		}
		setIsLoading(false);
	}

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { id, value } = event.target;
		if (id === 'grid-first-name') setFirstName(value);
		else if (id === 'grid-last-name') setLastName(value);
		setChanges(true);
	}

	return (
		<React.Fragment>
			<div
				className={`fixed inset-0 bg-black bg-opacity-20 transition-opacity duration-300 ease-in-out z-50 ${isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}
				onClick={handleOverlayClick}></div>
			<div
				className={`mt-24 mx-10 fixed bg-opacity-20 rounded-tl-xl top-0 right-0 z-50 dark:bg-slate-700 transition-all duration-300 ease-in-out ${isOpen ? 'transform-none opacity-100 pointer-events-auto' : 'transform translate-x-full opacity-0 pointer-events-none'}`}
			>
				<section className="relative flex flex-col w-full">
					<div className={"my-16"}>
						<div className="w-[450px] h-[420px] rounded-xl shadow-lg bg-white">
							<div className="p-4 h-full w-full flex flex-col items-start justify-center overflow-auto" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
								<div className="w-full max-w-lg flex flex-col items-center pt-8">
									<div className="absolute top-1 inset-y-0">
										<img
											src={member.avatar || '/images/no-img.png'}
											alt="Avatar"
											className="w-28 h-28 rounded-full"
										/>
										<div className="absolute w-full flex items-center justify-center top-20 mt-2">
											<div
												className="w-8 h-8 cursor-pointer flex items-center justify-center bg-white shadow-md shadow-blue-400 fill-blue-500 rounded-full"
												onClick={() => setProfilePictureSelectorVisibility(true)}
											>
												<EditIcon />
											</div>
										</div>
									</div>
									{
										!member.avatar && (
											<p className="text-red-500 text-[12px] cursor-default mb-4">
												Você precisa escolher seu avatar antes de prosseguir!
											</p>
										)
									}
									<div className="flex flex-col items-center text-center">
										<p className="text-lg font-semibold">{firstName} {lastName}</p>
										<p className="text-sm text-gray-500">{member.email}</p>
									</div>
								</div>

								<form className="w-full mt-2">
									<div className="flex flex-row w-full">
										<div className="w-full pl-6 pr-4 mb-6 md:mb-0">
											<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
												Nome
											</label>
											<input id="grid-first-name" className="appearance-none block w-full bg-gray-50 hover:bg-white text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="text" placeholder="Seu Nome" value={firstName} onChange={handleInputChange} disabled={isLoading} />
										</div>
										<div className="w-full pr-6 pl-2 mb-6 md:mb-0">
											<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
												Sobrenome
											</label>
											<input id="grid-last-name" className="appearance-none block w-full bg-gray-50 hover:bg-white text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" type="text" placeholder="Seu Sobrenome" value={lastName} onChange={handleInputChange} disabled={isLoading} />
										</div>
										{
											isLoading && (
												<div className="w-full flex justify-center items-center">
													<Loading />
												</div>
											)
										}
									</div>
								</form>

								<div className="w-full px-3">
									<div className="flex w-full mb-8">
										<InfoRow label="Empresa" value={company?.name || ""} />
										<InfoRow label="Loja" value={member.marketID?.toString().padStart(2, "0")} />
									</div>
									<InfoRow label="Impressões Restantes" value={`${member.remaining} / ${member.maxPrints}`} />
								</div>
							</div>
						</div>
					</div>

				</section>
			</div>
		</React.Fragment>
	);
};

export default ProfileSidebar;

const InfoRow: React.FC<{ label: string, value: any }> = ({ label, value }) => {
	return (
		<div className="px-3 w-full mt-2">
			<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
				{label}
			</label>
			<span className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white">
				{value}
			</span>
		</div>
	)
}
