class SystemInfo
{
	static isDev: boolean = process.env.NODE_ENV === "development";

	static DEBUG_MODE: boolean = process.env.NEXT_PUBLIC_DEBUG_MODE === "true" && SystemInfo.isDev;

	static isClientBridge: boolean = process.env.NEXT_PUBLIC_CLIENT_BRIDGE_URL ? true : false;

	static isIntranet: boolean = process.env.NEXT_PUBLIC_INTRANET === "true";

	static USE_EPJ_IMAGES: boolean = process.env.NEXT_PUBLIC_USE_EPJ_IMAGES === "true";

	static ALLOW_REGISTRATION: boolean = process.env.NEXT_PUBLIC_ALLOW_REGISTRATION === "true";
}

export default SystemInfo;
