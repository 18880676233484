import {
    CanvasState,
    Element,
    ElementData,
    ElementType,
    GetElementDataByIdPayload,
    GroupData,
    RemoveElementPayload,
    SelectShapePayload,
    ShapeInfo
} from "@/types/canvas";
import {
    IStateActions,
    create_background,
    initialProductsState,
    initialState,
    initialEditorState,
    __BACKGROUND__
} from "./IStateReducer";
import { uuid } from "@/helpers";
import Stack from "@/classes/Stack";
import _ from "lodash";

export const history = new Stack<ElementData>();
const StateReducer = (state: CanvasState, action: IStateActions): CanvasState => {

    const getMaxZIndex = (): number => {
        const elementZIndices = state.elements.map((element) => element.zIndex).filter((zIndex) => typeof zIndex === 'number' && !isNaN(zIndex));
        const groupZIndices = state.groups.map((group) => group.zIndex).filter((zIndex) => typeof zIndex === 'number' && !isNaN(zIndex));

        const maxElementZIndex = elementZIndices.length > 0 ? Math.max(...elementZIndices) : -1;
        const maxGroupZIndex = groupZIndices.length > 0 ? Math.max(...groupZIndices) : -1;

        return Math.max(maxElementZIndex, maxGroupZIndex);
    };

    const getUpdatedState = (prevState: CanvasState, id: string | null) => {
        const history = prevState.history;
        history.push(prevState);
        return {
            ...prevState,
            history,
            products: {
                ...prevState.products,
                selected: id,
            },
        };
    }

    const updateElementProductID = (prevState: CanvasState, id: string, productID: string | null) => {
        // Encontra o índice do elemento pelo id fornecido
        const elementIndex = prevState.elements.findIndex(el => el.attributes.id === id);
        if (elementIndex === -1) return prevState;

        // Cria uma cópia dos elementos e atualize o productID do elemento selecionado
        const updatedElements = [...prevState.elements];
        updatedElements[elementIndex] = {
            ...updatedElements[elementIndex],
            attributes: {
                ...updatedElements[elementIndex].attributes,
                productID: productID,
            },
        };
        // Retorne o state atualizado
        return {
            ...prevState,
            elements: updatedElements,
        };
    };

    const getElementDataById = (id: GetElementDataByIdPayload): ElementData | null => {
        const data = state.elements.filter(data => (data.attributes.id === id));
        return data.length > 0 ? data[0] : null;
    }

    const getGroupDataById = (id: string | null): GroupData | null => {
        if (id) {
            const groupId = id.replace('group_selection_', '');
            const data = state.groups.filter(group => (group.id === groupId));
            return data.length > 0 ? data[0] : null;
        }
        return null;
    }

    function buildShapeInfo(selectedID: string | null, selectedType: ElementType | null): ShapeInfo {
        return {
            id: selectedID,
            exists: selectedID !== null,
            isBackground: selectedType === 'background',
            isCircle: selectedType === 'circle',
            isImage: selectedType === 'image',
            isRectangle: selectedType === 'rectangle',
            isText: selectedType === 'text',
            isGroup: !!((selectedID && !selectedType) && (selectedID.includes('group_selection')))
        }
    }

    const selectShape = (payload: SelectShapePayload) => {
        return {
            ...state,
            current: {
                id: payload.id,
                type: payload.type,
                exists: Boolean(payload.id),
                info: buildShapeInfo(payload.id, payload.type)
            }
        };
    };

    const addGroup = (id: string, elements: Element[]) => {
        const zIndex = getMaxZIndex() + 1;
        const elementIds = elements.map((element) => element.id)
            .filter((id) => id !== 'background');

        const history = state.history;
        history.push(state);
        // Atualize o groupID dos elementos agrupados para o id do grupo
        const updatedElements = state.elements.map((elementData) => {
            const element = elementData.attributes;
            if (elementIds.includes(element.id)) {
                return { ...elementData, attributes: { ...element, groupID: id } };
            }
            return elementData;
        });

        return {
            ...state,
            history,
            current: {
                id: `group_selection_${id}`,
                type: null,
                exists: true,
                info: buildShapeInfo(`group_selection_${id}`, null)
            },
            groups: [
                ...state.groups,
                {
                    id,
                    zIndex,
                    type: "group",
                    attributes: {
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0,
                        scaleX: 1,
                        scaleY: 1,
                        rotation: 0,
                        skewX: 0,
                        skewY: 0,
                    }
                },
            ],
            elements: updatedElements,
        };
    };

    const ungroup = (id: RemoveElementPayload) => {
        if (id) {
            const groupId = id.replace('group_selection_', '');
            const history = state.history;
            history.push(state);

            // Atualize os elementos, definindo groupID como null
            const updatedElements = state.elements.map((elementData) => {
                const element = elementData.attributes;
                if (element.groupID === groupId) {
                    const index = state.groups.findIndex(data => data.id === groupId);
                    const group = state.groups[index];

                    return { ...elementData, attributes: { ...element, groupID: null } };
                }
                return elementData;
            });

            // Remova o grupo do array de grupos
            const updatedGroups = state.groups.filter((group) => group.id !== groupId);

            return {
                ...state,
                history,
                elements: updatedElements,
                groups: updatedGroups,
            };
        }
    };

    const previousElement = (elements: ElementData[]) => {
        const last = history.peekUndo();
        const element = elements.find(el => el.attributes.id === state.current.id);
        if (element) {
            history.undoPush(element);
        } else if (!last) {
            if (element) {
                history.undoPush(element);
            }
        }
    }

    switch (action.type) {
        case "setTemplateId": {
            if (!action.templateId) return state;
            return { ...state, templateId: action.templateId };
        }
        case "setTemplateName": {
            if (!action.templateName) return state;
            return { ...state, templateName: action.templateName };
        }
        case "setTemplateType": {
            if (!action.templateType) return state;
            return { ...state, templateType: action.templateType };
        }
        case "setCampaignId": {
            if (!action.campaignId) return state;
            return { ...state, campaignId: action.campaignId };
        }
        case "setCampaignName": {
            if (!action.campaignName) return state;
            return { ...state, campaignName: action.campaignName };
        }
        case "setPriceType": {
            if (!action.priceType) return state;
            return {
                ...state,
                priceType: action.priceType
            }
        }
        case "changeViewMode": {
            if (!action.viewMode) return state;
            return {
                ...state,
                editor: { ...state.editor, viewMode: action.viewMode }
            }
        }
        case "createProduct": {
            const history = state.history;
            return {
                ...state,
                history,
                products: {
                    ...state.products,
                    ids: [...state.products.ids, uuid()]
                }
            }
        }
        case "removeProduct": {
            if (!action.id) return state;

            const history = state.history;
            history.push(state);
            return {
                ...state,
                history,
                products: {
                    selected: null,
                    ids: state.products.ids.filter(currentId => currentId !== action.id)
                }
            }
        }
        case "selectProduct": {
            const history = state.history;
            history.push(state);

            if (action.id === null) {
                return {
                    ...state,
                    history,
                    products: {
                        ...state.products,
                        selected: action.id,
                    },
                }
            } else {
                return state.products.ids.includes(action.id)
                    ? getUpdatedState(state, action.id) : state;
            }
        }
        case "attachElementToProduct": {
            if (!action.id || !action.productId) return state;

            return (action.productId && state.products.ids.includes(action.productId))
                ? updateElementProductID(state, action.id, action.productId)
                : updateElementProductID(state, action.id, null);
        }
        case "addElement": {
            const zIndex = getMaxZIndex() + 1;
            const history = state.history;
            history.push(state);
            return {
                ...state,
                history,
                elements: [...state.elements, { ref: null, type: "element", attributes: action.attributes, zIndex }],
            }
        }
        case "addElements": {
            const zIndex = getMaxZIndex() + 1;
            const history = [...state.history, state];

            const newElements: ElementData[] = action.attributeList.map((attributes, i) => ({
                ref: null,
                type: "element",
                attributes,
                zIndex: zIndex + i,
            }));

            return {
                ...state,
                history,
                elements: [...state.elements, ...newElements],
            };
        }
        case "duplicateElement": {
            const data = getElementDataById(action.id);

            if (!data) return state;

            const seed = action.seedAttrs || {};
            const { attributes } = data;
            const zIndex = getMaxZIndex() + 1;
            const attrs = {
                ...attributes,
                id: uuid(),
                x: attributes.x + 10, // desloca levemente para não sobrepor o elemento atual
                y: attributes.y + 10,
                ...seed
            }
            const history = state.history;
            history.push(state);

            selectShape({ id: attrs.id, type: attrs.type }); // Seleciona como elemento atual.
            return {
                ...state,
                history,
                elements: [...state.elements, { ref: null, type: "element", attributes: attrs, zIndex }],
            };
        }
        case "addGroup": {
            addGroup(action.id, action.elements);
            return { ...state };
        }
        case "addElementToGroup": {
            // Verifica se o grupo e o elemento existem
            const groupExists = state.groups.some(group => group.id === action.groupId);
            const elementData = state.elements.find(elementData => elementData.attributes.id === action.elementId);

            if (!elementData) {
                console.error("addElementToGroup: elemento não encontrado [" + action.elementId + "]");
                return state;
            }

            // Se o grupo não existe, crie um novo grupo e adicione o elemento
            if (!groupExists) {
                addGroup(action.groupId, [elementData.attributes]);
                return state;
            }

            // Atualize o groupID do elemento
            const updatedElements = state.elements.map(elementData => {
                const element = elementData.attributes;
                if (element.id === action.elementId) {
                    return { ...elementData, attributes: { ...element, groupID: action.groupId } };
                }
                return elementData;
            });

            const history = state.history;
            history.push(state);

            return {
                ...state,
                history,
                elements: updatedElements,
            };
        }
        case "removeElement": {
            if (action.id && action.id !== "background") {
                const history = state.history;
                history.push(state);

                const targetId = action.id.replace('group_selection_', '');

                // Remova o elemento do array de elementos
                let updatedElements = state.elements.filter((data) => data.attributes.id !== targetId);

                // Verifique se o id corresponde a um grupo
                const groupToRemove = state.groups.find((group) => group.id === targetId);

                // Se o id corresponder a um grupo, remova todos os elementos do grupo
                if (groupToRemove) {
                    ungroup(targetId);
                }

                // Remova o grupo do array de grupos (se existir)
                const updatedGroups = state.groups.filter((group) => group.id !== targetId);

                const lastElement = updatedElements.length >= 2 ? updatedElements[updatedElements.length - 1] : null;
                if (lastElement) selectShape({ id: lastElement.attributes.id, type: lastElement.attributes.type });
                else selectShape({ id: null, type: null });

                previousElement(state.elements);

                return {
                    ...state,
                    history,
                    elements: updatedElements,
                    groups: updatedGroups,
                };
            }

            return { ...state }
        }

        case "updateElement": {
            let updatedElements = state.elements;
            const attributes = action.payload.attributes;

            // Atualize o elemento no array de elementos
            updatedElements = state.elements.map((el) => {
                const element = el.attributes;
                if (element.id === action.payload.id) {
                    return { ...el, attributes: { ...element, ...attributes } };
                }
                return el;
            });

            if (!action.payload.isUndoFunction) {
                previousElement(state.elements);
            }

            return {
                ...state,
                elements: updatedElements,
            };
        }
        case "updateElementRef": {
            const index = state.elements.findIndex(data => data.attributes.id === action.payload.id);
            if (index !== -1) {
                const updatedElements = state.elements.map((data, i) => (i === index ? { ...data, ref: action.payload.ref } : data));
                return { ...state, elements: updatedElements };
            }
            return state;
        }
        case "updateGroup": {
            const updatedGroups = state.groups.map(group => {
                if (group.id === action.id) {
                    return { ...group, attributes: action.newGroupData };
                }
                return group;
            });

            const history = state.history;
            history.push(state);

            return {
                ...state,
                history,
                groups: updatedGroups,
            };
        }
        case "updateOrientation": {
            const orientation = action.orientation;
            return {
                ...state,
                editor: { ...state.editor, orientation }
            }
        }
        case "useBackgroundRemover": {
            return {
                ...state,
                editor: { ...state.editor, useBackgroundRemover: action.value }
            }
        }
        case "unselect": {
            return {
                ...state,
                current: {
                    id: null,
                    type: null,
                    exists: false,
                    info: buildShapeInfo(null, null)
                }
            }
        }
        case "injectElements": {
            let background: ElementData[] = action.elements.filter((elementData: ElementData) => elementData.attributes.id === "background");
            background = background.length ? background : [create_background()] as ElementData[];

            return {
                ...state,
                elements: action.elements,
                groups: [],
                products: action.products || initialProductsState
            }
        }
        case "resetCanvas": {
            // fazer resetar um para cada state
            return {
                ...initialState,
                ...(action.newValues || {}),
                editor: {
                    ...initialEditorState,
                    useBackgroundRemover: state.editor.useBackgroundRemover || false,
                    viewMode: state.editor.viewMode, // mantém o modo de visualização atual
                    ...(action.newValues?.editor || {}),
                },
            }
        }
        case "loadStateData": {
            const data = action.data;

            return {
                ...initialState,
                ...state,
                ...data,
                elements: action.elements,
                groups: action.groups,
                products: {
                    ...state.products,
                    ...data.products,
                    selected: null
                },
                editor: {
                    ...state.editor,
                    ...data.editor,
                    section: state.editor.section // Mantém a section atual.
                },
            }
        }
        case "updateSection": {
            return {
                ...state,
                editor: { ...state.editor, section: action.section }
            }
        }
        case "setStageRef": {
            return {
                ...state,
                stageRef: action.stage
            }
        }
        case "replaceElement": {
            if (action.id === __BACKGROUND__) return state;

            const attributes = action.attributes;
            return {
                ...state,
                elements: state.elements.map((element) => {
                    if (element.attributes.id === action.id) return {
                        ...element,
                        attributes: {
                            ...attributes,
                            width: element.attributes.width,
                            height: element.attributes.height,
                            x: element.attributes.x,
                            y: element.attributes.y,
                            rotation: element.attributes.rotation
                        }
                    };
                    else return element;
                })
            };
        }
        case "setIsZoomEnabled": {
            return ({
                ...state,
                editor: {
                    ...state.editor,
                    isZoomEnabled: action.isOn
                }
            })
        }
        case "selectShape": {
            return {
                ...state,
                current: {
                    id: action.payload.id,
                    type: action.payload.type,
                    exists: Boolean(action.payload.id),
                    info: buildShapeInfo(action.payload.id, action.payload.type)
                }
            }
        }
        case "ungroup": {
            ungroup(action.id);
            return state;
        }
        case "setHovering": {
            return {
                ...state,
                hovering: action.element
            }
        }
        case "setComboType": {
            return {
                ...state,
                comboType: action.comboType
            }
        }
        case "setCampaignComboType": {
            return {
                ...state,
                campaignComboType: action.campaignComboType
            }
        }
    }
}

export default StateReducer;
