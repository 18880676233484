import { createContext, useContext, useState, useEffect } from "react";
import { ParentInterface } from '@/types';

interface ModalProviderInterface extends ParentInterface { }

interface ModalsContextInterface {
	// Avatar modal controllers
	isProfilePictureSelectorVisible: boolean,
	setProfilePictureSelectorVisibility: (value: boolean) => void,
	// Onboarding modal controllers
	showOnboarding: boolean,
	setShowOnboarding: (value: boolean) => void,
	// Notifications modal controllers
	showNotificationCenter: boolean,
	setNotificationCenter: (value: boolean) => void,
	// Canvas modal controllers
	showCanvasModal: boolean,
	setShowCanvasModal: (value: boolean) => void,
	// Profile modal controllers
	showProfileModal: boolean,
	setShowProfileModal: (value: boolean) => void,
	// Search product modal controllers
	searchProductModal: boolean,
	setSearchProductModal: (value: boolean) => void,
	// Products page modal controllers
	blockProductsPageView: boolean,
	setBlockProductsPageView: (value: boolean) => void,
	// Preferences editor modal controllers
	showDynamicTextModal: boolean,
	setShowDynamicTextModal: (value: boolean) => void,
	// Campaign modal controllers
	showCampaignModal: boolean,
	setShowCampaignModal: (value: boolean) => void,
	// Market modal controllers
	showMarketModal: boolean,
	setShowMarketModal: (value: boolean) => void,
	// Task modal controllers
	showTaskModal: boolean,
	setShowTaskModal: (value: boolean) => void,
	showTaskGroupModal: boolean,
	setShowTaskGroupModal: (value: boolean) => void,
	showTaskDelegationModal: boolean,
	setShowTaskDelegationModal: (value: boolean) => void,
	// Developer modal controllers
	showDeveloperModal: boolean,
	setShowDeveloperModal: (value: boolean) => void,

	showConfigModal: boolean,
	setShowConfigModal: (value: boolean) => void
}

const ModalsContext = createContext<ModalsContextInterface>(
	{} as ModalsContextInterface
);

export const ModalProvider: React.FC<ModalProviderInterface> = ({ children }) => {
	const [isProfilePictureSelectorVisible, setProfilePictureSelectorVisibility] = useState<boolean>(false);

	const [showOnboarding, setShowOnboarding] = useState<boolean>(false);

	const [showNotificationCenter, setNotificationCenter] = useState<boolean>(false);

	const [showCanvasModal, setShowCanvasModal] = useState<boolean>(false);

	const [showProfileModal, setShowProfileModal] = useState<boolean>(false);

	const [searchProductModal, setSearchProductModal] = useState<boolean>(false);

	const [blockProductsPageView, setBlockProductsPageView] = useState<boolean>(false);

	const [showDynamicTextModal, setShowDynamicTextModal] = useState<boolean>(false);

	const [showCampaignModal, setShowCampaignModal] = useState<boolean>(false);

	const [showMarketModal, setShowMarketModal] = useState<boolean>(false);

	const [showTaskModal, setShowTaskModal] = useState<boolean>(false);

	const [showTaskGroupModal, setShowTaskGroupModal] = useState<boolean>(false);

	const [showTaskDelegationModal, setShowTaskDelegationModal] = useState<boolean>(false);

	const [showDeveloperModal, setShowDeveloperModal] = useState<boolean>(false);

	const [showConfigModal, setShowConfigModal] = useState<boolean>(false);

	const resetContext = () => {
		setProfilePictureSelectorVisibility(false);
	}

	useEffect(() => {
		return resetContext();
	}, []);

	return (
		<ModalsContext.Provider
			value={{
				isProfilePictureSelectorVisible,
				setProfilePictureSelectorVisibility,

				showOnboarding,
				setShowOnboarding,

				showNotificationCenter,
				setNotificationCenter,

				showCanvasModal,
				setShowCanvasModal,

				showProfileModal,
				setShowProfileModal,

				searchProductModal,
				setSearchProductModal,

				blockProductsPageView,
				setBlockProductsPageView,

				showDynamicTextModal,
				setShowDynamicTextModal,

				showCampaignModal,
				setShowCampaignModal,

				showMarketModal,
				setShowMarketModal,

				showTaskModal,
				setShowTaskModal,
				showTaskGroupModal,
				setShowTaskGroupModal,
				showTaskDelegationModal,
				setShowTaskDelegationModal,

				showDeveloperModal,
				setShowDeveloperModal,

				showConfigModal,
				setShowConfigModal
			}}
		>
			{children}
		</ModalsContext.Provider>
	);
};

export const useModals = (): ModalsContextInterface => {
	const context = useContext(ModalsContext);

	if (!context) {
		throw new Error("useModals must be used within an ModalsContext");
	}
	return context;
};
