import { createGlobalStyle } from "styled-components";
import { FontData } from "@/contexts/fonts/FontContext";

const FontInjector = createGlobalStyle<{ fontList: FontData[] }>`
/* Carrega as fontes dinamicamente */
${props =>
		props.fontList.map(font => {
			if (font.url) {
				return `
			@font-face {
			  font-family: '${font.id}';
			  src: url(${font.url}) format('truetype');
			}
		  `
			}
		})}
	@font-face {
		font-family: 'HelveticaNeue';
		src: url('/fonts/HelveticaNeue/HelveticaNeue.ttf');
		src: url('/fonts/HelveticaNeue/HelveticaNeue Medium.ttf');
		src: url('/fonts/HelveticaNeue/HelveticaNeue Light.ttf');
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'HelveticaNeueBold';
		src: url('/fonts/HelveticaNeue/HelveticaNeue Bold.ttf');
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'HelveticaNeueCB';
		src: url('/fonts/HelveticaNeue/HelveticaNeue BlackCond.ttf');
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'Montserrat';
		src: url('/fonts/Montserrat/static/Montserrat-Regular.ttf');
		src: url('/fonts/Montserrat/static/Montserrat-Medium.ttf');
		src: url('/fonts/Montserrat/static/Montserrat-Light.ttf');
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'Roboto';
		src: url('/fonts/Roboto/Roboto-Regular.ttf');
		src: url('/fonts/Roboto/Roboto-Medium.ttf');
		src: url('/fonts/Roboto/Roboto-Light.ttf');
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'RussoOne';
		src: url('/fonts/Russo_One/RussoOne-Regular.ttf');
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'MercadilloBlack';
		src: url('/fonts/Mercadillo/Mercadillo-Regular.woff');
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'CaveatBrush';
		src: url('/fonts/Caveat_Brush/CaveatBrush-Regular.ttf');
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'Sofia';
		src: url('/fonts/Sofia/Sofia-Regular.ttf');
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'Anton';
		src: url('/fonts/Anton/Anton-Regular.ttf');
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'MajorMonoDisplay';
		src: url('/fonts/MajorMonoDisplay/MajorMonoDisplay-Regular.ttf');
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'DotGothic16';
		src: url('/fonts/DotGothic16/DotGothic16-Regular.ttf');
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'BigShotOne';
		src: url('/fonts/BigShotOne/BigShotOne-Regular.ttf');
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'RumRaisin';
		src: url('/fonts/RumRaisin/RumRaisin-Regular.ttf');
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'Pacifico';
		src: url('/fonts/Pacifico/Pacifico-Regular.ttf');
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'Grenze';
		src: url('/fonts/Grenze/Grenze-Regular.ttf');
		src: url('/fonts/Grenze/Grenze-Medium.ttf');
		src: url('/fonts/Grenze/Grenze-Light.ttf');
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'OleoScript';
		src: url('/fonts/OleoScript/OleoScript-Regular.ttf');
		src: url('/fonts/OleoScript/OleoScript-Bold.ttf');
		font-style: normal;
	  }
`;

export default FontInjector;
