import React from 'react';

interface ILightningIcon {
	className?: string;
}

const LightningIcon: React.FC<ILightningIcon> = ({ className }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		stroke="currentColor"
		className={`h-6 w-6 ${className}`}
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M13 10V3L4 14h7v7l9-11h-7z"
		/>
	</svg>
);

export default LightningIcon;
