import TextInput from "@/components/inputs/TextInput";
import { useCompany } from "@/contexts/company/CompanyContext";
import { useState } from "react";

interface ISloganContainer { }

const SloganContainer: React.FC<ISloganContainer> = ({ }) => {
	const { company } = useCompany();

	const [slogan1, setSlogan1] = useState(company?.slogans[0] || '');
	const [slogan2, setSlogan2] = useState(company?.slogans[1] || '');

	return (
		<>
			<div className="w-full h-full flex flex-col justify-center items-center">
				<h2 className="text-2xl font-semibold mb-2">Kit da Marca</h2>
				<p className="text-gray-500 mb-1">Você pode subir até duas variações do slogan da sua empresa e definir onde usá-las.</p>
				<div className="grid grid-cols-1 gap-4 md:grid-cols-1 min-w-[35rem]">
					{/* Detalhes da Empresa */}
					<div className="flex flex-col items-center bg-white p-6 rounded shadow-md duration-200">
						Slogan Primário
						<TextInput defaultValue={slogan1} onChange={(text) => setSlogan1(text)} />
					</div>
					<div className="flex flex-col items-center bg-white p-6 rounded shadow-md duration-200">
						Slogan Secundário
						<TextInput defaultValue={slogan2} onChange={(text) => setSlogan2(text)} />
					</div>
				</div>
				<div className="w-full flex flex-row space-x-3 justify-center">
					<button className="bg-orange-500 hover:bg-orange-600 mt-4 text-white py-2 px-4 my-4 rounded-lg">
						Salvar
					</button>
				</div>
			</div>
		</>
	)
}

export default SloganContainer;
