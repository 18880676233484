import { CreateChatCompletionResponse } from "openai";

/**
 * OpenAI: Uma classe utilitária para ajudar no desenvolvimento.
 */
export default class OpenAI
{
	/**
	 * Mede o tempo de execução de uma função em milissegundos.
	 * @param fn A função para a qual medir o tempo de execução.
	 * @param format Formato da saída de tempo, pode ser "s" para segundos ou "m" para minutos. Por padrão é "m".
	 * @returns O tempo de execução da função.
	 */
	static async sendMessage(prompt: string)
	{
		const jsonResponse = await fetch('/api/openai', {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify({
			  prompt: prompt,
			}),
		  });
		const response = await jsonResponse.json() as CreateChatCompletionResponse;
		return response;	  
	}
}
