import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const PORT = 3002;

const DEBUG = process.env.NODE_ENV === "development";

const initTranslator = (setTranslationsReady: (value: boolean) => void) => {
    i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            lng: "pt-BR",
            fallbackLng: "en",
            debug: false,
            interpolation: {
                escapeValue: false
            },
            react: {
                useSuspense: false
            },
            ns: [
                "translation",
                "canvas.translation",
                "user.translation"
            ],
            defaultNS: "translation",
            backend: {
                loadPath:
                    typeof window === "undefined"
                        ? `http://localhost:${PORT}/locales/{{lng}}/{{ns}}.json`
                        : "/locales/{{lng}}/{{ns}}.json",
            },
        })
        .then(() => {
            setTranslationsReady(true);
        });
};

export default initTranslator;
