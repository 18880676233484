import React from 'react';
import Row from "@/components/modals/admin/components/Row";
import TextInput from "@/components/inputs/TextInput";

interface TextInputRowInterface {
	label: string,
	defaultValue: any,
	onChange: (value: any) => void,
	disabled?: boolean,
	placeholder?: string;
}

const TextInputRow: React.FC<TextInputRowInterface> = ({ label, defaultValue, onChange, disabled, placeholder }) => {
	return (
		<Row>
			<span className="mr-4 w-2/5" >
				{label}
			</span>
			<div className="w-3/5" >
				<TextInput
					disabled={disabled}
					defaultValue={defaultValue}
					placeholder={placeholder}
					onChange={(option: any) => onChange(option)}
				/>
			</div>
		</Row>
	)
}

const TextInputCol: React.FC<TextInputRowInterface> = ({ label, defaultValue, onChange, disabled, placeholder }) => {
	return (
		<div className='w-full flex flex-col items-center justify-start'>
			<span className="w-full">
				{label}
			</span>
			<div className="w-full" >
				<TextInput
					disabled={disabled}
					defaultValue={defaultValue}
					placeholder={placeholder}
					onChange={(option: any) => onChange(option)}
				/>
			</div>
		</div>
	)
}

TextInputRow.defaultProps = {
	disabled: false,
}

export { TextInputCol };
export default TextInputRow;