import { createSlice } from "@reduxjs/toolkit";
import { AcceptedLocales } from "@/types/constants";
import i18n from "i18next";

const DEFAULT_LOCALITY: AcceptedLocales = 'en';

interface localeState {
    current: AcceptedLocales;
}

const initialState: localeState = {
    current: DEFAULT_LOCALITY
};

type SetLocalePayload = {
    locale: AcceptedLocales
}

const localeSlice = createSlice({
    name: "locale",
    initialState: initialState,
    reducers: {
        setLocale(state, action: { payload: SetLocalePayload }) {
            state.current = action.payload.locale;
            i18n.changeLanguage(action.payload.locale);
        },
        resetLocale(state) {
            state.current = DEFAULT_LOCALITY;
        }
    },
});

export const localeActions = localeSlice.actions;
export default localeSlice.reducer;
