import { IColor } from "@/types/canvas";


const CloneIcon = ({ color }: IColor) => {
    return (
        <svg className={color} xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 24 24">
            <path d="M24 4h-20v20h20v-20zm-24 17v-21h21v2h-19v19h-2z" />
        </svg>
    );
}

export default CloneIcon;