/**
 * Contexto de interface de comunicação com
 * as APIs da OpenAI.
 */

import { createContext, useContext, useState, useEffect } from "react";
import { ParentInterface } from "@/types";

interface OpenAIProviderInterface extends ParentInterface {}

type SenderType = "user" | "ai";

interface Message {
	sender: SenderType,
	content: string,
	displayContent: string
}

interface Chat {
	id: string,
	messages: Message[],
}

interface OpenAIContextData {
	chats: { [id: string]: Chat };
	addMessage: (chatId: string, sender: SenderType, content: string, displayContent: string) => void;
}

const OpenAIContext = createContext<OpenAIContextData>(
	{} as OpenAIContextData
);

export const OpenAIProvider: React.FC<OpenAIProviderInterface> = ({ children }) => {
	const [chats, setChats] = useState<{ [id: string]: Chat }>({});

	const addMessage = (chatId: string, sender: SenderType, content: string, displayContent: string) => {
		const chat = chats[chatId];
		if (!chat)
		{
			// Cria um chat se não existe.
			setChats({
				...chats,
				[chatId]: {
					id: chatId,
					messages: [{ sender, content, displayContent }],
				},
			});
		}
		else {
			// atualiza o existente.
			setChats({
				...chats,
				[chatId]: {
					...chat,
					messages: [...chat.messages, { sender, content, displayContent }],
				},
			});
		}
	};

	return (
		<OpenAIContext.Provider
			value={{
				chats,
				addMessage,
			}}
		>
			{children}
		</OpenAIContext.Provider>
	);
};

export const useOpenAI = (): OpenAIContextData => {
	const context = useContext(OpenAIContext);

	if (!context) {
		throw new Error(
			"useOpenAI must be used within a OpenAIProvider"
		);
	}

	return context;
};
