import { IColor } from "@/types/canvas";

const AddFileIcon = ({ color }: IColor) => {
    return (
        <svg className={color} width="64px" height="64px" strokeWidth="1.8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#71717a">
            <path d="M8 12H12M16 12H12M12 12V8M12 12V16" stroke="#71717a" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#71717a" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"></path>
        </svg>
    )
}

export default AddFileIcon;