import Api from '@/api/Api';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMember } from '@/contexts/member/MemberContext';
import FontInjector from '@/contexts/fonts/FontInjector';
import ImageHelper from '@/helpers/ImageHelper';
import DEBUG from '@/helpers/DEBUG';

// Fontes embarcadas no sistema.
const installedFonts = [
	{ id: 'Anton', label: 'Anton' },
	{ id: 'BigShotOne', label: 'Big Shot One' },
	{ id: 'DotGothic16', label: 'Dot Gothic 16' },
	{ id: 'Grenze', label: 'Grenze' },
	{ id: 'HelveticaNeue', label: 'Helvetica Neue' },
	{ id: 'HelveticaNeueBold', label: 'Helvetica Neue Bold' },
	{ id: 'MajorMonoDisplay', label: 'Major Mono Display' },
	{ id: 'MercadilloBlack', label: 'Mercadillo Black' },
	{ id: 'Montserrat', label: 'Montserrat' },
	{ id: 'OleoScript', label: 'Oleo Script' },
	{ id: 'Pacifico', label: 'Pacifico' },
	{ id: 'Roboto', label: 'Roboto' },
	{ id: 'RumRaisin', label: 'Rum Raisin' },
	{ id: 'RussoOne', label: 'Russo One' },
	{ id: 'Sofia', label: 'Sofia' },
];

export type FontData = {
	id: string;
	label: string;
	url?: string;
};

type FontContextType = {
	fonts: FontData[];
	getFontLabelById: (id: string) => string | null;
	getFontIdByLabel: (label: string) => string | null;
	getAvailableFontIds: () => string[];
	getAvailableFontLabels: () => string[];
	addFont: (font: FontData) => void;
	fetchFonts: () => void;
};

const FontContext = createContext<FontContextType | undefined>(undefined);

export function useFontContext(): FontContextType {
	const context = useContext(FontContext);
	if (!context) {
		throw new Error('useFontContext must be used within a FontProvider');
	}
	return context;
}

export function FontProvider({ children }: { children: React.ReactNode }) {
	const { member } = useMember();

	const [fonts, setFonts] = useState<FontData[]>(installedFonts);

	const getFontLabelById = (id: string): string | null => {
		const font = fonts.find((font) => font.id === id);
		return font ? font.label : null;
	};

	const getFontIdByLabel = (label: string): string | null => {
		const font = fonts.find((font) => font.label === label);
		return font ? font.id : null;
	};

	const getAvailableFontIds = (): string[] => {
		return fonts.map((font) => font.id);
	};

	const getAvailableFontLabels = (): string[] => {
		return fonts.map((font) => font.label);
	};

	const addFont = (font: FontData): void => {
		setFonts((prevFonts) => [...prevFonts, font]);
	};

	const fetchFonts = async () => {
		const api = new Api('fonts', 'g');
		try {
			const request = api.useHash(member);
			const response = await api.post(request);
			if (response.success)
			{
				const { fonts } = response.data;
				const fontList: Array<FontData> = fonts.map((font: any): FontData => ({
					url: ImageHelper.createServerURL(font.URL),
					label: font.FontName,
					id: font.FontId
				}));
				setFonts([...fontList, ...installedFonts]);
			}
		} catch (error) {
			if (DEBUG.ENABLED) console.error('Erro ao buscar fontes no servidor', error);
		}
	};

	useEffect(() => {
		if (member.isLogged())
		{
			fetchFonts();
		}
	}, [member]);

	return (
		<FontContext.Provider
			value={{
				fonts,
				getFontLabelById,
				getFontIdByLabel,
				getAvailableFontIds,
				getAvailableFontLabels,
				addFont,
				fetchFonts
			}}
		>
			{children}
			<FontInjector fontList={fonts} />
		</FontContext.Provider>
	);
}
