import React, { useState, useEffect, useRef } from "react";
import { useMember } from "@/contexts/member/MemberContext";
import { freeSet } from '@coreui/icons';
import CIcon from "@coreui/icons-react";
import { useCanvasProducts } from "@/contexts/editor/CanvasProductsContext";
import { ProductListItem } from "@/types/products";
import PriceHelper from "@/helpers/PriceHelper";
import TextRow from "@/components/views/common/TextRow";
import { ArrowUp, ArrowDown } from "@/components/svg/Arrows";
import { useProducts } from "@/contexts/products/ProductsContext";
import ProductsSearchModal from "@/components/modals/editor/ProductSearchModal";
import OpenAI from "@/classes/OpenAI";
import { useOpenAI } from "@/contexts/openai/OpenAIContext";

type RightSidebarProps = { isOpen: boolean, toggleSidebar: () => void };

const __BG_ANIMATED_GRADIENT__ = "transform-gpu transition-all duration-[600ms] bg-gradient-to-r to-dark-orange via-orange-400 from-dark-orange bg-size-200 bg-pos-0 hover:bg-pos-100 group";

const E_AISidebar: React.FC<RightSidebarProps> = ({ isOpen, toggleSidebar }) => {
    const { isLoginModalVisible } = useMember();
	const { showProductModal, setShowProductModal, resetContext } = useProducts();

	const { chats, addMessage } = useOpenAI();

    useEffect(() => {
        if (isOpen && isLoginModalVisible) toggleSidebar();
    }, [isOpen, isLoginModalVisible])

    const handleOverlayClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        toggleSidebar();
    };

	const onCloseModal = () => {
		setShowProductModal(false);
		resetContext();
	}

	const onClickSearch = () => {
		setShowProductModal(true);
		toggleSidebar();
		// setExpandBar(!expandBar);
	}

	const callAI = async () => {
		const prompt = "Oi, tem alguém aí?";
		addMessage('test', 'user', prompt, prompt);
		
		const response = await OpenAI.sendMessage(prompt);
		const content = response.choices[0].message?.content || 'No message received';
		addMessage('test', 'ai', content, content);
	}

	useEffect(() => {
		console.log(chats)
	}, [chats]);

	return (
		<React.Fragment>
			<ProductsSearchModal isOpen={showProductModal} onClose={onCloseModal} />
			<div
                className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out z-50 ${isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}
                onClick={handleOverlayClick}></div>
            <div
                className={`fixed top-0 right-0 h-screen z-50 bg-light-black dark:bg-slate-700 p-4 w-[65%] min-w-[250px] transition-all duration-300 ease-in-out ${isOpen ? 'transform-none opacity-100 pointer-events-auto' : 'transform translate-x-full opacity-0 pointer-events-none'}`}>
                <section className="flex flex-col h-full">

					<div className="flex flex-col shadow-sm">
						<span className="text-lg">E-AI</span>
						<span className="text-xs my-2 pr-2">Bem-vindo à interface IA do Grupo EPJ.</span>
					</div>

                    <div className={"flex flex-col items-center h-full overflow-auto px-2 py-4"}>
                        <List />
                    </div>

                </section>

				<div className=" absolute bottom-4 inset-x-4 flex w-full justify-center items-center">
					<div className={`${__BG_ANIMATED_GRADIENT__} p-2 rounded-lg text-white text-center font-bold cursor-pointer w-1/3`}
						onClick={callAI}
					>
						GO
					</div>
				</div>
			</div>
		</React.Fragment>
    );
};

export default E_AISidebar;

const List: React.FC = () => {
	const { products } = useCanvasProducts();
	
	return (
		<div className='w-full' >
			<ul className='w-full pb-8' >
				{products.map((product, index) => <Product key={index} index={index} product={product} />)}
			</ul>
		</div>
	)
}

const containerRowClasses = "my-4 p-4 bg-white shadow-sm rounded-lg border-2 border-gray-200 transition-colors duration-200 ease-in-out hover:bg-gray-50";

const Product: React.FC<{product: ProductListItem, index: number}> = ({ product, index }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggle = () => {
		setIsOpen(!isOpen);
	}
	
	return (
		<div 
			className='w-full min-h-[4rem] h-fit mt-4 bg-gray-100 border border-gray-300 rounded-lg shadow-md hover:shadow-lg duration-300 flex flex-col justify-center border-t-2 pt-2'>
			<div 
				className='w-full h-full flex flex-row items-center justify-between cursor-pointer p-2 no-text-highlight' 
				onClick={handleToggle}
			>
				<span className="w-full" >{product.description}</span>
				{isOpen ? <ArrowUp /> : <ArrowDown />}
			</div>
			{isOpen && (
				<div className='w-full h-full flex flex-col items-center justify-between p-2 my-2 dark:text-black'>
					{product.ean && <TextRow containerClassName={containerRowClasses} label="EAN:" value={product.ean} />}
					{product.innerCode && <TextRow containerClassName={containerRowClasses} label="Código Interno:" value={product.innerCode} />}
					{PriceHelper.isPriceValid(product.sPrice) && <TextRow containerClassName={containerRowClasses} label="Preço:" value={product.sPrice as string} />}
					{PriceHelper.isPriceValid(product.cPrice) && <TextRow containerClassName={containerRowClasses} label="Preço Clube:" value={product.cPrice as string} />}
					{PriceHelper.isPriceValid(product.oPrice) && <TextRow containerClassName={containerRowClasses} label="Preço Oferta:" value={product.oPrice as string} />}
					{PriceHelper.isPriceValid(product.ePrice) && <TextRow containerClassName={containerRowClasses} label="Preço Extra:" value={product.ePrice as string} />}
					{
						product.preview && product.preview.length > 0 && (
							<div className="w-20 h-20 mb-2">
								<img className="" src={product.preview} />
							</div>
						)
					}
				</div>
			)}
		</div>
	)
}
