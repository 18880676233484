import React, { useRef } from "react";
import Row from "@/components/modals/admin/components/Row";
import BooleanInput, { useBooleanInputRef } from "@/components/inputs/BooleanInput";

export interface BooleanInputRowInterface {
	label: string,
	onChange: (value: boolean) => void,
	defaultValue?: boolean,
	description?: string | null,
	className?: string
}

const BooleanInputRow: React.FC<BooleanInputRowInterface> = ({ label, onChange, defaultValue, description, className }) => {
	const booleanInputRef = useBooleanInputRef();

	const onRowClicked = () => {
		if (booleanInputRef && booleanInputRef.current) {
			const ref = booleanInputRef.current;
			ref.toggleEnable();
		}
	}

	return (
		<Row>
			<div
				className={`flex flex-col items-center w-full bg-white shadow-md hover:bg-zinc-50 duration-300 py-2 px-4 rounded-lg cursor-pointer ${className}`}
				onClick={onRowClicked}
			>
				<div className="flex flex-row items-center justify-between w-full">
					<span className="mr-4">
						{label}
					</span>
					<div className="flex justify-end">
						<BooleanInput ref={booleanInputRef} onChange={(value: boolean) => onChange(value)} defaultValue={defaultValue} />
					</div>
				</div>
				{
					description && (
						<span className="w-full text-left mt-2 text-gray-500" style={{ fontSize: 11 }} >
							{description}
						</span>
					)
				}
			</div>
		</Row>
	);
}

BooleanInputRow.defaultProps = {
	defaultValue: false,
	description: null
}

export default BooleanInputRow;
