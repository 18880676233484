export default class PriceHelper
{
	static mockEAN(): string
	{
		let eanCode = '';
		for (let i = 0; i < 13; i++)
		{
			eanCode += Math.floor(Math.random() * 10);
		}
		return eanCode;
	}

	static isPriceValid(price: string | null): boolean
	{
		return !!price && price.length > 0 && price !== '0' && price !== '-';
	}
}
