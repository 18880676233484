import Api from "@/api/Api";
import Dropzone from "@/components/cards/common/Dropzone";
import { useCompany } from "@/contexts/company/CompanyContext";
import { useMember } from "@/contexts/member/MemberContext";
import Notify from "@/helpers/Notify";
import { useEffect, useState } from "react";

interface UploadAreaInterface {
	index: number;
}

interface ILogosContainer { }

const LogosContainer: React.FC<ILogosContainer> = ({ }) => {
	return (
		<>
			<div className="flex flex-col justify-between w-full h-full">
				<div className="w-full flex flex-col items-center justify-center">
					<h2 className="text-zinc-600 text-xl font-semibold mb-2">Kit da Marca</h2>
					<p className="text-zinc-500 mb-5">Você pode subir até três logotipos da sua empresa e definir onde usá-las.</p>
				</div>
				<div className="flex items-center justify-center gap-4 h-full w-full overflow-hidden">
					{/* Detalhes da Empresa */}
					<div className="flex flex-1 flex-col items-center hover:scale-101 duration-200 cursor-pointer">
						<UploadArea index={1} />
					</div>
					<div className="flex flex-1 flex-col items-center hover:scale-101 duration-200 cursor-pointer">
						<UploadArea index={2} />
					</div>
					<div className="flex flex-1 flex-col items-center hover:scale-101 duration-200 cursor-pointer">
						<UploadArea index={3} />
					</div>
				</div>
			</div>
		</>
	)
}

export default LogosContainer;

const UploadArea: React.FC<UploadAreaInterface> = ({ index }) => {
	const { companyID, fetchCompany, company } = useCompany();
	const { member } = useMember();

	const existingLogo = company?.logos[index - 1];
	const [renderDropzone, setRenderDropzone] = useState(true);

	const rerender = async () => {
		await fetchCompany();
		setTimeout(() => setRenderDropzone(true), 200);
	}

	const handleErrorOnResponse = (response: any) => {
		Notify.Error("Algum erro aconteceu");
		console.log(response);
	}

	const handleUpload = async (uri: string | null) => {
		if (!companyID || !uri) return;

		const api = new Api('company', 'sl');

		const request = api.request(member, {
			companyID,
			index,
			uri
		});

		const response = await api.post(request);
		if (response.success) {
			Notify.Success("Salvo");
			rerender();
		}
		else handleErrorOnResponse(response);
	}

	const handleDeletion = async () => {
		if (!companyID) return;

		const api = new Api('company', 'dl');

		const request = api.request(member, { index });

		const response = await api.post(request);
		if (response.success) {
			Notify.Success("Removido");
			setRenderDropzone(false);
			await fetchCompany();
			setTimeout(() => setRenderDropzone(true), 200);
		}
		else handleErrorOnResponse(response);
	}

	return (
		<div className="flex flex-col justify-end items-center text-center mb-5">
			<h3 className="text-xl font-semibold mt-2">{index === 1 ? "Logo Principal" : index}</h3>
			{renderDropzone && <Dropzone onChange={(uri: string | null) => handleUpload(uri)} defaultUri={existingLogo} />}
			{
				existingLogo && (
					<button className="bg-rose-500 hover:bg-rose-600 mt-4 text-white py-1 px-4 rounded-lg" onClick={handleDeletion}>
						Remover
					</button>
				)
			}
		</div>
	);
}
