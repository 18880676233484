import { createContext, useContext, useState, useEffect } from "react";
import { ReactChildren } from "@/types";

interface OnboardingProviderInterface {
    children: ReactChildren
}

interface OnboardingContextData {
	isNextBtnEnabled: boolean,
	setIsNextBtnEnabled: (value: boolean) => void
}

const OnboardingContext = createContext<OnboardingContextData>(
    {} as OnboardingContextData
);

export const OnboardingProvider: React.FC<OnboardingProviderInterface> = ({ children }) => {
	const [isNextBtnEnabled, setIsNextBtnEnabled] = useState<boolean>(false);

    // const { member } = useMember();

    return (
        <OnboardingContext.Provider
            value={{
				isNextBtnEnabled,
				setIsNextBtnEnabled
            }}
        >
            {children}
        </OnboardingContext.Provider>
    );
};

export const useOnboarding = (): OnboardingContextData => {
    const context = useContext(OnboardingContext);

    if (!context) {
        throw new Error(
            "useOnboarding must be used within a OnboardingProvider"
        );
    }

    return context;
};
