import IStack from "@/types/IStack";

class Stack<T> implements IStack<T> {
    public undoStack: Array<T>;
    public redoStack: Array<T>;

    constructor() {
        this.undoStack = [];
        this.redoStack = [];
    }

    public undoPush(item: T): void {
        if (this.undoStack.length >= 15) {
            this.undoStack.shift();
        }
        this.undoStack.push(item);
    }

    public redoPush(item: T): void {
        if (this.redoStack.length >= 15) {
            this.redoStack.shift();
        }
        this.redoStack.push(item);
    }

    public undo(current: T): T | undefined {
        let element: T | undefined = this.undoStack.pop();
        if (element) {
            this.redoPush(current);
            return element;
        }
        return undefined;
    }

    public redo(current: T): T | undefined {
        let element: T | undefined = this.redoStack.pop();
        if (element) {
            this.undoPush(current);
            return element;
        }
        return undefined;
    }

    public isEmpty(stack: 'undo' | 'redo'): boolean {
        switch (stack) {
            case "redo":
                return this.redoStack.length === 0;
            case "undo":
                return this.undoStack.length === 0;
        }
    }

    public peekUndo(): T | undefined {
        return !this.isEmpty('undo') ? this.undoStack[this.undoStack.length - 1] : undefined;
    }

    public peekRedo(): T | undefined {
        return !this.isEmpty('redo') ? this.redoStack[this.redoStack.length - 1] : undefined;
    }

    public clear(): void {
        this.undoStack = [];
        this.redoStack = [];
    }
}

export default Stack; 