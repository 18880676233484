import React from 'react';
import { createContext, useContext, useState, useEffect } from "react";
import { ReactChildren } from "@/types";
import { EntityID } from "@/types/silverstripe";
import { AdminTabs } from "@/types/admin";
import { TabContainerRef } from "@/components/wrappers/TabContainer";

export interface UserManagerContextData {
    tabRef: TabContainerRef | null;
    setTabRef: (tab: TabContainerRef) => void;
    isSelecting: boolean;
    setIsSelecting: (value: boolean) => void;
    shouldUpdateLists: boolean;
    setShouldUpdateLists: (value: boolean) => void;
    targetId: EntityID;
    setTargetId: (value: EntityID) => void;
    currentTab: AdminTabs;
    setCurrentTab: (value: AdminTabs) => void;
    memberModalVisible: boolean;
    setMemberModalVisibility: (value: boolean) => void;
    companyModalVisible: boolean;
    setCompanyModalVisibility: (value: boolean) => void;
    permissionModalVisible: boolean;
    setPermissionModalVisibility: (value: boolean) => void;
    notificationModalVisible: boolean;
    setNotificationModalVisibility: (value: boolean) => void;
    fontModalVisible: boolean;
    setFontModalVisibility: (value: boolean) => void;
    imageModalVisible: boolean;
    setImageModalVisibility: (value: boolean) => void;
    registrationModalVisible: boolean;
    setRegistrationModalVisibility: (value: boolean) => void;
    restrictPrinting: boolean;
    setRestrictPrinting: (value: boolean) => void;
}

interface UserManagerProviderInterface {
    children: ReactChildren
}

export const UserManagerContext = createContext<UserManagerContextData>(
    {} as UserManagerContextData
);

export const UserManagerProvider: React.FC<UserManagerProviderInterface> = ({ children }) => {
    const [shouldUpdateLists, setShouldUpdateLists] = useState(false);
    const [isSelecting, setIsSelecting] = useState(false);
    const [targetId, setTargetId] = useState<EntityID>(null);

    // Ref
    const [tabRef, setTabRef] = useState<TabContainerRef | null>(null);

    // Modal visibility
    const [memberModalVisible, setMemberModalVisibility] = useState(false);
    const [companyModalVisible, setCompanyModalVisibility] = useState(false);
    const [permissionModalVisible, setPermissionModalVisibility] = useState(false);
    const [notificationModalVisible, setNotificationModalVisibility] = useState(false);
    const [fontModalVisible, setFontModalVisibility] = useState(false);
    const [imageModalVisible, setImageModalVisibility] = useState(false);
    const [registrationModalVisible, setRegistrationModalVisibility] = useState(false);
    const [restrictPrinting, setRestrictPrinting] = useState(false);

    // Tab controls
    const [currentTab, setCurrentTab] = useState<AdminTabs>("companies");

    return (
        <UserManagerContext.Provider
            value={{
                tabRef,
                setTabRef,
                isSelecting,
                setIsSelecting,
                shouldUpdateLists,
                setShouldUpdateLists,
                targetId,
                setTargetId,
                currentTab,
                setCurrentTab,
                memberModalVisible,
                setMemberModalVisibility,
                companyModalVisible,
                setCompanyModalVisibility,
                permissionModalVisible,
                setPermissionModalVisibility,
                notificationModalVisible,
                setNotificationModalVisibility,
                fontModalVisible,
                setFontModalVisibility,
                imageModalVisible,
                setImageModalVisibility,
                registrationModalVisible,
                setRegistrationModalVisibility,
                restrictPrinting,
                setRestrictPrinting
            }}
        >
            {children}
        </UserManagerContext.Provider>
    );
};

export const useUserManager = (): UserManagerContextData => {
    const context = useContext(UserManagerContext);

    if (!context) {
        throw new Error(
            "useUserManager must be used within a UserManagerProvider"
        );
    }

    return context;
};
