import { v4 as uuidv4 } from 'uuid';
import { createHash } from 'crypto';

// Get random number.
export const now = () => Date.now().toString();

export const uuid = (length: number = 8) => uuidv4().substr(0, length);

export const generateDeterministicUUID = (seed: string, length: number = 8): string => {
    const hash = hashSeed(seed);

    const parts = [
        hash.slice(0, 8),
        hash.slice(8, 12),
        '4' + hash.slice(13, 16),   // indica UUID v4
        ((parseInt(hash[16], 16) & 0b0011) | 0b1000).toString(16) + hash.slice(17, 19), // indica variant
        hash.slice(20, 32)
    ];

    const uuid = parts.join('-');
    return uuid.substr(0, length);
}

const Helpers = {
    now,
    uuid,
    generateDeterministicUUID
}

export default Helpers;

function hashSeed(seed: string): string {
    return createHash('sha256').update(seed).digest('hex');
}
