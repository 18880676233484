import Skeleton from "react-loading-skeleton";

const SkeletonTable = () => {
	return (
		<div className="w-full flex flex-col justify-center">
			<table className="min-w-full divide-y divide-gray-200">
				<thead className="bg-gray-50">
					<tr>
						{
							Array.from({ length: 6 }).map((_, index) => (
								<th key={index} className="px-4 py-2">
									<Skeleton />
								</th>
							))
						}
					</tr>
				</thead>
				<tbody className="bg-white divide-y divide-gray-200">
					{
						Array.from({ length: 5 }).map((_, rowIndex) => (
							<tr key={rowIndex} className='hover:bg-slate-50'>
								{
									Array.from({ length: 6 }).map((_, cellIndex) => (
										<td key={cellIndex} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 h-20">
											<Skeleton />
										</td>
									))
								}
							</tr>
						))
					}
				</tbody>
			</table>
		</div>
	);
};

export default SkeletonTable;
