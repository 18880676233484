import React, {useState, useEffect, Fragment} from 'react';
import i18n from 'i18next';
import {useDispatch} from "react-redux";
import { AcceptedLocales } from "@/types/constants";
import {localeActions} from "@/store/Locale.store";
import { useAppSelector } from '@/store/hooks';
import { useTranslation } from 'next-i18next';

const LanguageSelector: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="language-selector flex justify-center space-x-2">
            <Flag label={t("languages.pt.pt_br.title")} country={'BR'} location={'pt-BR'} description={t("languages.pt.pt_br.flagDescription")} />
            <Flag label={t("languages.en.en_us.title")} country={'US'} location={'en'} description={t("languages.en.en_us.flagDescription")} />
        </div>
    );
};

type FlagProps = {
    label: string;
    country: 'BR' | 'PT' | 'ES' | 'FR' | 'CN' | 'RU' | 'US';
    location: AcceptedLocales;
    description: string
}

function Flag({label, country, location, description}: FlagProps) {
    const dispatch = useDispatch();
    const currentLocale = useAppSelector((state) => state.locale.current);
    const [locale, setLocale] = useState<AcceptedLocales>(currentLocale);

    const onClick = (language: AcceptedLocales) => {
        dispatch(localeActions.setLocale({locale: language}));
        setLocale(language);
    };

    useEffect(() => {
        setLocale(i18n.language as AcceptedLocales);
    }, [i18n.language]);

    const isSelected = locale === location;
    const borderColor = isSelected ? 'border-c5c5c7' : 'border-transparent';

    return (
        <div className={`w-8 h-8 flex justify-center items-center cursor-pointer rounded border-2 ${borderColor}`} >
            <span
                className={`fi fi-${country.toLocaleLowerCase()}`}
                onClick={() => onClick(location)}
                aria-label={description}
            ></span>
        </div>
    )
}

export default LanguageSelector;
