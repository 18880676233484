import React from "react";
import { createSlice } from "@reduxjs/toolkit";
import ComponentProvider from "@/components/editor/ComponentProvider";
import Konva from "konva";

export type ElementType = "rectangle" | "circle" | "image" | "text" | "background";
type Orientation = "horizontal" | "vertical";

type Element = {
    type: ElementType,
    id: string,
    x: number,
    y: number,
    width: number,
    height: number,
    fill: string,
    image: string | null,
    text: string | null,
    fontSize: number | null
};

export type ElementData = {
    ref: React.RefObject<Konva.Shape> | null;
    element: Element;
}

type CanvasState = {
    elements: ElementData[];
    orientation: Orientation;
    selectedID: string | null;
    selectedType: string | null;
};

const initialState: CanvasState = {
    //
    elements: [
        // @ts-ignore
        {ref: null, element: ComponentProvider.background()}
    ],
    orientation: "vertical",
    selectedID: null,
    selectedType: null
};

type AddElementPayload = Element;
type RemoveElementPayload = string;
type UpdateElementPayload = {
    id: string;
    element: Element;
}
type UpdateElementRefPayload = {
    id: string;
    ref: React.RefObject<Konva.Shape>;
};
type UpdateOrientationPayload = Orientation;
type SelectShapePayload = {
    id: string | null;
    type: string | null;
};

const canvasSlice = createSlice({
    name: "canvas",
    initialState: initialState,
    reducers: {
        // Elementos
        addElement(state, action: {payload: AddElementPayload}) {
            state.elements = [...state.elements, { ref: null, element: action.payload }];
        },
        removeElement(state, action: {payload: RemoveElementPayload}) {
            state.elements = state.elements.filter(data => (data.element.id !== action.payload && data.element.id !== 'background'));
        },
        updateElement(state, action: {payload: UpdateElementPayload}) {
            const index = state.elements.findIndex(data => data.element.id === action.payload.id);
            if (index !== -1)
            {
                const image: string | HTMLImageElement | null = action.payload.element.image;
                // @ts-ignore
                const src = typeof image === 'string' ? null : (image?.src ?? null);
                state.elements[index].element = { ...action.payload.element, image: src };
            }
        },
        updateElementRef(state, action: { payload: UpdateElementRefPayload }) {
            const index = state.elements.findIndex(data => data.element.id === action.payload.id);
            if (index !== -1) {
                state.elements[index].ref = action.payload.ref;
            }
        },
        selectShape(state, action: { payload: SelectShapePayload }) {
            state.selectedID = action.payload.id;
            state.selectedType = action.payload.type;
        },
        updateOrientation(state, action: {payload: UpdateOrientationPayload}) {
            state.orientation = action.payload;
        },
        unselect(state) {
            state.selectedID = null;
            state.selectedType = null;
        }
    },
});

export const canvasActions = canvasSlice.actions;
export default canvasSlice.reducer;
